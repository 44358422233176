import { createServerSidePaginatedFetcher } from '../contexts/Fetcher';
import { apiOrganization } from '../resources/api/apiOrganization';
import { useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';

const { useGetInvitations } = apiOrganization;

export const OrganizationInvitesFetcher = createServerSidePaginatedFetcher(() => {
  const { organization } = useContext(OrganizationContext);
  return useGetInvitations(organization?.name).execute;
});
