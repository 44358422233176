import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CustomerUsersFetcher } from './CustomerFetchers';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { useIsGuardedRouteAllowed } from '../../resources/GuardedRouteLink';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';

export const OrganizationUsers: FC = () => {
  const { organization } = useContext(OrganizationContext);
  const { data } = useContext(CustomerUsersFetcher.Context);
  /** @todo here was a little trick that prevented a call after selecting a user. Let's see if it's needed to bring it back */
  // const { onDataUpdate: onUserDataUpdate } = useContext(CustomerUserFetcher.Context);

  useAutoFetch(CustomerUsersFetcher);
  const { t } = useTranslation();

  const getRowLink = useCallback(
    (params: GridRowParams) => {
      if (!organization) {
        return '';
      }
      return AuthenticatedRoutes.UserOrganization(params.row.id, organization?.name);
    },
    [organization?.name]
  );

  const isGetRowLinkAllowed = useIsGuardedRouteAllowed('UserOrganization', ['any', 'any']);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'first_name',
        headerName: t('common.tableHeader.firstName'),
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
        flex: 1,
      },
      {
        field: 'last_name',
        headerName: t('common.tableHeader.lastName'),
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        filterOperators: [getStringEqualsOperator()],
        flex: 2,
      },
      {
        field: 'created_at',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
        width: 130,
      },
      {
        field: 'updated_at',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
        width: 130,
      },
    ],
    []
  );

  if (!data) return null;

  return (
    <UrlQueryDataGrid
      columns={columns}
      rows={data.payload.data}
      disableExtendRowFullWidth={false}
      autoHeight={true}
      disableSelectionOnClick={true}
      paginationMode={'server'}
      filterMode={'server'}
      sortingMode={'server'}
      // onRowClick={onRowClick}
      getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      rowCount={data.payload.total_items}
    />
  );
};
