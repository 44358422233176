import { IProductPlanPackage } from '../../models/ProductPlanPackage';
import { productPackageResources } from '../builders/productPackageResources';
import { getUseResource } from '../resource';

interface IProductPackagePayload {
  name: string;
  tools: {
    data: Array<{
      amount: number;
      toolId: number;
    }>;
    bundledWith?: number[];
  };
  isAddon: boolean;
}

export const apiProductPackage = {
  useGetPackages: getUseResource(
    productPackageResources.getPackages,
    (body): { packages: IProductPlanPackage[] } => body
  ),
  useCreatePackage: getUseResource(
    productPackageResources.createPackage,
    (body): IProductPlanPackage => body,
    (params, payload: IProductPackagePayload) => payload
  ),
  useEditPackage: getUseResource(
    productPackageResources.updatePackage,
    (body): IProductPlanPackage => body,
    (params, payload: Partial<IProductPackagePayload>) => payload
  ),
  useDeletePackage: getUseResource(productPackageResources.deletePackage, () => null),
};
