import React, { FC } from 'react';
import { Control, FormState, UseFormHandleSubmit } from 'react-hook-form';
import { TextInputController } from '../InputControllers/TextInputController';
import { useTranslation } from 'react-i18next';
import { TUpdateUserPayload } from '../../resources/api/apiUser';
import { IBasicUserInfo } from '../../resources/models/IUserInfo';

interface IProps {
  user: IBasicUserInfo;
  onSubmit: (fields: TUpdateUserPayload) => void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<TUpdateUserPayload>;
  formState: FormState<TUpdateUserPayload>;
}

export const EditUserForm: FC<IProps> = ({ user, onSubmit, control, handleSubmit, formState: { errors } }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.firstName')}
        defaultValue={user.first_name}
        name="first_name"
      />

      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.lastName')}
        defaultValue={user.last_name}
        name="last_name"
      />

      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.phoneNumber')}
        defaultValue={user.phone_number}
        name="phone_number"
      />
    </form>
  );
};
