import React, { FC, useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWrapper } from '../components/RouteWrapper';
import { AuthLayout } from '../layouts/AuthLayout';
import { AuthRecoverAccount } from '../components/AuthRecoverAccount/AuthRecoverAccount';
import { AccountRecoveryUpdatePassword } from '../components/AccountRecoveryUpdatePassword/AccountRecoveryUpdatePassword';
import { AccountType } from '../types/AccountType';
import { AuthEmail } from '../components/AuthEmail/AuthEmail';
import { AuthAccount } from '../components/AuthAccount/AuthAccount';
import { RedirectPreserveQuery } from '../components/RedirectPreserveQuery/RedirectPreserveQuery';
import { AuthSignUp } from '../components/AuthSignUp/AuthSignUp';
import { NotFoundComponent } from '../components/NotFound';
import { AuthSecondFactor } from '../components/AuthSecondFactor/AuthSecondFactor';
import { JoinPage } from '../pages/Join';
import { AuthContext } from '../contexts/AuthContext';

export const guestRoot = '/auth';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */
export const GuestRoutes = {
  Root: () => guestRoot,
  SignIn: () => `${guestRoot}/signin`,
  SignUp: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/signup`,
  Account: () => `${guestRoot}/account`,
  Choose: () => `${guestRoot}/choose`,
  Start: () => `/start`,

  SecondFactor: () => `${guestRoot}/signin/2fa`,
  RecoverAccount: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/recover-account`,
  UpdatePassword: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/update-password`,

  Join: (token?: string) => `${guestRoot}/invitation/${token ?? ':token'}`,

  // LegacyUserUpdatePassword: () => '/customer/user/update-password',
  // LegacyCustomerUpdatePassword: () => '/customer/admin/update-password',
  // LegacyAdminUpdatePassword: () => '/admin/update-password',
  //
  // LegacyUserSignIn: () => '/customer/user/signin',
  // LegacyCustomerSignIn: () => '/customer/admin/signin',
  // LegacyAdminSignIn: () => '/admin/signin',
  //
  // LegacyUserSignUp: () => '/customer/user/signup',
  // LegacyCustomerSignUp: () => '/customer/admin/signup',
  // LegacyAdminSignUp: () => '/admin/signup',
};

// const AccountTypeProvider: FC = ({ children }) => {
//   const { accountType } = useParams<{ accountType: AccountType }>();
//   return <AccountContext.Provider value={accountType}>{children}</AccountContext.Provider>;
// };

// const providers = [];

export const GuestRouter: FC = () => {
  // const { account } = useAccount();
  const { identity } = useContext(AuthContext);

  console.log('GUEST ROUTER');

  return (
    <Switch>
      <RedirectPreserveQuery exact from={GuestRoutes.Start()} to={GuestRoutes.Root()} />
      {identity ? <RedirectPreserveQuery exact from={GuestRoutes.Root()} to={GuestRoutes.Account()} /> : <RedirectPreserveQuery exact from={GuestRoutes.Root()} to={GuestRoutes.SignIn()} />}

      {/*<RedirectWithQuery to={GuestRoutes.UpdatePassword(AccountType.Customer)} from={GuestRoutes.LegacyCustomerUpdatePassword()} />*/}
      {/*<RedirectWithQuery to={GuestRoutes.SignUp(AccountType.Customer)} from={GuestRoutes.LegacyCustomerSignUp()} />*/}

      {/*<RedirectWithQuery to={GuestRoutes.UpdatePassword(AccountType.User)} from={GuestRoutes.LegacyUserUpdatePassword()} />*/}
      {/*<RedirectWithQuery to={GuestRoutes.SignUp(AccountType.User)} from={GuestRoutes.LegacyUserSignUp()} />*/}

      {/*<RedirectWithQuery to={GuestRoutes.UpdatePassword(AccountType.Admin)} from={GuestRoutes.LegacyAdminUpdatePassword()} />*/}
      {/*<RedirectWithQuery to={GuestRoutes.SignUp(AccountType.Admin)} from={GuestRoutes.LegacyAdminSignUp()} />*/}

      <RouteWrapper exact path={GuestRoutes.SignIn()} component={AuthEmail} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.Join()} component={JoinPage} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.Account()} component={AuthAccount} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.SecondFactor()} component={AuthSecondFactor} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.RecoverAccount()} component={AuthRecoverAccount} layout={AuthLayout} />
      <RouteWrapper exact path={GuestRoutes.UpdatePassword()} component={AccountRecoveryUpdatePassword} layout={AuthLayout} />
      <RouteWrapper exact path={GuestRoutes.SignUp()} component={AuthSignUp} layout={AuthLayout} />
      <Redirect exact from="/" to={GuestRoutes.Root()} />

      <RouteWrapper path={guestRoot} component={NotFoundComponent} layout={AuthLayout} />

      {/*<RedirectToAuth from={authenticatedRoot} />*/}
    </Switch>
  );
};
