import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { StandardTextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEnumState } from '../../hooks/useEnumState';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';

interface IProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  disabled?: boolean;
  errors: FieldErrors;
  InputProps?: StandardTextFieldProps['InputProps'];
  label: string;
  variant?: 'standard' | 'outlined' | 'filled';
}

export const PasswordInputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled = false,
  errors,
  InputProps,
  label,
  name,
  variant,
  ...restProps
}: IProps<TFieldValues, TName>) => {
  const [showPassword, setShowPassword, setHidePassword] = useEnumState(false, true, false);

  const renderFn = useCallback<ControllerProps<TFieldValues, TName>['render']>(
    ({ field: { value, ref, ...field } }) => {
      return (
        <TextField
          key={showPassword ? 'show' : 'hide'}
          fullWidth
          label={label}
          value={value ?? ''}
          inputRef={ref}
          {...field}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            ...InputProps,
            endAdornment: value ? (
              <InputAdornment position="end">
                <IconButton onClick={showPassword ? setHidePassword : setShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          disabled={disabled}
          variant={variant}
        />
      );
    },
    [label, showPassword, InputProps, errors[name]]
  );

  return <Controller name={name} render={renderFn} {...restProps} />;
};
