import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { AuthDialog } from '../components/AuthDialog/AuthDialog';
import { useForm } from 'react-hook-form';
import { TextInputController } from '../components/InputControllers/TextInputController';
import { PasswordInputController } from '../components/InputControllers/PasswordInputController';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiddenSubmit } from '../components/HiddenSubmit/HiddenSubmit';
import { useHistory, useParams } from 'react-router-dom';
import { createFetcher } from '../contexts/Fetcher';
import { apiOrganization } from '../resources/api/apiOrganization';
import { AuthContext } from '../contexts/AuthContext';
import Typography from '@mui/material/Typography';
import { useApiErrorHandler } from '../hooks/useApiErrorHandler';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { NotificationContext } from '../contexts/NotificationContext';
import Alert from '@mui/material/Alert';
import { GuestRoutes } from '../routers/GuestRouter';
import { UserContext } from '../contexts/UserContext';

interface IFormFields {
  firstName: string;
  lastName: string;
  password: string;
}

interface IParams {
  token: string;
}

const { useGetInvitationDetails, useAcceptInvitation, useAcceptInvitationAndSignUp } = apiOrganization;

const InvitationDetailsFetcher = createFetcher(() => {
  const { token } = useParams<IParams>();
  return useGetInvitationDetails(token).execute;
});

// @todo use trans
const JoinComponent: FC = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { data } = useContext(InvitationDetailsFetcher.Context);
  const { identity, identities, setIdentity } = useContext(AuthContext);
  const { refetchUserInfo } = useContext(UserContext);

  const handleError = useApiErrorHandler();
  const history = useHistory();
  const { addNotification } = useContext(NotificationContext);

  const { token } = useParams<IParams>();

  const { execute: acceptInvitation } = useAcceptInvitation(data?.payload.organization_name, token);
  const { execute: signUpAndAccept } = useAcceptInvitationAndSignUp(data?.payload.organization_name, token);

  const invitedBy = useMemo(() => {
    try {
      return JSON.parse(atob(token.split('.')[1])).invited_by;
    } catch (e) {
      return null;
    }
  }, [token]);

  const onSignUpAndJoin = useCallback(
    async (formData: IFormFields) => {
      if (!data) return;

      try {
        await signUpAndAccept({
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: data.payload.email,
          password: formData.password,
        });

        const query = new URLSearchParams({
          email: data.payload.email,
        });

        history.push(`${GuestRoutes.SignIn()}?${query}`);
      } catch (e) {
        handleError(e);
      }
    },
    [data]
  );

  const onAccept = useCallback(async () => {
    try {
      await acceptInvitation();
      /** @todo some better wording and move to trans file */
      addNotification({
        severity: 'success',
        message: 'Successfully accepted invitation',
      });
      await refetchUserInfo();
      history.push(AuthenticatedRoutes.ChooseOrganization());
    } catch (e) {
      handleError(e);
    }
  }, [acceptInvitation, refetchUserInfo]);

  const buttons = useMemo(() => {
    const matchingIdentity = identities.find(({ email }) => email === data?.payload.email);

    if (!data?.payload.is_account_exist) {
      return [
        <Button key="submit" variant="contained" onClick={handleSubmit(onSignUpAndJoin)}>
          Sign up and join
        </Button>,
      ];
    } else if (data?.payload.email === identity?.email) {
      return [
        <Button key="submit" variant="contained" onClick={onAccept}>
          Join {data?.payload.organization_name}
        </Button>,
      ];
    } else if (matchingIdentity) {
      /** @todo handle situation when token timed out and need to enter credentials again */
      const onSwitch = () => {
        setIdentity(matchingIdentity);
      };
      return [
        <Button key="submit" variant="contained" onClick={onSwitch}>
          Switch account
        </Button>,
      ];
    } else {
      /** @todo redirection must happen now */
      return [];
    }
  }, [handleSubmit, onSignUpAndJoin, onAccept, identity?.email, data, identities]);

  useEffect(() => {
    if (!data || !data.payload.is_account_exist) return;
    const { email } = data.payload;

    if (!identities.some((identity) => identity.email === email)) {
      const query = new URLSearchParams({
        redirect: location.pathname,
        email,
        flow: 'invitation',
      });
      history.push(`${GuestRoutes.SignIn()}?${query}`);
    }
  }, [data, identities]);

  if (!data) return null;

  return (
    <AuthDialog title={`Join ${data.payload.organization_name}`} actions={buttons}>
      <Typography variant="body1" color="textSecondary">
        {invitedBy} invited you to the organization
      </Typography>

      {identity && identity.email !== data?.payload.email ? (
        <Alert severity="info">
          You are currently logged in with {identity.email}. To access the new organization, you need to{' '}
          {data.payload.is_account_exist ? 'sign in' : 'sign up'} using the invited email address.
        </Alert>
      ) : null}

      {!data.payload.is_account_exist ? (
        <Grid container spacing={2} component={'form'} onSubmit={handleSubmit(onSignUpAndJoin)}>
          <Grid item xs={12}>
            <TextField fullWidth label={'Email'} type={'text'} disabled={true} value={data.payload.email} />
          </Grid>

          <Grid item xs={6}>
            <TextInputController
              control={control}
              errors={errors}
              label={'First name'}
              name={'firstName'}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInputController
              control={control}
              errors={errors}
              label={'Last name'}
              name={'lastName'}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordInputController
              control={control}
              errors={errors}
              label={'Set password'}
              name={'password'}
              rules={{ required: true }}
            />
          </Grid>

          <HiddenSubmit />
        </Grid>
      ) : null}
    </AuthDialog>
  );
};

export const JoinPage: FC = () => {
  return (
    <InvitationDetailsFetcher.WAF>
      <JoinComponent />
    </InvitationDetailsFetcher.WAF>
  );
};
