import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { useAutoFetch } from '../../contexts/Fetcher';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { AddProductToolDialog } from './AddProductToolDialog';
import { useIntParams } from '../../hooks/useIntParams';
import { EditProductToolDialog } from './EditProductToolDialog';
import { useHistory } from 'react-router-dom';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { BoolChip } from '../BoolChip/BoolChip';
import { getBoolFilterOperator } from '../../filterOperators/boolFilterOperator';
import { useDialog } from '../../hooks/useDialog';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { useCloseDialog } from '../../hooks/useCloseDialog';

interface IUrlParams {
  id?: string;
}

export const ProductTools: FC = () => {
  const { data } = useContext(ProductToolsFetcher.Context);
  useAutoFetch(ProductToolsFetcher);

  const { isOpen: isAdding, close: closeDialog } = useDialog('new');

  const abortEdit = useCloseDialog(AuthenticatedRoutes.AdminProductsTab('tools'));

  const { id } = useIntParams<IUrlParams>();
  const history = useHistory();
  useDefaultSorting('id', 'asc');
  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
      },
      {
        field: 'key',
        headerName: t('adminProducts.tools.table.keyHeader'),
        flex: 1,
      },
      {
        field: 'filter',
        headerName: t('adminProducts.tools.table.filterHeader'),
        width: 120,
        renderCell: BoolChip,
        filterOperators: [getBoolFilterOperator()],
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
      },
    ],
    []
  );

  const getRowLink = useCallback((params: GridRowParams) => {
    return `${AuthenticatedRoutes.AdminProductsTabEditItem('tools', params.row.id)}${history.location.search}`;
  }, []);
  /** @todo add proper isAllowed check */
  const isGetRowLinkAllowed = true; //useIsAdminRouteAllowed('ProductsTabEditItem', 'tools');

  if (!data) return null;
  const tool = data.tools.find((t) => t.id === id);

  return (
    <div>
      <UrlQueryDataGrid
        columns={columns}
        rows={data.tools}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />
      <AddProductToolDialog open={isAdding} closeDialog={closeDialog} />
      <EditProductToolDialog tool={tool} closeDialog={abortEdit} />
    </div>
  );
};
