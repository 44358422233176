import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const userResources = {
  createUser: createResourceBuilder('/identities/user', EResourceMethod.Post),
  updateUser: createResourceBuilder('/identities/user/:user_id', EResourceMethod.Patch, (user_id: string) => ({
    user_id,
  })),
  getUser: createResourceBuilder('/identities/user/:user_id', EResourceMethod.Get, (user_id: string) => ({ user_id })),
  getCurrentUserInfo: createResourceBuilder('/identities/user/me/info', EResourceMethod.Get),
  getUsers: createResourceBuilder('/identities/user', EResourceMethod.Get),
  getUsersByIds: createResourceBuilder('/identities/user/by-ids', EResourceMethod.Get),
  getUsersByRoles: createResourceBuilder('/identities/by-roles', EResourceMethod.Get),
  getAdmins: createResourceBuilder('/identities/user/admins', EResourceMethod.Get),
  assignRole: createResourceBuilder('/identities/user/:user_id/role', EResourceMethod.Post, (user_id: string) => ({
    user_id,
  })),
  updateDefaultOrganization: createResourceBuilder(
    '/identities/user/:user_id/set-default-organization/:organization_id',
    EResourceMethod.Patch,
    (user_id: string, organization_id: string) => ({ user_id, organization_id })
  ),
  acceptUserAgreement: createResourceBuilder(
    '/identities/user/:user_id/aggreemenets',
    EResourceMethod.Patch,
    (user_id: string) => ({ user_id })
  ),
  requestEmailChange: createResourceBuilder(
    '/identities/user/:user_id/request-change-email',
    EResourceMethod.Post,
    (user_id: string) => ({ user_id })
  ),
  submitEmailChange: createResourceBuilder('/identities/user/change-email', EResourceMethod.Post),
  getMfaStatus: createResourceBuilder('/identities/user/:user_id/2fa', EResourceMethod.Get, (user_id: string) => ({
    user_id,
  })),
} as const;
