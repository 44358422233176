import { createFetcher } from '../contexts/Fetcher';
import { apiProductRelease } from '../resources/api/apiProductRelease';
import { useIntParams } from '../hooks/useIntParams';

interface IUrlParams {
  productId?: string;
}

const { useGetProductReleases } = apiProductRelease;

export const ReleasesFetcher = createFetcher(() => {
  const { productId } = useIntParams<IUrlParams>();
  return useGetProductReleases(productId).execute;
});
