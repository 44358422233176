import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationContext } from '../../contexts/NotificationContext';
import { OrganizationFetcher } from '../Customer/CustomerFetchers';
import { EditOrganizationForm, TEditOrganizationFormType } from './EditOrganizationForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { compareChanges } from '../../helpers/compareChanges';
import { apiOrganization } from '../../resources/api/apiOrganization';
import { useHistory } from 'react-router-dom';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { useDecodedParams } from '../../hooks/useDecodedParams';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

const { useUpdateOrganizationByName } = apiOrganization;

export const EditOrganizationDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(OrganizationFetcher.Context);
  const { organization_name } = useDecodedParams<{ organization_name: string }>();

  const history = useHistory();

  const { execute: updateOrganization } = useUpdateOrganizationByName(organization_name);

  const { addNotification } = useContext(NotificationContext);
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();

  const editOrganizationForm = useForm<TEditOrganizationFormType>();
  // const { control, handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    if (!open) {
      editOrganizationForm.reset();
    } else if (data?.payload) {
      editOrganizationForm.setValue('same_billing_address', data.payload.address === data.payload.billing_address);
    }
  }, [open, data]);

  // useEffect(() => {
  //   if (data?.payload) {
  //     editOrganizationForm.setValue('same_billing_address', data.payload.address === data.payload.billing_address);
  //   }
  // }, [data]);

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ same_billing_address, ...fields }: TEditOrganizationFormType) => {
      if (!data) return;

      const { anythingChanged: anythingToUpdate, changes: payload } = compareChanges(
        {
          name: data.payload.name,
          address: data.payload.address,
          phone_number: data.payload.phone_number ?? '',
          notes: data.payload.notes,
          billing_address: data.payload.billing_address,
          pixotope_representative: data.payload.pixotope_representative,
          country: data?.payload.country ?? '',
          /** @todo check that */
          domain: '',
        },
        fields
      );

      if (anythingToUpdate) {
        try {
          // const updatedData = await updateOrganization(payload);
          const updatedData = await updateOrganization({
            ...payload,
            // @todo revisit once CLOUD-1543 is done
            // country: fields.country || void 0,
            notes: fields.notes,
          });
          /** @todo check if we can use update response */
          // onDataUpdate(newCustomerDetails);
          if (updatedData.payload.name !== organization_name) {
            history.push(AuthenticatedRoutes.OrganizationDetails(updatedData.payload.name));
          } else {
            onDataUpdate(updatedData);
            // await onRefresh();
          }
          closeDialog();
        } catch (e) {
          handleError(e);
        }
      } else {
        addNotification({
          severity: 'warning',
          message: t('editOrganizationDialog.edit.nothingChanged'),
        });
      }
    },
    [data, updateOrganization, closeDialog, organization_name]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editOrganizationDialog.edit.title')}</DialogTitle>
      <DialogContent>
        <EditOrganizationForm onSubmit={onSubmit} organization={data.payload} form={editOrganizationForm} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={editOrganizationForm.handleSubmit(onSubmit)}>
          {t('editOrganizationDialog.edit.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
