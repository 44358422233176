import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { useForm } from 'react-hook-form';
import { IProductFormPayload, ProductForm } from './ProductForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { IProductsResponse } from '../../models/ProductsResponse';
import { Single } from '../../types/single';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { Link } from 'react-router-dom';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../hooks/useDialog';
import { apiProduct } from '../../resources/api/apiProduct';

interface IProps {
  product?: Single<IProductsResponse['products']>;
  closeDialog: CloseDialogFunc;
}

const { useDeleteProduct, useUpdateProduct } = apiProduct;

export const EditProductDialog: FC<IProps> = ({ product, closeDialog }) => {
  const form = useForm();
  const handleError = useApiErrorHandler();

  const { execute: deleteProduct, Guard: GuardDeleteProduct } = useDeleteProduct(product?.id);
  const { execute: updateProduct } = useUpdateProduct(product?.id);

  const { onRefresh } = useContext(ProductsFetcher.Context);

  const { isOpen: isDeleting, openUrl: deleteUrl, close: abortDelete } = useDialog('delete', 'subdialog');

  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      name: product?.name ?? '',
      plans: product?.plans.filter((plan) => plan).map((plan) => plan!.id) ?? [],
    });
  }, [product]);

  const onSubmit = useCallback(
    async (payload: IProductFormPayload) => {
      try {
        const result = await updateProduct(payload);
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [updateProduct]
  );

  const onDelete = useCallback(async () => {
    try {
      await deleteProduct();
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [deleteProduct]);

  return (
    <Dialog open={!!product} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('adminProducts.products.editDialog.title', { id: product?.id })}</DialogTitle>
      <DialogContent>
        <ProductForm form={form} onSubmit={onSubmit} isEdit />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>

        {product ? (
          <GuardDeleteProduct>
            <DangerousButton component={Link} to={deleteUrl}>
              {t('common.button.delete')}
            </DangerousButton>
          </GuardDeleteProduct>
        ) : null}

        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.products.editDialog.confirm')}
        </Button>
      </DialogActions>

      {product ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('adminProducts.products.deleteDialog.title')}
          dangerous
          confirm={onDelete}
          abort={abortDelete}
          message={t('adminProducts.products.deleteDialog.message', { name: product.name })}
        />
      ) : null}
    </Dialog>
  );
};
