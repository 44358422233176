import { getUseResource } from '../resource';
import { userResources } from '../builders/userResources';
import { IAdminInfo, IBasicUserInfo, IUserInfo, IUserInfoWithOrganizations } from '../models/IUserInfo';
import { IResponse } from '../models/IResponse';
import { IPage } from '../models/IPage';
import { paginatedResourceQuery } from '../paginatedResourceQuery';
import { IServerSidePaginatedFetcherParams } from '../../contexts/Fetcher';
import { EMfaMethod } from './apiAuth';

export type TUpdateUserPayload = Partial<Pick<IBasicUserInfo, 'first_name' | 'last_name' | 'phone_number'>>;
export type TCreateUserPayload = TUpdateUserPayload & Pick<IBasicUserInfo, 'email'> & { password: string };
export interface IAssignRolePayload {
  role_id: string;
  user_id: string;
  organization_id: string;
}

interface IMFaStatusUnset {
  mfa_enabled: false;
}
interface IMfaStatusSet {
  mfa_enabled: true;
  default_method_type: EMfaMethod;
  methods: Array<{
    method_name: EMfaMethod;
    default: boolean;
  }>;
}
export type TMfaStatus = IMFaStatusUnset | IMfaStatusSet;

export const apiUser = {
  useCreateUser: getUseResource(
    userResources.createUser,
    (body): IResponse<IBasicUserInfo> => body,
    (params, payload: TCreateUserPayload) => payload
  ),
  useUpdateUser: getUseResource(
    userResources.updateUser,
    (body): IResponse<IBasicUserInfo> => body,
    (params, payload: TUpdateUserPayload) => payload
  ),
  useGetUser: getUseResource(userResources.getUser, (body): IResponse<IUserInfoWithOrganizations> => body),
  useGetCurrent: getUseResource(userResources.getCurrentUserInfo, (body): IResponse<IUserInfo> => body),
  useGetUsers: getUseResource(
    userResources.getUsers,
    (body): IResponse<IPage<IUserInfoWithOrganizations>> => body,
    paginatedResourceQuery
  ),
  useGetUsersByIds: getUseResource(
    userResources.getUsersByIds,
    (body): IResponse<IPage<Pick<IBasicUserInfo, 'id' | 'first_name' | 'last_name' | 'email'>>> => body,
    (params, users: string[], pagination: IServerSidePaginatedFetcherParams) => ({
      users: users.join(','),
      ...paginatedResourceQuery(params, pagination),
    })
  ),
  useGetUsersByRoles: getUseResource(userResources.getUsersByRoles, (body): IResponse<IPage<IBasicUserInfo>> => body),
  useGetAdmins: getUseResource(
    userResources.getAdmins,
    (body): IResponse<IPage<IAdminInfo>> => body,
    (params, pagination: IServerSidePaginatedFetcherParams, roleIds?: string[]) => {
      return {
        role_ids: roleIds,
        ...paginatedResourceQuery(params, pagination),
      };
    }
  ),
  useAssignRole: getUseResource(
    userResources.assignRole,
    (body): IUserInfo => body,
    (params, payload: IAssignRolePayload) => payload
  ),
  useUpdateDefaultOrganization: getUseResource(
    userResources.updateDefaultOrganization,
    (body): IResponse<IBasicUserInfo> => body
  ),
  useAcceptUserAgreement: getUseResource(
    userResources.acceptUserAgreement,
    (body): IResponse<IBasicUserInfo> => body,
    (params, eula: boolean, user_license: boolean) => ({ eula, user_license })
  ),
  useRequestEmailChange: getUseResource(
    userResources.requestEmailChange,
    (body): IResponse<void> => body,
    (params, new_email: string) => ({ new_email })
  ),
  useSubmitEmailChange: getUseResource(
    userResources.submitEmailChange,
    (body): IResponse<void> => body,
    (params, token: string) => ({ token })
  ),
  useGetMfaStatus: getUseResource(userResources.getMfaStatus, (body): IResponse<TMfaStatus> => body),
};
