import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { PixoThemeProvider } from './contexts/ThemeContext';
import { FetcherInvalidatorProvider } from './contexts/FetcherInvalidator';
import { initializeI18n } from './translations';
import { initializeSentry } from './sentry';
import { GlobalContextContainer } from './contexts/GlobalContext';
import { PermissionContextProvider } from './contexts/PermissionContextProvider';
import { UserContextProvider } from './contexts/UserContextProvider';
import { OrganizationContextProvider } from './contexts/OrganizationContextProvider';

initializeI18n();
initializeSentry();

ReactDOM.render(
  <GlobalContextContainer>
    <NotificationProvider>
      <LoadingProvider>
        <AuthProvider>
          <UserContextProvider>
            <OrganizationContextProvider>
              <PermissionContextProvider>
                <FetcherInvalidatorProvider>
                  <PixoThemeProvider>
                    <App />
                  </PixoThemeProvider>
                </FetcherInvalidatorProvider>
              </PermissionContextProvider>
            </OrganizationContextProvider>
          </UserContextProvider>
        </AuthProvider>
      </LoadingProvider>
    </NotificationProvider>
  </GlobalContextContainer>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
