import React, { FC, useCallback, useContext, useEffect } from 'react';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductPlanFormPayload, ProductPlanForm } from './ProductPlanForm';
import { useForm } from 'react-hook-form';
import { apiProductPlan } from '../../resources/api/apiProductPlan';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { useTranslation } from 'react-i18next';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';

interface IProps {
  open: boolean;
  closeDialog: CloseDialogFunc;
}

const { useCreatePlan } = apiProductPlan;

export const AddProductPlanDialog: FC<IProps> = ({ open, closeDialog }) => {
  const form = useForm();
  const { execute: createPlan } = useCreatePlan();
  const handleError = useApiErrorHandler();
  const { onRefresh } = useContext(ProductPlansFetcher.Context);
  const { invalidateData } = useContext(ProductsFetcher.Context);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      name: '',
      type: 'subscription',
      price: '0',
      duration: '1',
      productId: '',
      packages: [],
      watermark: false,
      customerPackageDefaultProperties: {},
    });
  }, [open, form]);

  const onSubmit = useCallback(async (data: IProductPlanFormPayload) => {
    try {
      const result = await createPlan({
        name: data.name,
        duration: parseFloat(data.duration),
        price: parseInt(data.price, 10) * 100,
        productId: data.productId,
        productCode: data.productCode,
        type: data.type,
        watermark: data.watermark,
        customerPackageDefaultProperties: data.customerPackageDefaultProperties,
        currency: 'usd',
        packages: { data: data.packages },
        archived: false,
      });
      await onRefresh();
      invalidateData();
      closeDialog(result.id);
    } catch (e) {
      handleError(e);
    }
  }, []);

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('adminProducts.plans.addDialog.title')}</DialogTitle>
      <DialogContent>
        <ProductPlanForm form={form} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.plans.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
