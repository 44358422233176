import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountTree from '@mui/icons-material/AccountTree';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import Home from '@mui/icons-material/Home';
import GetApp from '@mui/icons-material/GetApp';
import MenuIcon from '@mui/icons-material/Menu';
import Payment from '@mui/icons-material/Payment';
import People from '@mui/icons-material/People';
import Public from '@mui/icons-material/Public';
import Help from '@mui/icons-material/Help';
import Timeline from '@mui/icons-material/Timeline';
import WatchLater from '@mui/icons-material/WatchLater';
import ListIcon from '@mui/icons-material/List';
import Description from '@mui/icons-material/Description';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Language from '@mui/icons-material/Language';
import Cloud from '@mui/icons-material/Cloud';
import { LoadingContext } from '../contexts/LoadingContext';
import { Notifications } from '../components/Notifications';
import {
  DashboardAdminDrawerItem,
  DashboardDrawerItem,
  DashboardGuardedDrawerItem,
} from './partials/DashboardDrawerItem';
import { UserRoutes } from '../routers/UserRoutes';
import { CustomerRoutes } from '../routers/CustomerRoutes';
import { useHistory } from 'react-router-dom';
import { MenuLink } from './partials/MenuLink';
import { useEnumState } from '../hooks/useEnumState';
import { config } from '../config';
import { combineCn } from '../helpers/combineCn';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Licenses, ProductFilled } from '../components/Icon/Icon';
import MenuOpen from '@mui/icons-material/MenuOpen';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { apiOrganization } from '../resources/api/apiOrganization';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { AuthContext } from '../contexts/AuthContext';
import { useQueryUrl } from '../hooks/useQueryUrl';
import { useCloseDialog } from '../hooks/useCloseDialog';
import { ChooseOrganizationModal } from '../components/ChooseOrganizationModal/ChooseOrganizationModal';
import { useQueryParam } from '../hooks/useQueryParam';
import { CorporateFare } from '@mui/icons-material';
import { useAuth } from '../hooks/useAuth';
import { GuestRoutes } from '../routers/GuestRouter';

const drawerWidth = 240;
const topBarHeight = 48;

const augmentWithOpacity = (color: string, opacity: number) => {
  if (color.startsWith('#')) {
    return (
      color +
      Math.round(opacity * 255)
        .toString(16)
        .padStart(2, '0')
    );
  } else if (color.startsWith('rgb(')) {
    return `rgba${color.substr(3, color.length - 4)}, ${opacity})`;
  }
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      display: 'flex',
      backgroundImage: `linear-gradient(45.05deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    appBarBrand: {
      display: 'flex',
      alignSelf: 'center',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    appBarDivider: {
      width: 0,
      height: 16,
      borderLeft: `1px solid ${theme.palette.text.primary}`,
      opacity: 0.3,
      margin: '0 20px 0 4px',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: theme.palette.background.paper,
    },
    mobileDrawerPaper: {
      minWidth: drawerWidth,
      width: 'calc(100vw - 64px)',
      backdropFilter: 'blur(4px) grayscale(1)',
      background: augmentWithOpacity(theme.palette.background.paper, 0.8),
      borderRight: `4px solid ${theme.palette.primary.dark}`,
    },
    content: {
      width: '100%',
      flexGrow: 1,
      marginTop: topBarHeight,
      padding: theme.spacing(3),
      minHeight: `calc(100vh - ${topBarHeight}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    backdrop: {
      zIndex: 9999,
    },
    drawerFiller: {
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      overflow: 'hidden',
      ...theme.mixins.toolbar,
    },
    menuPaper: {
      marginTop: '18px',
    },
  });
});

// const NavCategory = styled('div')({});

const { useListOrganizationUsers } = apiOrganization;

export const DashboardLayout: FC = ({ children }) => {
  const styles = useStyles();
  const profileIconRef = useRef(null);
  const [isProfileMenuOpen, openProfileMenu, closeProfileMenu] = useEnumState(false, true, false);

  const { logOut } = useAuth();

  const { isLoading } = useContext(LoadingContext);
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const { identity, identities, setIdentity } = useContext(AuthContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [languagePicker] = useLocalStorage('languagePicker', false);
  const [language, setLanguage] = useLocalStorage('language', i18n.language);
  // CLOUD-1186: Disable 2FA tooltip
  // useEncourageMFA();

  const isOrganizationDialogOpen = useQueryParam('dialog') === 'organization';
  const chooseOrganizationUrl = useQueryUrl('dialog', 'organization');
  const closeDialog = useCloseDialog(void 0, ['dialog']);

  const { organization, organizationId, setOrganization } = useContext(OrganizationContext);

  const { Guard: GuardOrganizationUsers } = useListOrganizationUsers(organization?.name);

  useEffect(() => {
    if (isLoading) {
      const listener = (e: KeyboardEvent) => e.preventDefault();
      document.addEventListener('keydown', listener);
      return () => document.removeEventListener('keydown', listener);
    }
  }, [isLoading]);

  const handleDrawerToggle = useCallback(() => {
    setDrawerExpanded(!drawerExpanded);
  }, [drawerExpanded]);

  useEffect(() => {
    return history.listen(() => {
      closeProfileMenu();
      setDrawerExpanded(false);
    });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language).then();
  }, [language]);

  const drawer = (
    <div>
      <div className={styles.drawerFiller}>
        <IconButton onClick={handleDrawerToggle}>
          <MenuOpen />
        </IconButton>
      </div>
      <List>
        <DashboardDrawerItem route={AuthenticatedRoutes.Welcome} label={'Welcome'} icon={Home} />

        {organization ? (
          <>
            <GuardOrganizationUsers>
              <DashboardDrawerItem route={AuthenticatedRoutes.OrganizationUsers} label={'Users'} icon={People} />
            </GuardOrganizationUsers>
          </>
        ) : null}

        {!organizationId ? (
          <>
            <DashboardGuardedDrawerItem
              route={'Organizations'}
              label={t('layout.adminNavigation.organizations')}
              icon={CorporateFare}
            />
            <DashboardGuardedDrawerItem route="AllUsers" label={t('layout.adminNavigation.users')} icon={People} />

            <DashboardDrawerItem
              route={AuthenticatedRoutes.AdminProducts}
              label={t('layout.adminNavigation.products')}
              icon={ProductFilled}
            />

            <DashboardDrawerItem
              route={AuthenticatedRoutes.Releases}
              label={t('layout.adminNavigation.releases')}
              icon={AccountTree}
            />
          </>
        ) : null}
      </List>

      {(() => false)() ? (
        <List>
          {/* USER */}
          <DashboardDrawerItem route={UserRoutes.Overview} label={t('layout.userNavigation.overview')} icon={Home} />
          <DashboardDrawerItem
            route={UserRoutes.Licenses}
            label={t('layout.userNavigation.licenses')}
            icon={Licenses}
          />
          <DashboardDrawerItem
            route={UserRoutes.Downloads}
            label={t('layout.userNavigation.downloads')}
            icon={GetApp}
          />
          <DashboardDrawerItem
            route={UserRoutes.Documentation}
            label={t('layout.userNavigation.documentation')}
            icon={Description}
          />
          <DashboardDrawerItem route={UserRoutes.Support} label={t('layout.userNavigation.support')} icon={Help} />
          {config.enableLive ? (
            <DashboardDrawerItem
              route={UserRoutes.LivePreview}
              label={t('layout.userNavigation.live')}
              icon={PhotoCamera}
            />
          ) : null}
          {config.enableDeveloperTools ? (
            <>
              <Divider />
              <DashboardDrawerItem
                route={UserRoutes.Events}
                label={t('layout.userNavigation.events')}
                icon={ListIcon}
              />
            </>
          ) : null}

          {/* CUSTOMER */}
          <DashboardDrawerItem
            route={CustomerRoutes.Overview}
            label={t('layout.customerNavigation.overview')}
            icon={Home}
          />
          <DashboardDrawerItem
            route={CustomerRoutes.Users}
            label={t('layout.customerNavigation.users')}
            icon={People}
          />
          <DashboardDrawerItem
            route={CustomerRoutes.Products}
            label={t('layout.customerNavigation.products')}
            icon={ProductFilled}
          />
          <DashboardDrawerItem
            route={CustomerRoutes.Downloads}
            label={t('layout.customerNavigation.downloads')}
            icon={GetApp}
          />
          {config.enableInvoices ? (
            <DashboardDrawerItem
              route={CustomerRoutes.Invoices}
              label={t('layout.customerNavigation.invoices')}
              icon={Payment}
            />
          ) : null}
          <DashboardDrawerItem
            route={CustomerRoutes.Documentation}
            label={t('layout.customerNavigation.documentation')}
            icon={Description}
          />
          <DashboardDrawerItem
            route={CustomerRoutes.Support}
            label={t('layout.customerNavigation.support')}
            icon={Help}
          />
          {config.enableLive ? (
            <DashboardDrawerItem
              route={CustomerRoutes.LivePreview}
              label={t('layout.customerNavigation.live')}
              icon={PhotoCamera}
            />
          ) : null}

          {/* ADMIN */}
          <DashboardAdminDrawerItem route="Overview" label={t('layout.adminNavigation.overview')} icon={Home} />
          <DashboardAdminDrawerItem
            route="Admins"
            label={t('layout.adminNavigation.admins')}
            icon={AdminPanelSettings}
          />
          {/*<DashboardAdminDrawerItem*/}
          {/*  route="Organizations"*/}
          {/*  label={t('layout.adminNavigation.customers')}*/}
          {/*  icon={PersonAddAlt1}*/}
          {/*/>*/}
          <DashboardAdminDrawerItem route="Users" label={t('layout.adminNavigation.users')} icon={People} />
          {/*<DashboardAdminDrawerItem*/}
          {/*  route="Products"*/}
          {/*  label={t('layout.adminNavigation.products')}*/}
          {/*  icon={ProductFilled}*/}
          {/*/>*/}
          {/*<DashboardAdminDrawerItem route="Releases" label={t('layout.adminNavigation.releases')} icon={AccountTree} />*/}
          <DashboardAdminDrawerItem route="Support" label={t('layout.adminNavigation.support')} icon={Help} />
          <DashboardAdminDrawerItem route="Tasks" label={t('layout.adminNavigation.tasks')} icon={WatchLater} />
          <DashboardAdminDrawerItem
            route="AnalyticsActivity"
            label={t('layout.adminNavigation.activity')}
            icon={Public}
          />
          <DashboardAdminDrawerItem route="Balance" label={t('layout.adminNavigation.balance')} icon={Timeline} />

          <DashboardAdminDrawerItem route="AllLiveStacks" label={t('layout.adminNavigation.cloudLive')} icon={Cloud} />

          <Divider />
          <DashboardAdminDrawerItem route="Events" label={t('layout.adminNavigation.events')} icon={ListIcon} />
        </List>
      ) : null}
    </div>
  );

  return (
    <div className={styles.root}>
      <CssBaseline />
      <AppBar position="fixed" className={styles.appBar}>
        <Toolbar>
          <Hidden smUp>
            <IconButton color="inherit" onClick={handleDrawerToggle} size="large">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={styles.appBarBrand}>
            <img src={config.pixotopeLogo} />
            <Hidden smDown>
              <div className={styles.appBarDivider} />
              <img src={config.cloudLogo} />
            </Hidden>
          </div>
          <div onClick={openProfileMenu} ref={profileIconRef}>
            <IconButton color="inherit" size="large">
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>

        <Menu
          anchorEl={profileIconRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isProfileMenuOpen}
          onClose={closeProfileMenu}
          classes={{
            paper: styles.menuPaper,
          }}
        >
          {identity ? <MenuItem disabled>{identity.email}</MenuItem> : null}
          <MenuLink to={AuthenticatedRoutes.Security()}>{t('layout.accountItems.security') as string}</MenuLink>

          <MenuLink to={chooseOrganizationUrl}>{t('layout.accountItems.organization') as string}</MenuLink>

          {languagePicker && i18n.language !== 'en' ? (
            <MenuItem onClick={() => setLanguage('en')}>
              <Language /> English
            </MenuItem>
          ) : null}
          {languagePicker && i18n.language !== 'pl' ? (
            <MenuItem onClick={() => setLanguage('pl')}>
              <Language /> Polski
            </MenuItem>
          ) : null}

          <Divider />
          <MenuItem
            onClick={() => {
              history.push(GuestRoutes.SignIn());
            }}
          >
            Log in to different account
          </MenuItem>
          {identities
            .filter(({ email }) => email !== identity?.email)
            .map((identity) => (
              <MenuItem
                key={identity.email}
                onClick={() => {
                  setIdentity(identity);
                }}
              >
                Switch to {identity.email}
              </MenuItem>
            ))}

          <Divider />
          <MenuItem onClick={logOut}>{t('layout.accountItems.logOut')}</MenuItem>
        </Menu>
      </AppBar>

      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          className={styles.drawer}
          classes={{
            paper: combineCn({ [styles.drawerPaper]: true, [styles.mobileDrawerPaper]: true }),
          }}
          open={drawerExpanded}
          onClose={handleDrawerToggle}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          className={styles.drawer}
          classes={{
            paper: styles.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      {/* @todo add trans */}
      <ChooseOrganizationModal
        cancelText={'Cancel'}
        cancelAction={closeDialog}
        submitText={'Confirm'}
        submitAction={(org) => {
          setOrganization(org);
          closeDialog();
        }}
        noOrgText={'No organization'}
        noOrgAction={() => {
          setOrganization(null);
          closeDialog();
        }}
        open={isOrganizationDialogOpen}
        noBackdrop={false}
      />

      <main className={styles.content}>{children || ''}</main>

      <Notifications />
      <Backdrop open={isLoading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
