import { createFetcher, createServerSidePaginatedFetcher } from '../../contexts/Fetcher';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useCallback } from 'react';
import { IOrganizationUrlParams } from '../../pages/Organization';
import { apiOrganization } from '../../resources/api/apiOrganization';
import { apiOrganizationProduct } from '../../resources/api/apiOrganizationProduct';
import { useDecodedParams } from '../../hooks/useDecodedParams';

const { useGetOrganizationByName, useListOrganizationUsers } = apiOrganization;
const { useGetOrganizationProducts } = apiOrganizationProduct;

export const OrganizationFetcher = createFetcher(() => {
  const { organization_name } = useDecodedParams<{ organization_name: string }>();

  const { execute } = useGetOrganizationByName(organization_name);

  return execute;
});

export const CustomerProductsFetcher = createFetcher(() => {
  const { organization_name } = useDecodedParams<IOrganizationUrlParams>();

  const { execute } = useGetOrganizationProducts(organization_name);

  return execute;
});

export const CustomerUsersFetcher = createServerSidePaginatedFetcher(() => {
  const { organization_name } = useDecodedParams<IOrganizationUrlParams>();
  const { execute } = useListOrganizationUsers(organization_name);

  return execute;
});

export const CustomerTicketsFetcher = createFetcher(() => {
  const { getCustomerTickets } = useCustomersApi();
  // @todo needs refactoring
  const customerId = 0; // const { customerId } = useIntParams<IOrganizationUrlParams>();

  return useCallback(() => {
    return getCustomerTickets([customerId]);
  }, [getCustomerTickets, customerId]);
});

export const CustomerInvoicesFetcher = createFetcher(() => {
  const { getCustomerInvoices } = useCustomersApi();
  // @todo needs refactoring
  const customerId = 0; // const { customerId } = useIntParams<IOrganizationUrlParams>();

  return useCallback(() => {
    return getCustomerInvoices([customerId]);
  }, [getCustomerInvoices, customerId]);
});

export const CustomerAvailableProductsFetcher = createFetcher(() => {
  const { getAvailableProducts } = useCustomersApi();
  // @todo needs refactoring
  const customerId = 0; // const { customerId } = useIntParams<IOrganizationUrlParams>();

  return useCallback(() => {
    return getAvailableProducts([customerId]);
  }, [getAvailableProducts, customerId]);
});
