import React, { FC, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationContext } from '../../contexts/NotificationContext';
import { EditUserForm } from './EditUserForm';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import { userFormSchema } from '../../formSchemas/userFormSchema';
import { UserFetcher } from '../../fetchers/UserFetcher';
import { apiUser, TUpdateUserPayload } from '../../resources/api/apiUser';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

const { useUpdateUser } = apiUser;

export const EditUserDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(UserFetcher.Context);

  const { execute: updateUser } = useUpdateUser(data?.payload.id);

  const handleError = useApiErrorHandler();

  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const { control, handleSubmit, formState } = useForm({
    resolver: joiResolver(userFormSchema),
  });

  const onSubmit = useCallback(
    async (fields: TUpdateUserPayload) => {
      if (!data) return;
      const payload: Partial<TUpdateUserPayload> = {};
      let anythingToUpdate = false;
      for (const [field, value] of Object.entries(fields) as [
        keyof TUpdateUserPayload,
        TUpdateUserPayload[keyof TUpdateUserPayload]
      ][]) {
        if (value !== data.payload[field]) {
          anythingToUpdate = true;
          payload[field] = value;
        }
      }
      if (anythingToUpdate) {
        try {
          const newUserDetails = await updateUser(payload);
          onDataUpdate({
            ...data,
            payload: {
              ...data.payload,
              ...newUserDetails.payload,
            },
          });
          closeDialog();
        } catch (e) {
          handleError(e);
        }
      } else {
        addNotification({
          severity: 'warning',
          message: t('editUserDialog.edit.nothingChanged'),
        });
      }
    },
    [data, updateUser, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editUserDialog.edit.title')}</DialogTitle>
      <DialogContent>
        {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
        <EditUserForm onSubmit={onSubmit} user={data.payload} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editUserDialog.edit.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
