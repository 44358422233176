import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CustomerLiveFetcher } from '../../fetchers/CustomerLiveFetcher';
import { LivePreviewContent } from '../CloudLive/CloudLive';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useIntParams } from '../../hooks/useIntParams';
import { CustomerNewLiveStack } from './CustomerNewLiveStack';
import { useAdminLiveApi } from '../../hooks/useAdminLiveApi';
import { LiveStackContext } from '../../contexts/LiveChannels';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { LiveRegionSelect } from '../LiveRegionSelect/LiveRegionSelect';
import { LiveRegionContext, LiveRegionContextProvider } from '../../contexts/LiveRegionContext';
import { LiveRegionsFetcher } from '../../fetchers/LiveRegionsFetcher';
import { composeFetcher, createFetcherWrapper } from '../../contexts/Fetcher';
import { OrganizationFetcher } from './CustomerFetchers';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../hooks/useDialog';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useCloseDialog } from '../../hooks/useCloseDialog';

interface IUrlParams {
  customerId: string;
  itemId?: string;
}

const LiveRegionsPerCustomerFetcherWrapper = createFetcherWrapper(LiveRegionsFetcher.Context, () => {
  const { customerId } = useIntParams<IUrlParams>();
  const { getCustomerRegions } = useAdminLiveApi();

  return useCallback(() => {
    return getCustomerRegions([customerId]);
  }, [customerId, getCustomerRegions]);
});

const LiveRegionsPerCustomerFetcher = composeFetcher(LiveRegionsFetcher.Context, LiveRegionsPerCustomerFetcherWrapper);

const CustomerLiveContent: FC = () => {
  const { onRefresh } = useContext(CustomerLiveFetcher.Context);
  const { itemId } = useParams<IUrlParams>();
  const { customerId } = useIntParams<IUrlParams>();
  const history = useHistory();

  const { isOpen: isCreatingNew, openUrl: newUrl, close: abortNew } = useDialog('new');

  const deleteId = useQueryParam('delete');
  const isDeleting = !!deleteId;
  const closeDeleteDialog = useCloseDialog(void 0, ['delete']);

  const { removeLiveStack, start, stop, getAccessData, getAccessDataSilent } = useAdminLiveApi();
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();
  const { region } = useContext(LiveRegionContext);

  const onConfirmDelete = useCallback(async () => {
    if (!itemId || !customerId) return;

    try {
      await removeLiveStack([customerId, region, itemId]);
      await onRefresh();
      closeDeleteDialog();
    } catch (e) {
      handleError(e);
    }
  }, [itemId, removeLiveStack, region, customerId]);

  const contextVal = useMemo(
    () => ({
      remove: (stackId: string) => {
        const query = new URLSearchParams(history.location.search);
        query.set('delete', stackId);
        history.push(`?${query}`);
      },
      start: (stackId: string) => start([customerId, region, stackId]),
      stop: (stackId: string) => stop([customerId, region, stackId]),
      getAccessData: (stackId: string, silent = false) =>
        (silent ? getAccessDataSilent : getAccessData)([customerId, region, stackId]),
    }),
    [customerId, start, stop, getAccessData, region]
  );

  return (
    <LiveRegionsPerCustomerFetcher.WAF>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4} lg={2}>
          <LiveRegionSelect />
        </Grid>
        <Grid item xs={12} md={8} lg={10}>
          {/** @todo add Guard here */}
          <Button component={Link} to={newUrl} variant="contained" color="primary">
            {t('organization.live.addButton')}
          </Button>
        </Grid>
      </Grid>

      <Box marginTop={1}>
        <LiveStackContext.Provider value={contextVal}>
          <LivePreviewContent />
        </LiveStackContext.Provider>
      </Box>

      <CustomerNewLiveStack open={isCreatingNew} closeDialog={abortNew} />

      <ConfirmationDialog
        open={isDeleting}
        title={t('organization.live.deleteDialog.title')}
        confirm={onConfirmDelete}
        abort={closeDeleteDialog}
        message={t('organization.live.deleteDialog.message')}
        dangerous
        confirmLabel={t('common.button.delete')}
      />
    </LiveRegionsPerCustomerFetcher.WAF>
  );
};

export const CustomerLive: FC = () => {
  const { data } = useContext(OrganizationFetcher.Context);
  if (!data) return null;

  /** @todo previously a customer's email was passed to region context provider */
  return (
    <LiveRegionContextProvider>
      <CustomerLiveFetcher.WAF>
        <CustomerLiveContent />
      </CustomerLiveFetcher.WAF>
    </LiveRegionContextProvider>
  );
};
