import React, { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IBackendAccountData, ITokenData } from '../hooks/useAuth';

interface IBaseAccountData {
  userId: string;
  tokenData: ITokenData;
  email: string;
}

type IIdentityData = IBaseAccountData;

interface IUserAccountData extends IBaseAccountData {
  accountData: IBackendAccountData;
}

type ICustomerAccountData = IBaseAccountData;
type IAdminAccountData = IBaseAccountData;

interface IAuthContext {
  identities: IIdentityData[];

  identity: IIdentityData | null;
  setIdentity: (identity: IIdentityData | null) => void;
  forgetCurrentIdentity: () => void;

  /** @deprecated */
  user: IUserAccountData | null;
  /** @deprecated */
  setUser: (user: IUserAccountData | null) => void;

  /** @deprecated */
  customer: ICustomerAccountData | null;
  /** @deprecated */
  setCustomer: (customer: ICustomerAccountData | null) => void;

  /** @deprecated */
  admin: IAdminAccountData | null;
  /** @deprecated */
  setAdmin: (admin: IAdminAccountData | null) => void;

  auth2faCache: null | {
    email: string;
    access_token: string;
  };
  setAuth2faCache: (data: IAuthContext['auth2faCache']) => void;
}

const noop = () => null;

const defaultAuthContext: IAuthContext = {
  identities: [],

  identity: null,
  setIdentity: noop,
  forgetCurrentIdentity: () => void 0,
  // userData: null,

  user: null,
  setUser: noop,
  customer: null,
  setCustomer: noop,
  admin: null,
  setAdmin: noop,
  auth2faCache: null,
  setAuth2faCache: noop,
};

export const AuthContext = createContext<IAuthContext>(defaultAuthContext);

interface IIdentitiesData {
  current: string | null;
  identities: IIdentityData[];
}

const LOCAL_STORAGE_IDENTITIES_DATA_KEY = 'identitiesData';

const SESSION_STORAGE_2FA_CACHE_KEY = '2faCache';

const lsIdentitiesItem = localStorage.getItem(LOCAL_STORAGE_IDENTITIES_DATA_KEY);

const ss2faCacheItem = sessionStorage.getItem(SESSION_STORAGE_2FA_CACHE_KEY);

const updateLSData = (data: unknown | null, key: string) => {
  if (data) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.removeItem(key);
  }
};

const updateSSData = (data: unknown | null, key: string) => {
  if (data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  } else {
    sessionStorage.removeItem(key);
  }
};

export const AuthProvider: FC = ({ children }) => {
  const [identities, setIdentities] = useState<IIdentitiesData>(
    lsIdentitiesItem ? JSON.parse(lsIdentitiesItem) : { current: null, identities: [] }
  );

  const identity = identities.identities.find(({ email }) => email === identities.current) ?? null;

  const setIdentity = useCallback(
    (newIdentity: IIdentityData | null) => {
      const updatedIdentities = { ...identities };

      if (newIdentity !== null) {
        if (updatedIdentities.identities.find(({ email }) => email === newIdentity.email)) {
          updatedIdentities.identities = updatedIdentities.identities.map((identity) => {
            if (identity.email === newIdentity.email) {
              return newIdentity;
            }
            return identity;
          });
        } else {
          updatedIdentities.identities = [...updatedIdentities.identities, newIdentity];
        }

        updatedIdentities.current = newIdentity.email;
      } else {
        updatedIdentities.current = null;
      }

      setIdentities(updatedIdentities);
    },
    [identities]
  );

  const forgetCurrentIdentity = useCallback(() => {
    setIdentities((currentIdentities) => {
      return {
        current: null,
        identities: currentIdentities.identities.filter((identity) => identity.email !== currentIdentities.current),
      };
    });
  }, []);

  const [auth2faCache, setAuth2faCache] = useState<IAuthContext['auth2faCache']>(
    ss2faCacheItem ? JSON.parse(ss2faCacheItem) : null
  );

  useEffect(() => {
    updateLSData(identities, LOCAL_STORAGE_IDENTITIES_DATA_KEY);
  }, [identities]);

  useEffect(() => {
    updateSSData(auth2faCache, SESSION_STORAGE_2FA_CACHE_KEY);
  }, [auth2faCache]);

  const contextValue = useMemo(
    () => ({
      identities: identities.identities,

      identity,
      setIdentity,
      forgetCurrentIdentity,

      auth2faCache,
      setAuth2faCache,

      user: null,
      setUser: () => alert('deprecated'),
      customer: null,
      setCustomer: () => alert('deprecated'),
      admin: null,
      setAdmin: () => alert('deprecated'),
    }),
    [identities, identity, auth2faCache]
  );

  // useEffect(() => {
  //   console.log('auth context value', contextValue);
  // }, [contextValue]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
