import React, { FC, useCallback, useContext } from 'react';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { OrganizationFetcher } from '../components/Customer/CustomerFetchers';
import { OrganizationUserFetcher } from '../fetchers/OrganizationUserFetcher';
import { OrganizationContextProviderFromFetcher } from '../contexts/OrganizationContextProvider';
import { GuardedRouteLink, useIsGuardedRouteAllowed } from '../resources/GuardedRouteLink';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { AccountSingleData } from '../components/AccountData/AccountSingleData';
import Link from '@mui/material/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { apiOrganization } from '../resources/api/apiOrganization';
import { useDialog } from '../hooks/useDialog';
import Button from '@mui/material/Button';
import { ConfirmationDialog } from '../components/ConfirmationDialog/ConfirmationDialog';
import { useApiErrorHandler } from '../hooks/useApiErrorHandler';
import { NotificationContext } from '../contexts/NotificationContext';

const { useRemoveUser } = apiOrganization;

const UserOrganization: FC = () => {
  const handleError = useApiErrorHandler();

  const history = useHistory();

  const { organization } = useContext(OrganizationContext);
  const { data } = useContext(OrganizationUserFetcher.Context);

  const { t } = useTranslation();

  const isNoOrgUserAllowed = useIsGuardedRouteAllowed('User', ['any']);

  const { execute: removeUser, Guard: GuardRemoveUser } = useRemoveUser(organization?.name, data?.payload.id);

  const { isOpen: isDeleting, openUrl: deleteUrl, close: abortDelete } = useDialog('delete');

  const { addNotification } = useContext(NotificationContext);

  const onRemoveFromOrg = useCallback(async () => {
    if (!user || !organization) return;
    try {
      await removeUser();
      addNotification({
        severity: 'success',
        message: t('user.removeFromOrganization.success', {
          name: `${user.first_name} ${user.last_name}`,
          organization: organization.name,
        }),
      });
      if (isNoOrgUserAllowed) {
        history.push(AuthenticatedRoutes.User(user.id));
      } else {
        history.push(AuthenticatedRoutes.OrganizationUsers());
      }
    } catch (e) {
      handleError(e);
    }
  }, [isNoOrgUserAllowed, data?.payload]);

  if (!data || !organization) return null;

  const user = data.payload;
  const userName = `${user.first_name} ${user.last_name}`;

  return (
    <div>
      <PageHeader
        title={isNoOrgUserAllowed ? organization.name : userName}
        crumbs={
          isNoOrgUserAllowed
            ? [
                {
                  title: t('layout.adminNavigation.users'),
                  url: AuthenticatedRoutes.AllUsers(),
                },
                {
                  title: userName,
                  url: AuthenticatedRoutes.User(user.id),
                },
              ]
            : [
                {
                  title: t('layout.customerNavigation.users'),
                  url: AuthenticatedRoutes.OrganizationUsers(),
                },
              ]
        }
      >
        <GuardRemoveUser>
          <Button variant="outlined" component={RouterLink} to={deleteUrl}>
            {t('user.removeFromOrganization.button')}
          </Button>

          <ConfirmationDialog
            open={isDeleting}
            title={t('user.removeFromOrganization.button')}
            message={t('user.removeFromOrganization.message', {
              name: userName,
              organization: organization.name,
            })}
            confirm={onRemoveFromOrg}
            abort={abortDelete}
          />
        </GuardRemoveUser>
      </PageHeader>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.name')}>
            {data.payload.first_name} {data.payload.last_name}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.organization')}>
            <Link component={GuardedRouteLink} route={'OrganizationDetails'} params={[organization.name]} showFallback>
              {organization.name}
            </Link>
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.email')}>{data.payload.email}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.phone')}>{data.payload.phone_number}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.id')}>{data.payload.id}</AccountSingleData>
        </Grid>
      </Grid>
    </div>
  );
};

export const UserOrganizationPage: FC = () => {
  return (
    <OrganizationFetcher.WAF>
      <OrganizationContextProviderFromFetcher>
        <OrganizationUserFetcher.WAF>
          <UserOrganization />
        </OrganizationUserFetcher.WAF>
      </OrganizationContextProviderFromFetcher>
    </OrganizationFetcher.WAF>
  );
};
