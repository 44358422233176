/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */

/** @deprecated */
export const customerRoot = '/customer/admin';

/** @deprecated */
export const CustomerRoutes = {
  Overview: () => `${customerRoot}/overview`,
  Users: () => `${customerRoot}/users`,
  NewUser: () => `${customerRoot}/users/new`,
  Products: () => `${customerRoot}/products`,
  ProductUsers: (productId?: number) => `${customerRoot}/products/${productId ?? ':productId'}/users`,
  User: (userId?: number) => `${customerRoot}/users/${userId ?? ':userId'}`,
  UserProduct: (userId?: number, productId?: number) => `${customerRoot}/users/${userId ?? ':userId'}/products/${productId ?? ':productId'}`,
  UserLicenseOffline: (userId?: number, productId?: number, id?: number) => `${customerRoot}/users/${userId ?? ':userId'}/products/${productId ?? ':productId'}/licenses/${id ?? ':id'}/offline`,
  UserLicenseRelease: (userId?: number, productId?: number, id?: number) => `${customerRoot}/users/${userId ?? ':userId'}/products/${productId ?? ':productId'}/licenses/${id ?? ':id'}/release`,
  UserLicenseUnassign: (userId?: number, productId?: number, id?: number) => `${customerRoot}/users/${userId ?? ':userId'}/products/${productId ?? ':productId'}/licenses/${id ?? ':id'}/unassign`,
  UserDelete: (userId?: number) => `${customerRoot}/users/${userId ?? ':userId'}/delete`,
  Invoices: () => `${customerRoot}/invoices`,
  Documentation: () => `${customerRoot}/docs`,
  DocumentationProduct: (product?: number) => `${customerRoot}/docs/${product ?? ':product'}`,
  Support: () => `${customerRoot}/support`,
  Account: () => `${customerRoot}/account`,
  LivePreview: () => `${customerRoot}/live/preview`,
  Downloads: () => `${customerRoot}/downloads`,
  DownloadsProduct: (product?: number) => `${customerRoot}/downloads/${product ?? ':product'}`,
  DownloadsProductLens: (product?: number) => `${customerRoot}/downloads/${product ?? ':product'}/lens`,
  DownloadsProductVersion: (product?: number, version?: string) => `${customerRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}`,
  DownloadsProductExamples: (product?: number, version?: string) => `${customerRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/examples`,
  DownloadsProductContent: (product?: number, version?: string) => `${customerRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/content`,
  DownloadsProductTools: (product?: number, version?: string) => `${customerRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/tools`,
  DownloadsProductInstaller: (product?: number, version?: string, installer?: string) => `${customerRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/${installer ?? ':installer'}`,
};
