import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { GuestRoutes } from '../../routers/GuestRouter';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';

const EmailsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const AuthAccount: FC = () => {
  const { identities, setIdentity } = useContext(AuthContext);
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const uniqueEmails = useMemo(() => identities.map((account) => account.email), [identities]);

  useEffect(() => {
    if (uniqueEmails.length === 0) history.replace(GuestRoutes.SignIn());
  }, [uniqueEmails]);

  const onUseAnotherAccount = useCallback(() => {
    setIdentity(null);
    history.push(`${GuestRoutes.SignIn()}${search}`);
  }, [search]);

  return (
    <AuthDialog
      title={t('auth.account.encouragement')}
      actions={
        <Button onClick={onUseAnotherAccount} variant="text" color="primary">
          {t('auth.account.useAnother')}
        </Button>
      }
    >
      <EmailsContainer>
        {uniqueEmails.map((email) => {
          const urlSearch = new URLSearchParams(search);
          urlSearch.set('email', email);
          return (
            <Button
              key={email}
              onClick={() => {
                const identity = identities.find((id) => id.email === email);
                if (identity) {
                  setIdentity(identity);
                  history.push(AuthenticatedRoutes.ChooseOrganization());
                }
              }}
              variant="contained"
              color="primary"
            >
              {email}
            </Button>
          );
        })}
      </EmailsContainer>
    </AuthDialog>
  );
};
