import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { IProductPackageFormPayload, ProductPackageForm } from './ProductPackageForm';
import { useForm } from 'react-hook-form';
import { apiProductPackage } from '../../resources/api/apiProductPackage';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { IProductPlanPackage } from '../../models/ProductPlanPackage';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDialog } from '../../hooks/useDialog';

interface IProps {
  pack?: IProductPlanPackage;
  closeDialog: CloseDialogFunc;
  packagesType: 'packages' | 'addons';
}

const { useEditPackage, useDeletePackage } = apiProductPackage;

export const EditProductPackageDialog: FC<IProps> = ({ pack, closeDialog, packagesType }) => {
  const form = useForm();
  const { onRefresh } = useContext(ProductPackagesFetcher.Context);
  const { execute: editPackage } = useEditPackage(pack?.id);
  const { execute: deletePackage, Guard: GuardDeletePackage } = useDeletePackage(pack?.id);
  const handleError = useApiErrorHandler();

  const { isOpen: isDeleting, openUrl: deleteUrl, close: abortDelete } = useDialog('delete', 'subdialog');

  const { t } = useTranslation();

  const onDelete = useCallback(async () => {
    if (!pack) return;
    try {
      await deletePackage();
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [deletePackage]);

  const onSubmit = useCallback(
    async (payload: IProductPackageFormPayload) => {
      if (!pack) return;
      try {
        const updatedPack = await editPackage({
          name: payload.name,
          tools: {
            data: payload.tools,
            bundledWith: payload.bundledWith,
          },
        });
        await onRefresh();
        closeDialog(updatedPack.id);
      } catch (e) {
        handleError(e);
      }
    },
    [editPackage]
  );

  useEffect(() => {
    form.reset({
      name: pack?.name ?? '',
      bundledWith: pack?.tools.bundledWith ?? [],
      tools:
        pack?.tools.data
          .filter((toolData) => toolData.tool)
          .map((toolData) => ({
            amount: toolData.amount,
            toolId: toolData.tool?.id ?? 0,
          })) ?? [],
    });
  }, [pack?.id]);

  return (
    <Dialog open={!!pack} onClose={closeDialog} maxWidth="md">
      <DialogTitle>
        {packagesType === 'addons'
          ? t('adminProducts.addons.editDialog.title', { id: pack?.id })
          : t('adminProducts.packages.editDialog.title', { id: pack?.id })}
      </DialogTitle>
      <DialogContent>
        <ProductPackageForm form={form} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        {pack ? (
          <GuardDeletePackage>
            <DangerousButton component={Link} to={deleteUrl}>
              {t('common.button.delete')}
            </DangerousButton>
          </GuardDeletePackage>
        ) : null}
        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {packagesType === 'addons'
            ? t('adminProducts.addons.editDialog.confirm')
            : t('adminProducts.packages.editDialog.confirm')}
        </Button>
      </DialogActions>

      {pack ? (
        <ConfirmationDialog
          open={isDeleting}
          title={
            packagesType === 'addons'
              ? t('adminProducts.addons.deleteDialog.title')
              : t('adminProducts.packages.deleteDialog.title')
          }
          confirm={onDelete}
          abort={abortDelete}
          message={
            packagesType === 'addons'
              ? t('adminProducts.addons.deleteDialog.message', { name: pack.name })
              : t('adminProducts.packages.deleteDialog.message', { name: pack.name })
          }
          dangerous
        />
      ) : null}
    </Dialog>
  );
};
