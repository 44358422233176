import { getUseResource } from '../resource';
import { organizationResources } from '../builders/organizationResources';
import { IResponse } from '../models/IResponse';
import { IPage } from '../models/IPage';
import { IBasicUserInfo } from '../models/IUserInfo';
import { IServerSidePaginatedFetcherParams } from '../../contexts/Fetcher';
import { IBasicInvitationDetails, IInvitationDetails } from '../models/IInvitationDetails';
import { IBasicOrganization, IOrganization } from '../models/IOrganization';
import { paginatedResourceQuery } from '../paginatedResourceQuery';

export interface IOrganizationPayload {
  name: string;
  address: string;
  phone_number: string;
  notes?: string; // @todo isn't it optional?
  billing_address: string;
  pixotope_representative?: string;
  country?: string;
  domain?: string;
}

export const apiOrganization = {
  useListOrganizationUsers: getUseResource(
    organizationResources.listOrganizationUsers,
    (body): IResponse<IPage<IBasicUserInfo>> => body,
    (params, pagination: IServerSidePaginatedFetcherParams) => pagination
  ),
  useInviteUsers: getUseResource(
    organizationResources.inviteUsers,
    (body): IResponse<null> => body,
    (params, emails: string[], role_id: string) => ({
      emails,
      role_id,
    })
  ),
  useAcceptInvitation: getUseResource(
    organizationResources.acceptInvitation,
    // there is a response, but nothing interesting in there for us
    (body): IResponse<void> => body
  ),
  useGetInvitationDetails: getUseResource(
    organizationResources.getInvitationDetails,
    (body): IResponse<IInvitationDetails> => body
  ),
  useAcceptInvitationAndSignUp: getUseResource(
    organizationResources.acceptInvitationAndSignUp,
    // there is a response, but nothing interesting in there for us
    (body): IResponse<void> => body,
    (
      params,
      payload: { first_name: string; last_name: string; email: string; password: string; phone_number?: string }
    ) => payload
  ),
  useRejectInvitationAuthenticated: getUseResource(
    organizationResources.rejectInvitationAuthenticated,
    (body): IResponse<null> => body
  ),
  useGetOrganizations: getUseResource(
    organizationResources.getOrganizations,
    (body): IResponse<IPage<IOrganization>> => body,
    paginatedResourceQuery
  ),
  useCreateOrganization: getUseResource(
    organizationResources.createOrganization,
    (body): IResponse<IBasicOrganization> => body,
    (params, payload: IOrganizationPayload) => payload
  ),
  useGetOrganizationByName: getUseResource(
    organizationResources.getOrganizationByName,
    (body): IResponse<IOrganization> => body
  ),
  useDeleteOrganizationByName: getUseResource(
    organizationResources.deleteOrganizationByName,
    (body): IResponse<void> => body
  ),
  useUpdateOrganizationByName: getUseResource(
    organizationResources.updateOrganizationByName,
    (body): IResponse<IOrganization> => body,
    (
      params,
      payload: Partial<{
        name: string;
        address: string;
        phone_number: string;
        notes: string | null;
        billing_address: string;
      }>
    ) => payload
  ),
  useAssignUserToOrganization: getUseResource(
    organizationResources.assignUserToOrganization,
    (body): IResponse<null> => body
  ),
  useGetInvitations: getUseResource(
    organizationResources.getInvitations,
    (body): IResponse<IPage<IBasicInvitationDetails>> => body,
    (params, pagination: IServerSidePaginatedFetcherParams) => pagination
  ),
  useRemoveUser: getUseResource(organizationResources.removeUser, (body): IResponse<null> => body),
  /** @todo verify response type */
  useGetUser: getUseResource(organizationResources.getUser, (body): IResponse<IBasicUserInfo> => body),
};
