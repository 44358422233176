import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const productPlanResources = {
  getPlans: createResourceBuilder('/admin/products/plans', EResourceMethod.Get),
  createPlan: createResourceBuilder('/admin/products/plans', EResourceMethod.Post),
  getPlan: createResourceBuilder('/admin/products/plans/:plan_id', EResourceMethod.Get, (plan_id: number) => ({
    plan_id,
  })),
  updatePlan: createResourceBuilder('/admin/products/plans/:plan_id', EResourceMethod.Put, (plan_id: number) => ({
    plan_id,
  })),
  deletePlan: createResourceBuilder('/admin/products/plans/:plan_id', EResourceMethod.Delete, (plan_id: number) => ({
    plan_id,
  })),
};
