import { useCallback, useContext, useEffect, useRef } from 'react';
import { useQueryParam } from './useQueryParam';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingContext } from '../contexts/LoadingContext';
import { useSupportApi } from './useSupportApi';
import { useApiErrorHandler } from './useApiErrorHandler';
import { usePostRedirect } from './usePostRedirect';
import { authenticatedRoot } from '../routers/AuthenticatedRoutes';

export const useRedirectAfterAuth = () => {
  const { search } = useLocation();
  const queryEmail = useQueryParam('email');
  const { identity, auth2faCache } = useContext(AuthContext);
  const history = useHistory();
  const { startLoading, endLoading } = useContext(LoadingContext);
  const { zenDeskSSO } = useSupportApi();
  const handleApiError = useApiErrorHandler();
  const postRedirect = usePostRedirect();

  const emailRef = useRef(queryEmail || auth2faCache?.email);
  emailRef.current = queryEmail || auth2faCache?.email || emailRef.current;

  const performZendeskRedirect = useCallback(
    async (url: string, brand_id?: string, timestamp?: string) => {
      startLoading();
      try {
        const { endpoint, body } = await zenDeskSSO([], {
          url,
          brand_id,
          timestamp,
        });
        postRedirect(endpoint, body);
        return;
      } catch (e) {
        handleApiError(e);
      }
      endLoading();
    },
    [zenDeskSSO]
  );

  useEffect(() => {
    if (identity?.email && identity.email === emailRef.current) {
      const urlSearch = new URLSearchParams(search);

      if (urlSearch.has('redirect')) {
        console.log('LETS REDIRECT THIS FELLA');

        const url = urlSearch.get('redirect')!;

        console.log({
          identity,
          'emailRef.current': emailRef.current,
          url,
        });

        history.push(url);
        return;
      }

      if (urlSearch.has('return_to')) {
        performZendeskRedirect(
          urlSearch.get('return_to')!,
          urlSearch.get('brand_id') ?? void 0,
          urlSearch.get('timestamp') ?? void 0
        ).then();
        return;
      }

      console.log('redirect to authenticated root');
      history.replace(authenticatedRoot);
    }
  }, [identity, search, performZendeskRedirect]);
};
