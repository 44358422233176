/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */

/** @deprecated */
export const userRoot = '/customer/user';

/** @deprecated */
export const UserRoutes = {
  Overview: () => `${userRoot}/overview`,
  OverviewProduct: (product?: string) => `${userRoot}/overview/${product ?? ':product'}`,
  Licenses: () => `${userRoot}/licenses`,
  RequestOffline: () => `${userRoot}/licenses/offline`,
  LicenseOffline: (userPackageId?: string | number) => `${userRoot}/licenses/${userPackageId ?? ':userPackageId'}/offline`,
  LicenseRelease: (userPackageId?: string | number) => `${userRoot}/licenses/${userPackageId ?? ':userPackageId'}/release`,
  DownloadLegacy: () => `${userRoot}/download`,
  Downloads: () => `${userRoot}/downloads`,
  DownloadsProduct: (product?: number) => `${userRoot}/downloads/${product ?? ':product'}`,
  DownloadsProductLens: (product?: number) => `${userRoot}/downloads/${product ?? ':product'}/lens`,
  DownloadsProductVersion: (product?: number, version?: string) => `${userRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}`,
  DownloadsProductExamples: (product?: number, version?: string) => `${userRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/examples`,
  DownloadsProductContent: (product?: number, version?: string) => `${userRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/content`,
  DownloadsProductTools: (product?: number, version?: string) => `${userRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/tools`,
  DownloadsProductInstaller: (product?: number, version?: string, installer?: string) => `${userRoot}/downloads/${product ?? ':product'}/${version ?? ':version'}/${installer ?? ':installer'}`,
  Documentation: () => `${userRoot}/docs`,
  DocumentationProduct: (product?: number) => `${userRoot}/docs/${product ?? ':product'}`,
  LivePreview: () => `${userRoot}/live/preview`,
  Support: () => `${userRoot}/support`,
  Events: () => `${userRoot}/events`,
  Account: () => `${userRoot}/account`,
};
