import { useLocation } from 'react-router-dom';

export const queryUrl = (key: string, value: string | null, currentSearch?: string) => {
  const search = new URLSearchParams(currentSearch);
  if (typeof value === 'string') {
    search.set(key, value);
  } else {
    search.delete(key);
  }
  return `?${search.toString()}`;
};

export const useQueryUrl = (key: string, value: string | null) => {
  const location = useLocation();
  return queryUrl(key, value, location.search);
};
