import Button from '@mui/material/Button';
import React, { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { GuestRoutes } from '../../routers/GuestRouter';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useForm } from 'react-hook-form';
import { useAuthApi } from '../../hooks/useAuthApi';
import { useEnumState } from '../../hooks/useEnumState';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { PasswordInputController } from '../InputControllers/PasswordInputController';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { useTranslation } from 'react-i18next';

export const AuthSignUp: FC = () => {
  const { addNotification } = useContext(NotificationContext);
  const apiErrorHandler = useApiErrorHandler();
  const form = useForm();
  const { t } = useTranslation();

  const { signUp } = useAuthApi();

  const [done, setDone] = useEnumState(false, true);
  const email = useQueryParam('email');
  const token = useQueryParam('token');
  const passwordRef = useRef('');
  passwordRef.current = form.watch('password', '');

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (!email || !token) {
          throw new Error('missing params');
        }
        await signUp({
          email,
          token,
          password: data.password,
          verifyPassword: data.password2,
        });
        addNotification({
          message: t('auth.signUp.success'),
          severity: 'success',
        });
        setDone();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [email, token]
  );

  useEffect(() => {
    form.reset({
      password: '',
      password2: '',
    });
  }, [email, token]);

  return (
    <AuthDialog
      title={done ? t('auth.signUp.done') : t('auth.signUp.encouragement', { email })}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={
        done ? (
          <AuthGoBack to={routeWithQuery(GuestRoutes.SignIn, [], { email: email! })} />
        ) : (
          <Button variant="contained" color="primary" type="submit">
            {t('auth.signUp.submitButton')}
          </Button>
        )
      }
    >
      {!done ? (
        <>
          <PasswordInputController
            control={form.control}
            errors={form.formState.errors}
            label={t('auth.inputs.password.label')}
            name="password"
            rules={{ required: true }}
            InputProps={{
              inputProps: {
                autoComplete: 'new-password',
                tabIndex: 1,
              },
            }}
          />

          <PasswordInputController
            control={form.control}
            errors={form.formState.errors}
            label={t('auth.inputs.confirmPassword.label')}
            name="password2"
            rules={{
              required: true,
              validate: (value) => {
                const msg = t('auth.inputs.confirmPassword.validation.notMatch');
                return value === passwordRef.current || msg;
              },
            }}
            InputProps={{
              inputProps: {
                autoComplete: 'new-password',
                tabIndex: 2,
              },
            }}
          />
        </>
      ) : null}
    </AuthDialog>
  );
};
