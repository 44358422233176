import React, { Key } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material';

interface IRow<CellsType extends any[], IdType> {
  id: IdType;
  cells: CellsType;
}

interface IColumn<CellType extends any> {
  id: string;
  name: string;
  format: (value: CellType) => string | React.ReactNode;
}

type TColumns<CellsType extends any[]> = {
  [Index in keyof CellsType]: IColumn<CellsType[Index]>;
  // [Index in CellsType[number]]: IColumn<CellsType[Index]>;
};

interface IProps<CellsType extends any[], IdType> {
  hasHeader: boolean;
  rows: IRow<CellsType, IdType>[];
  columns: TColumns<CellsType>;
  selectedRow?: IdType;
  setSelectedRow: (row: IdType) => void;
  clearSelectedRow: () => void;
}

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<{ $selected: number }>((props) => ({
  // @todo this color isn't optimal
  backgroundColor: props.$selected ? 'red' : 'transparent',
}));

export const SelectTable = <CellsType extends any[], IdType extends Key>({
  hasHeader,
  rows,
  columns,
  selectedRow,
  setSelectedRow,
  clearSelectedRow,
}: IProps<CellsType, IdType>): React.ReactElement => {
  return (
    <Table>
      {hasHeader ? (
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow
            key={row.id}
            onClick={() => (selectedRow === row.id ? clearSelectedRow() : setSelectedRow(row.id))}
            $selected={row.id === selectedRow ? 1 : 0}
          >
            {columns.map((column, index) => (
              <TableCell key={column.id}>{column.format(row.cells[index])}</TableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
