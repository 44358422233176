import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useAuthApi } from '../../hooks/useAuthApi';
import { useEnumState } from '../../hooks/useEnumState';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { TextInputController } from '../InputControllers/TextInputController';
import { useQueryParam } from '../../hooks/useQueryParam';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { GuestRoutes } from '../../routers/GuestRouter';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AuthRecoverAccount: FC = () => {
  const { addNotification } = useContext(NotificationContext);
  const { search } = useLocation();
  const apiErrorHandler = useApiErrorHandler();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const email = useQueryParam('email');
  const { t } = useTranslation();

  const { recoverAccount } = useAuthApi();

  const [done, setDone] = useEnumState(false, true);

  const onSubmit = useCallback(async (data) => {
    try {
      await recoverAccount(data.email);
      addNotification({
        message: t('auth.recovery.emailSentNotification'),
        severity: 'success',
      });
      setDone();
    } catch (e) {
      apiErrorHandler(e, t('auth.recovery.failure'));
    }
  }, []);

  useEffect(() => {
    reset({ email: email ?? '' });
  }, [email]);

  return (
    <AuthDialog
      title={done ? t('auth.recovery.done') : t('auth.recovery.encouragement')}
      onSubmit={handleSubmit(onSubmit)}
      actions={[
        <AuthGoBack key="goBack" to={`${GuestRoutes.SignIn()}${search}`} />,
        <Button key="recover" variant="contained" color="primary" type="submit">
          {t('auth.recovery.submitButton')}
        </Button>,
      ]}
    >
      {!done ? (
        <TextInputController
          control={control}
          errors={errors}
          label={t('auth.inputs.email.label')}
          name="email"
          inputType="email"
          rules={{ required: true }}
        />
      ) : null}
    </AuthDialog>
  );
};
