import { useApi } from './useApi';
import { useCallback } from 'react';
import { IPaginatedContent } from '../models/PaginatedContent';
import { ICustomerData } from '../models/CustomerData';
import { IAdminDetails } from '../models/AdminDetails';
import { IUserDetails } from '../models/UserDetails';
import { ICustomerInvoicesResponse } from '../models/CustomerInvoicesResponse';
import { IProductDetails } from '../models/ProductDetails';
import { IPlan } from '../models/Plan';
import { IUpdateUserPayload } from './useUsersApi';
import { ICustomerInvoice } from '../models/CustomerInvoice';
import { ApiRequest, useEndpoint, usePaginatedGetEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { CustomersEndpoints } from './api/endpoints/customers';
import { ISupportListResponse } from './useSupportApi';

export interface ICustomerUpdatePayload {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  hubSpotId: string;
  address: string;
  billingAddress: string;
  notes: string;
  MFAEnabled: boolean;
  createdBy: number;
}

interface IAddInvoicePayload {
  billingAddress: string;
  amount: number;
  productId: number;
  planId: number;
  currency: string;
  description: string;
}

export const useCustomersApi = () => {
  const { del, get, post, put } = useApi();

  const getCustomers = usePaginatedGetEndpoint(
    CustomersEndpoints.Customers,
    get as ApiRequest<typeof get, IPaginatedContent<{ customer: ICustomerData; createdBy: IAdminDetails }>>
  );

  const getCustomer = useCallback(
    async (id: number) => {
      const result = await getCustomers([], {
        page: 1,
        per: 1,
        filter: { filterField: 'id', filterOp: 'eq', filterValue: id.toString(10) },
      });
      return result.data[0];
    },
    [getCustomers]
  );

  const getCustomerInvoices = useSimpleEndpoint(
    CustomersEndpoints.CustomerInvoices,
    get as ApiRequest<typeof get, ICustomerInvoicesResponse>
  );

  // const getCustomerProducts = useSimpleEndpoint(
  //   CustomersEndpoints.CustomerProducts,
  //   get as ApiRequest<typeof get, IAdminCustomerProductsResponse>
  // );

  const getCustomerTickets = useSimpleEndpoint(
    CustomersEndpoints.CustomerTickets,
    get as ApiRequest<typeof get, ISupportListResponse>
  );

  const getCustomerUsers = usePaginatedGetEndpoint(
    CustomersEndpoints.CustomerUsers,
    get as ApiRequest<typeof get, IPaginatedContent<IUserDetails>>
  );

  const reInvite = useSimpleEndpoint(CustomersEndpoints.CustomerReInvite, post as ApiRequest<typeof post, void>);

  const createCustomer = useEndpoint(
    (post, payload: ICustomerUpdatePayload, pageSize?: number) =>
      post({ ...payload, role: 'customer' }, { params: { per: pageSize } }),
    CustomersEndpoints.CustomerInvite,
    post as ApiRequest<typeof post, IPaginatedContent<{ customer: ICustomerData; createdBy: IAdminDetails }>>
  );

  const updateCustomer = useEndpoint(
    (put, payload: Partial<ICustomerUpdatePayload>) => put(payload),
    CustomersEndpoints.Customer,
    put as ApiRequest<typeof put, ICustomerData>
  );

  const deleteCustomer = useSimpleEndpoint(CustomersEndpoints.Customer, del);

  const archiveCustomer = useSimpleEndpoint(
    CustomersEndpoints.CustomerArchive,
    put as ApiRequest<typeof put, ICustomerData>
  );

  const unArchiveCustomer = useSimpleEndpoint(
    CustomersEndpoints.CustomerUnArchive,
    put as ApiRequest<typeof put, ICustomerData>
  );

  const getAvailableProducts = useSimpleEndpoint(
    CustomersEndpoints.CustomerAvailableProducts,
    get as ApiRequest<typeof get, { products: Array<Omit<IProductDetails, 'plans'> & { plans: IPlan[] }> }>
  );

  // const addProduct = useEndpoint(
  //   (
  //     post,
  //     productId: number,
  //     planId: number,
  //     amount: number,
  //     sendNotifications: boolean,
  //     properties: ICustomerPackageDefaultProperties
  //   ) => post({ productId, planId, amount, sendNotifications, properties }),
  //   CustomersEndpoints.CustomerProducts,
  //   post as ApiRequest<typeof post, ICustomerProduct[]>
  // );

  const addUser = useEndpoint(
    (post, payload: IUpdateUserPayload) => post(payload),
    CustomersEndpoints.CustomerUsers,
    post as ApiRequest<typeof post, unknown>
  );

  const addInvoice = useEndpoint(
    (post, payload: IAddInvoicePayload) => post(payload),
    CustomersEndpoints.CustomerInvoices,
    post as ApiRequest<typeof post, ICustomerInvoice>
  );

  // const getCustomerProduct = useSimpleEndpoint(
  //   CustomersEndpoints.CustomerProduct,
  //   get as ApiRequest<typeof get, ICustomerProductResponse>
  // );

  // const renewCustomerProduct = useEndpoint(
  //   (post, retroActivate: number, sendNotifications: boolean) => post({ retroActivate, sendNotifications }),
  //   CustomersEndpoints.RenewCustomerProduct,
  //   post as ApiRequest<typeof post, { customer: ICustomerData; customerProduct: ICustomerProduct; plan: IPlan }>
  // );

  // const editCustomerProduct = useEndpoint(
  //   (
  //     put,
  //     notification_reneval_270_days: boolean,
  //     notification_reneval_180_days: boolean,
  //     notification_reneval_90_days: boolean,
  //     notification_reneval_60_days: boolean,
  //     notification_reneval_30_days: boolean,
  //     notification_expiring_30_days: boolean,
  //     ignore_notification_until_reneval: boolean
  //   ) =>
  //     put({
  //       notification_reneval_270_days,
  //       notification_reneval_180_days,
  //       notification_reneval_90_days,
  //       notification_reneval_60_days,
  //       notification_reneval_30_days,
  //       notification_expiring_30_days,
  //       ignore_notification_until_reneval,
  //     }),
  //   CustomersEndpoints.EditCustomerProduct,
  //   put as ApiRequest<typeof put, { customer: ICustomerData; customerProduct: ICustomerProduct; plan: IPlan }>
  // );

  // const suspendCustomerProduct = useSimpleEndpoint(CustomersEndpoints.SuspendCustomerProduct, post);
  // const reinstateCustomerProduct = useSimpleEndpoint(CustomersEndpoints.ReinstateCustomerProduct, post);
  // const releaseCustomerProduct = useSimpleEndpoint(CustomersEndpoints.ReleaseCustomerProduct, post);
  // const revokeCustomerProduct = useSimpleEndpoint(CustomersEndpoints.CustomerProduct, del);
  const reassignZendeskId = useSimpleEndpoint(
    CustomersEndpoints.ReassignZendeskId,
    post as ApiRequest<typeof post, ICustomerData>
  );

  return {
    getCustomers,
    getCustomer,
    getCustomerInvoices,
    // getCustomerProducts,
    getCustomerTickets,
    getCustomerUsers,
    reInvite,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    archiveCustomer,
    unArchiveCustomer,
    getAvailableProducts,
    // addProduct,
    addUser,
    addInvoice,
    // getCustomerProduct,
    // renewCustomerProduct,
    // suspendCustomerProduct,
    // reinstateCustomerProduct,
    // releaseCustomerProduct,
    // revokeCustomerProduct,
    reassignZendeskId,
    // editCustomerProduct,
  };
};
