/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */
// @todo be serious with the url
import { ProductsTab } from '../components/AdminProducts/AdminProducts';
import { productResources } from '../resources/builders/productResources';
import { IResourceBuilder } from '../resources/resource';
import { authResources } from '../resources/builders/authResources';
import { organizationResources } from '../resources/builders/organizationResources';
import { productReleaseResources } from '../resources/builders/productReleaseResources';
import { organizationProductResources } from '../resources/builders/organizationProduct';
import { userResources } from '../resources/builders/userResources';

export const authenticatedRoot = '/bazinga';

const encode = (key: string, value?: string) => {
  return value ? encodeURIComponent(value) : `:${key}`;
};

export const AuthenticatedRoutes = {
  ChooseOrganization: () => `${authenticatedRoot}/choose-organization`,
  Welcome: () => `${authenticatedRoot}/welcome`,
  Security: () => `${authenticatedRoot}/security`,

  // Organization
  OrganizationUsers: () => `${authenticatedRoot}/organization/users`,
  OrganizationUser: (user_id?: string) => `${authenticatedRoot}/organization/users/${user_id ?? ':user_id'}`,

  // Global
  AdminProducts: () => `${authenticatedRoot}/products`,
  AdminProductsTab: (tab?: ProductsTab) => `${authenticatedRoot}/products/${tab ?? ':tab'}`,
  AdminProductsTabEditItem: (tab?: ProductsTab, id?: number) => `${authenticatedRoot}/products/${tab ?? ':tab'}/${id ?? ':id'}`,

  Releases: () => `${authenticatedRoot}/releases`,
  ReleasesProduct: (productId?: number) => `${authenticatedRoot}/releases/${productId ?? ':productId'}`,
  ReleasesProductVersion: (productId?: number, version?: string) => `${authenticatedRoot}/releases/${productId ?? ':productId'}/version/${version ?? ':version'}`,
  Organizations: () => `${authenticatedRoot}/organizations`,

  OrganizationDetails: (organization_name?: string) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}`,
  // `${authenticatedRoot}/organizations/${organization_name ?? ':organization_name'}`,
  OrganizationDetailsProducts: (organization_name?: string) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}/products`,
  OrganizationDetailsUsers: (organization_name?: string) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}/users`,
  OrganizationDetailsInvites: (organization_name?: string) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}/invites`,
  OrganizationDetailsTickets: (organization_name?: string) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}/tickets`,
  OrganizationDetailsLive: (organization_name?: string) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}/live`,
  OrganizationProduct: (organization_name?: string, product_id?: number) => `${authenticatedRoot}/organizations/${encode('organization_name', organization_name)}/products/${product_id}`,

  AllUsers: () => `${authenticatedRoot}/users`,
  User: (user_id?: string) => `${authenticatedRoot}/users/${user_id ?? ':user_id'}`,
  UserOrganization: (user_id?: string, organization_name?: string) => `${authenticatedRoot}/users/${user_id ?? ':user_id'}/organization/${encode('organization_name', organization_name)}`,
};

export type RouteResources<Routes extends Record<string, (...args: any) => string>> = {
  [RouteName in keyof Routes]: Array<IResourceBuilder<any, any, any>>;
};

export const AuthenticatedRouteResources: RouteResources<typeof AuthenticatedRoutes> = {
  // debug
  Welcome: [],
  // general
  ChooseOrganization: [],
  Security: [authResources.changePassword],
  // organization
  OrganizationUsers: [organizationResources.listOrganizationUsers],
  OrganizationUser: [organizationResources.getUser],
  // admin
  AdminProducts: [productResources.getProducts],
  AdminProductsTab: [productResources.getProducts],
  AdminProductsTabEditItem: [productResources.updateProduct],
  //
  Releases: [productReleaseResources.getProductReleases],
  ReleasesProduct: [productReleaseResources.getProductReleases],
  ReleasesProductVersion: [productReleaseResources.updateProductReleaseVersion],
  //
  Organizations: [organizationResources.getOrganizations],
  OrganizationDetails: [organizationResources.getOrganizationByName],
  OrganizationDetailsProducts: [organizationProductResources.GetOrganizationProducts],
  OrganizationDetailsUsers: [organizationResources.listOrganizationUsers],
  OrganizationDetailsInvites: [organizationResources.listOrganizationUsers],
  OrganizationDetailsTickets: [
    /** @todo */
  ],
  OrganizationDetailsLive: [
    /** @todo */
  ],
  OrganizationProduct: [organizationProductResources.CreateOrganizationProduct],
  AllUsers: [userResources.getUsers],
  User: [userResources.getUser],
  UserOrganization: [organizationResources.getUser],
};
