import { IProductPlanPackageTool } from '../../models/ProductPlanPackageTool';
import { productToolResources } from '../builders/productToolResources';
import { getUseResource } from '../resource';

export interface IProductToolPayload {
  key: string;
  filter: boolean;
}

export const apiProductTool = {
  useGetProductTools: getUseResource(
    productToolResources.getTools,
    (body): { tools: IProductPlanPackageTool[] } => body
  ),
  useAddTool: getUseResource(
    productToolResources.createTool,
    (body): IProductPlanPackageTool => body,
    (params, payload: IProductToolPayload) => payload
  ),
  useUpdateTool: getUseResource(
    productToolResources.updateTool,
    (body): IProductPlanPackageTool => body,
    (params, payload: IProductToolPayload) => payload
  ),
  useDeleteTool: getUseResource(productToolResources.deleteTool, () => null),
};
