export enum EPolicyCondition {
  RoleCheck = 'role_check',
  IsSelf = 'is_self',
  AlwaysAllow = 'always_allow',
  OrganizationOwner = 'organization_owner',
  OrganizationMember = 'organization_member',
  Authenticated = 'is_authenticated',
}

export enum EPolicyParameterSource {
  Url = 'url',
}

export enum EPolicyOperator {
  Or = 'OR',
  And = 'AND',
}

export interface IPolicy {
  condition: EPolicyCondition;
  function_parameters: null | Record<
    string,
    {
      key: string;
      source: EPolicyParameterSource;
    }
  >;
  operator: EPolicyOperator;
  member_of_roles?: string[];
}
