import { createFetcher } from '../contexts/Fetcher';
import { apiOrganization } from '../resources/api/apiOrganization';
import { useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { useParams } from 'react-router-dom';

const { useGetUser } = apiOrganization;

const pending = () => new Promise<never>(() => null);

export const OrganizationUserFetcher = createFetcher(() => {
  const { user_id } = useParams<{ user_id: string }>();
  const { organization } = useContext(OrganizationContext);

  console.log({
    user_id,
    organization,
  });
  const { execute } = useGetUser(organization?.name, user_id);

  return organization ? execute : pending;
});
