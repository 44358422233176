import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const organizationProductResources = {
  GetOrganizationProducts: createResourceBuilder(
    '/admin/customers/:organization_name/products',
    EResourceMethod.Get,
    (organization_name: string) => ({ organization_name })
  ),
  CreateOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products',
    EResourceMethod.Post,
    (organization_name: string) => ({ organization_name })
  ),
  GetOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id',
    EResourceMethod.Get,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
  RenewOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id/renew',
    EResourceMethod.Post,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
  UpdateOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id',
    EResourceMethod.Put,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
  SuspendOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id/suspend',
    EResourceMethod.Post,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
  ReinstateOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id/reinstate',
    EResourceMethod.Post,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
  ReleaseOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id/release',
    EResourceMethod.Post,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
  RevokeOrganizationProduct: createResourceBuilder(
    '/admin/customers/:organization_name/products/:product_id',
    EResourceMethod.Delete,
    (organization_name: string, product_id: number) => ({ organization_name, product_id })
  ),
};
