import { useCallback } from 'react';
import { useApi } from './useApi';

export enum AuthEndpoints {
  SignOut = '/signout',
  SignUp = '/signup',
  RecoverAccount = '/recover-account',
  UpdatePassword = '/update-password',
}

export interface IResetPasswordPayload {
  email: string;
  password: string;
  verifyPassword: string;
  token: string;
}

/** @deprecated this is outdated. Hooks from `apiAuth` should be used now */
export function useAuthApi() {
  const { post, put } = useApi({ addAccountTypePrefix: false });

  /** @deprecated needs to be refactored to apiAuth */
  const recoverAccount = useCallback(
    async (email: string) => {
      const result = await post(AuthEndpoints.RecoverAccount, { email });
      return result.data;
    },
    [post]
  );

  /** @deprecated needs to be refactored to apiAuth */
  const accountRecoveryUpdatePassword = useCallback(
    async (payload: IResetPasswordPayload) => {
      const result = await put(AuthEndpoints.UpdatePassword, payload);
      return result.data;
    },
    [put]
  );

  /** @deprecated needs to be refactored to apiAuth */
  const signUp = useCallback(
    async (payload: IResetPasswordPayload) => {
      const result = await post<unknown>(AuthEndpoints.SignUp, payload);
      return result.data;
    },
    [post]
  );

  return {
    signUp,
    recoverAccount,
    accountRecoveryUpdatePassword,
  };
}
