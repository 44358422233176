import { IAdminPlan } from '../../models/AdminPlan';
import { IPlanResponse } from '../../models/PlanResponse';
import { productPlanResources } from '../builders/productPlanResources';
import { getUseResource } from '../resource';

interface ICustomerPackageDefaultProperties {
  [key: string]: boolean;
}

interface IProductPlanPayload {
  name: string;
  type: 'perpetual' | 'subscription';
  price: number;
  currency: string;
  duration: number;
  packages: {
    data: Array<{
      amount: number;
      packageId: number;
    }>;
  };
  productCode?: string;
  productId: number;
  watermark: boolean;
  archived: boolean;
  customerPackageDefaultProperties: ICustomerPackageDefaultProperties;
}

export const apiProductPlan = {
  useGetPlans: getUseResource(productPlanResources.getPlans, (body): { plans: IAdminPlan[] } => body),
  useGetPlan: getUseResource(productPlanResources.getPlan, (body): IPlanResponse => body),
  useCreatePlan: getUseResource(
    productPlanResources.createPlan,
    (body): IAdminPlan => body,
    (params, payload: IProductPlanPayload) => payload
  ),
  useUpdatePlan: getUseResource(
    productPlanResources.updatePlan,
    (body): IAdminPlan => body,
    (params, payload: Partial<IProductPlanPayload>) => payload
  ),
  useDeletePlan: getUseResource(productPlanResources.deletePlan, () => null),
};
