import React, { FC, useCallback, useContext, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { TextInputController } from '../InputControllers/TextInputController';
import { SelectController } from '../InputControllers/SelectController';
import { validateEmail } from '../../helpers/validateEmail';
import { apiOrganization } from '../../resources/api/apiOrganization';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { NotificationContext } from '../../contexts/NotificationContext';
import { EOrganizationRole, organizationRoles } from '../../const/roles';
import { useTranslation } from 'react-i18next';
import { IBasicOrganization } from '../../resources/models/IOrganization';

interface IProps {
  open: boolean;
  closeDialog: () => void;
  organization: IBasicOrganization;
  onDone?: () => Promise<any>;
}

interface IFormData {
  emails: string;
  role_id: string;
}

const parseEmails = (emailsString: string) => {
  return emailsString
    .split(',')
    .map((single) => single.trim())
    .filter((single) => single);
};

const { useInviteUsers } = apiOrganization;

const roleLabels: Record<EOrganizationRole, string> = {
  [EOrganizationRole.Admin]: 'roles.organization.admin',
  [EOrganizationRole.Member]: 'roles.organization.member',
};

// @todo add trans
// @todo style this form
// @todo add link
const InviteUserDialogContent: FC<IProps> = ({ open, closeDialog, organization, onDone }) => {
  // const { data: rolesData } = useContext(RolesFetcher.Context);

  // const rolesItems = useMemo<Array<{ value: string; label: string }>>(() => {
  //   if (!rolesData?.payload?.data) {
  //     return [];
  //   }
  //   return rolesData.payload.data.map((role) => ({
  //     value: role.id,
  //     label: role.name,
  //   }));
  // }, [rolesData]);
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormData>();

  const roleItems = useMemo(
    () =>
      organizationRoles.map((role) => ({
        value: role.id,
        label: t(roleLabels[role.role]),
      })),
    [t]
  );

  const { execute } = useInviteUsers(organization?.name);

  const handleApiError = useApiErrorHandler();
  const { addNotification } = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: IFormData) => {
      try {
        await execute(parseEmails(data.emails), data.role_id);
        addNotification({
          message: 'An invitation email has been sent to the user',
          severity: 'success',
        });
        await onDone?.();
        closeDialog();
      } catch (e) {
        handleApiError(e);
      }
    },
    [execute]
  );

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Invite user</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInputController
            control={control}
            errors={errors}
            label={'Email'}
            name={'emails'}
            rules={{
              required: true,
              validate: (value) => {
                const emails = parseEmails(value);

                const invalid = emails.find((email) => !validateEmail(email));

                return invalid ? `${invalid} is not a valid email` : true;
              },
            }}
          />

          <SelectController
            control={control}
            errors={errors}
            label={'Role'}
            name="role_id"
            items={roleItems}
            rules={{ required: true }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Invite user
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const { useListRoles } = apiRole;

// const RolesFetcher = createFetcher(() => {
//   const { execute } = useListRoles();
//   return useCallback(() => {
//     return execute({ page: 1, limit: 100 });
//   }, [execute]);
// });

export const InviteUserModal: FC<IProps> = (props) => {
  return (
    // <RolesFetcher.WAF>
    <InviteUserDialogContent {...props} />
    // </RolesFetcher.WAF>
  );
};
