import { useQueryParam } from './useQueryParam';
import { useQueryUrl } from './useQueryUrl';
import { useCloseDialog } from './useCloseDialog';

export const useDialog = (dialogName: string, dialogParam = 'dialog') => {
  const isOpen = useQueryParam(dialogParam) === dialogName;
  const openUrl = useQueryUrl(dialogParam, dialogName);
  const closeUrl = useQueryUrl(dialogParam, null);
  const close = useCloseDialog(void 0, [dialogParam]);

  return {
    isOpen,
    openUrl,
    close,
    closeUrl,
  };
};
