import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { createFetcher, useAutoFetch } from '../../contexts/Fetcher';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useIntParams } from '../../hooks/useIntParams';
import { TextInputController } from '../InputControllers/TextInputController';
import { SelectController } from '../InputControllers/SelectController';
import { CheckboxController } from '../InputControllers/CheckboxController';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ICreateLiveStackPayload, useAdminLiveApi } from '../../hooks/useAdminLiveApi';
import { CustomerLiveFetcher } from '../../fetchers/CustomerLiveFetcher';
import { NotificationContext } from '../../contexts/NotificationContext';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { LiveRegionContext } from '../../contexts/LiveRegionContext';
import { LiveRegionsFetcher } from '../../fetchers/LiveRegionsFetcher';
import { StackName } from '../../helpers/stackName';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

interface IUrlParams {
  customerId: string;
}

const AllUsersFetcher = createFetcher(() => {
  const { getCustomerUsers } = useCustomersApi();
  const { customerId } = useIntParams<IUrlParams>();
  return useCallback(() => {
    return getCustomerUsers([customerId], { page: 1, per: 1000 });
  }, []);
});

interface IFormValues {
  userId: number;
  stackId: string;
  capacityReservation: boolean;
  allowListCIDR?: string;
}

const VerticallyCenteredDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  paddingTop: theme.spacing(2),
}));

const CustomerNewLiveStackContent: FC<IProps> = ({ open, closeDialog }) => {
  const { onRefresh, data: stacksData } = useContext(CustomerLiveFetcher.Context);
  const { data: usersData } = useContext(AllUsersFetcher.Context);
  useAutoFetch(AllUsersFetcher, open);
  const { handleSubmit, control, formState, watch, setValue, clearErrors } = useForm({
    mode: 'onBlur',
  });
  const handleError = useApiErrorHandler();
  const { customerId } = useIntParams<IUrlParams>();
  const { createLiveStack } = useAdminLiveApi();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();
  const { region } = useContext(LiveRegionContext);
  const { data: regions } = useContext(LiveRegionsFetcher.Context);

  const selectedUser = watch('userId');

  const onSubmit = useCallback(
    async (data: IFormValues) => {
      const payload: ICreateLiveStackPayload = {
        ...data,
        protocol: 'SRT',
        subscriptionPlan: 1,
        cameraId: parseInt(data.stackId),
        allowListCIDR: data.allowListCIDR ?? '0.0.0.0/0',
      };
      try {
        await createLiveStack([customerId, region], payload);
        await onRefresh();
        addNotification({
          severity: 'success',
          message: t('organization.live.addSucceeded'),
        });
        closeDialog();
      } catch (e) {
        handleError(e);
      }
    },
    [createLiveStack, customerId, region]
  );

  const usersOptions = useMemo(() => {
    if (!usersData) return null;
    return usersData.data.map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
  }, [usersData]);

  const usedStackIdsPerUser = useMemo(() => {
    if (!stacksData) return {};
    return stacksData.reduce((out, current) => {
      const stackData = new StackName(current.name);
      if (!stackData.valid) return out;
      return {
        ...out,
        [stackData.userId]: [...(out[stackData.userId] ?? []), parseInt(stackData.stackId)],
      };
    }, {} as { [userId: string]: number[] });
  }, [stacksData]);

  useEffect(() => {
    const usedStackIds = usedStackIdsPerUser[selectedUser] ?? [0];
    const autoIncrement = Math.max(...usedStackIds) + 1;
    setValue('stackId', autoIncrement);
    clearErrors('stackId');
  }, [usedStackIdsPerUser, selectedUser]);

  const regionName = regions?.regions.find((r) => r.name === region)?.location ?? region;

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('organization.live.addDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {t('organization.live.addDialog.region', { name: regionName })}
            </Grid>
            <Grid item xs={6}>
              {usersOptions ? (
                <SelectController
                  defaultValue=""
                  control={control}
                  errors={formState.errors}
                  rules={{ required: true }}
                  label={t('organization.live.formLabel.user')}
                  name="userId"
                  items={usersOptions}
                />
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <TextInputController
                defaultValue="1"
                control={control}
                errors={formState.errors}
                rules={{
                  required: true,

                  validate: (value) => {
                    if (usedStackIdsPerUser[selectedUser]?.includes(parseInt(value))) {
                      return t('organization.live.duplicatedStackId') as string;
                    }
                    return true;
                  },
                }}
                label={t('organization.live.formLabel.stackId')}
                inputType="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                name="stackId"
              />
            </Grid>

            <Grid item xs={6}>
              <VerticallyCenteredDiv>
                <CheckboxController
                  defaultValue={false}
                  control={control}
                  errors={formState.errors}
                  label={t('organization.live.formLabel.capacityReservation')}
                  name="capacityReservation"
                />
              </VerticallyCenteredDiv>
            </Grid>

            <Grid item xs={12}>
              <TextInputController
                defaultValue=""
                control={control}
                errors={formState.errors}
                rules={{
                  required: true,
                  validate: (value) => {
                    const match = value.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)\/(\d+)$/);
                    const invalidMsg = t('organization.live.addDialog.wrongCIDR');
                    if (!match) return invalidMsg;

                    const maxAllowed = [255, 255, 255, 255, 32];

                    if (maxAllowed.some((max, index) => match[index + 1] > max)) {
                      return invalidMsg;
                    }

                    return true;
                  },
                }}
                label={t('organization.live.formLabel.allowListCIDR')}
                inputType="text"
                InputProps={{
                  placeholder: '0.0.0.0/0',
                }}
                name="allowListCIDR"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('organization.live.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CustomerNewLiveStack: FC<IProps> = (props) => {
  return (
    <AllUsersFetcher.WMF>
      <CustomerNewLiveStackContent {...props} />
    </AllUsersFetcher.WMF>
  );
};
