import { IServerSidePaginatedFetcherParams } from '../contexts/Fetcher';

export const paginatedResourceQuery = (params: any, pagination: IServerSidePaginatedFetcherParams) => {
  return {
    page: pagination.page,
    page_size: pagination.limit,
    filter_field: pagination.filter?.filterField === 'text' ? '*' : pagination.filter?.filterField,
    filter_op: pagination.filter?.filterField === 'text' ? 'ilike' : pagination.filter?.filterOp,
    filter_value: pagination.filter?.filterValue,
    sort_by: pagination.sort?.sortBy,
    sort_order: pagination.sort?.sortOrder,
  };
};
