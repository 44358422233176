import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { createFetcher } from '../../contexts/Fetcher';
import { SelectController } from '../InputControllers/SelectController';
import { countries } from '../../helpers/countries';
import { useTranslation } from 'react-i18next';
import { TextInputController } from '../InputControllers/TextInputController';
import { IOrganizationPayload } from '../../resources/api/apiOrganization';
import { apiUser } from '../../resources/api/apiUser';
import { IOrganization } from '../../resources/models/IOrganization';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { adminRoles } from '../../const/roles';

export type TEditOrganizationFormType = IOrganizationPayload & { same_billing_address: boolean };

interface IProps {
  organization?: IOrganization;
  onSubmit: (fields: TEditOrganizationFormType) => void;
  form: UseFormReturn<TEditOrganizationFormType>;
}

const { useGetAdmins } = apiUser;

const AdminsFetcher = createFetcher(() => {
  const { execute: getAdmins } = useGetAdmins();

  return useCallback(async () => {
    const result = await getAdmins(
      {
        page: 1,
        limit: 1000,
        sort: {
          sortBy: 'first_name',
          sortOrder: 'asc',
        },
      },
      adminRoles.map(({ id }) => id)
    );
    return result.payload.data;
  }, [getAdmins]);
});

const countryItems = countries.map((country) => ({
  label: country.name,
  value: country.code,
}));

const EditOrganizationFormContent: FC<IProps> = ({
  organization,
  onSubmit,
  form: {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  },
}) => {
  const { data } = useContext(AdminsFetcher.Context);
  const { t } = useTranslation();

  const address = watch('address');
  const sameBillingAddress = watch('same_billing_address');

  useEffect(() => {
    if (sameBillingAddress) {
      setValue('billing_address', address);
    }
  }, [sameBillingAddress, address]);

  const representativeItems = useMemo(() => {
    if (!data) return [];
    return data.map((admin) => ({
      value: admin.id,
      label: `${admin.first_name} ${admin.last_name}`,
    }));
  }, [data]);

  const onChangeSameBillingAddress = useCallback((event: ChangeEvent, value: boolean) => {
    setValue('same_billing_address', value);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={organization?.name ?? ''}
            label={t('common.formLabel.organization')}
            rules={{ required: true }}
            name="name"
          />
        </Grid>
        {/*<Grid item xs={12} lg={6}>*/}
        {/*  <TextInputController*/}
        {/*    control={control}*/}
        {/*    errors={errors}*/}
        {/*    defaultValue={organization?.domain ?? ''}*/}
        {/*    label={t('common.formLabel.domain')}*/}
        {/*    rules={{ required: true }}*/}
        {/*    name="domain"*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={organization?.address ?? ''}
            label={t('common.formLabel.address')}
            rules={{ required: true }}
            name="address"
          />
        </Grid>
        <Grid item xs={5} lg={4}>
          <FormControl fullWidth={true}>
            <InputLabel focused={false} shrink={true} variant="filled">
              Same billing address
            </InputLabel>
            <Box pt={2}>
              <Switch checked={sameBillingAddress} onChange={onChangeSameBillingAddress} />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={7} lg={8}>
          <TextInputController
            control={control}
            errors={errors}
            // disabled={sameBillingAddress}
            InputProps={{
              readOnly: sameBillingAddress,
              disabled: sameBillingAddress,
            }}
            defaultValue={organization?.billing_address ?? ''}
            label={t('editOrganizationDialog.formLabel.billingAddress')}
            rules={{ required: !sameBillingAddress }}
            name={'billing_address'}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectController
            control={control}
            errors={errors}
            items={countryItems}
            label={t('editOrganizationDialog.formLabel.country')}
            name={'country'}
            defaultValue={organization?.country ?? ''}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectController
            control={control}
            errors={errors}
            items={representativeItems}
            label={t('editOrganizationDialog.formLabel.representative')}
            name={'pixotope_representative'}
            defaultValue={organization?.pixotope_representative ?? ''}
            // rules={{ required: false }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={organization?.notes ?? ''}
            label={t('editOrganizationDialog.formLabel.notes')}
            name="notes"
            InputProps={{
              multiline: true,
              rows: 4,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export const EditOrganizationForm: FC<IProps> = (props) => {
  return (
    <AdminsFetcher.WAF>
      <EditOrganizationFormContent {...props} />
    </AdminsFetcher.WAF>
  );
};
