import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, useHistory, useParams } from 'react-router-dom';
import { combineComponents } from '../../contexts/combineComponents';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { ProductTools } from './ProductTools';
import { ProductPackages } from './ProductPackages';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { ProductPlans } from './ProductPlans';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { ProductsList } from './ProductsList';
import { useTranslation } from 'react-i18next';
import styled from '@mui/styles/styled';
import { useQueryUrl } from '../../hooks/useQueryUrl';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { apiProduct } from '../../resources/api/apiProduct';
import { apiProductPlan } from '../../resources/api/apiProductPlan';
import { apiProductPackage } from '../../resources/api/apiProductPackage';
import { apiProductTool } from '../../resources/api/apiProductTool';
import { useResourceChecker } from '../../resources/resource';

export type ProductsTab = 'products' | 'plans' | 'packages' | 'addons' | 'tools';

interface IUrlParams {
  tab?: ProductsTab;
}

const Providers = combineComponents(
  ProductToolsFetcher.WMF,
  ProductPackagesFetcher.WMF,
  ProductPlansFetcher.WMF,
  ProductsFetcher.WMF
);

const TabsContainer = styled('div')({
  display: 'flex',
});
const StyledTabs = styled(Tabs)({
  flexGrow: 1,
});
const ButtonContainer = styled('div')({
  flex: 1,
  textAlign: 'right',
});

const { useGetProducts, useCreateProduct } = apiProduct;
const { useGetPlans, useCreatePlan } = apiProductPlan;
const { useGetPackages, useCreatePackage } = apiProductPackage;
const { useGetProductTools, useAddTool } = apiProductTool;

export const AdminProducts: FC = () => {
  const { tab } = useParams<IUrlParams>();
  const history = useHistory();
  const { t } = useTranslation();

  const resourceChecker = useResourceChecker();
  const { resource: resourceProducts } = useGetProducts();
  const { resource: resourcePlans } = useGetPlans();
  const { resource: resourcePackages } = useGetPackages();
  const { resource: resourceTools } = useGetProductTools();

  const { resource: resourceCreateProducts } = useCreateProduct();
  const { resource: resourceCreatePlans } = useCreatePlan();
  const { resource: resourceCreatePackages } = useCreatePackage();
  const { resource: resourceCreateTools } = useAddTool();

  const isProductsAllowed = resourceChecker(resourceProducts);
  const isPlansAllowed = resourceChecker(resourcePlans);
  const isPackagesAllowed = resourceChecker(resourcePackages);
  const isToolsAllowed = resourceChecker(resourceTools);

  const isCreateAllowed =
    (tab === 'tools' && resourceChecker(resourceCreateTools)) ||
    (tab === 'plans' && resourceChecker(resourceCreatePlans)) ||
    (tab === 'packages' && resourceChecker(resourceCreatePackages)) ||
    (tab === 'addons' && resourceChecker(resourceCreatePackages)) ||
    (tab === 'products' && resourceChecker(resourceCreateProducts));

  const onChange = useCallback((e: ChangeEvent<unknown>, tab: ProductsTab) => {
    history.push(AuthenticatedRoutes.AdminProductsTab(tab));
  }, []);

  useEffect(() => {
    if (typeof tab === 'undefined') history.replace(AuthenticatedRoutes.AdminProductsTab('products'));
  }, [typeof tab]);

  const newItemUrl = useQueryUrl('dialog', 'new');

  return (
    <div>
      <TabsContainer>
        <StyledTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {isProductsAllowed ? <Tab label={t('adminProducts.products.tabTitle')} value={'products'} /> : null}
          {isPlansAllowed ? <Tab label={t('adminProducts.plans.tabTitle')} value={'plans'} /> : null}
          {isPackagesAllowed ? <Tab label={t('adminProducts.packages.tabTitle')} value={'packages'} /> : null}
          {isPackagesAllowed ? <Tab label={t('adminProducts.addons.tabTitle')} value={'addons'} /> : null}
          {isToolsAllowed ? <Tab label={t('adminProducts.tools.tabTitle')} value={'tools'} /> : null}
        </StyledTabs>
        <ButtonContainer>
          {isCreateAllowed ? (
            <Button variant="contained" color="primary" component={Link} to={newItemUrl}>
              {tab === 'tools' ? t('adminProducts.tools.addButton') : null}
              {tab === 'packages' ? t('adminProducts.packages.addButton') : null}
              {tab === 'addons' ? t('adminProducts.addons.addButton') : null}
              {tab === 'plans' ? t('adminProducts.plans.addButton') : null}
              {tab === 'products' ? t('adminProducts.products.addButton') : null}
            </Button>
          ) : null}
        </ButtonContainer>
      </TabsContainer>

      <Providers>
        {tab === 'tools' ? <ProductTools /> : null}
        {tab === 'packages' ? <ProductPackages packagesType={tab} /> : null}
        {tab === 'addons' ? <ProductPackages packagesType={tab} /> : null}
        {tab === 'plans' ? <ProductPlans /> : null}
        {tab === 'products' ? <ProductsList /> : null}
      </Providers>
    </div>
  );
};
