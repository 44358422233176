import { EResourceMethod } from '../models/IPermission';
import { createResourceBuilder } from '../resource';

export const permissionResources = {
  getPermissions: createResourceBuilder('/identities/permission', EResourceMethod.Get),
  getUserPermissions: createResourceBuilder(
    '/identities/permission/user/:user_id',
    EResourceMethod.Get,
    (user_id: string) => ({ user_id })
  ),
} as const;
