import { createServerSidePaginatedFetcher } from '../contexts/Fetcher';
import { apiOrganization } from '../resources/api/apiOrganization';
import { useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';

const { useListOrganizationUsers } = apiOrganization;

export const OrganizationUsersFetcher = createServerSidePaginatedFetcher(() => {
  const { organization } = useContext(OrganizationContext);
  const { execute } = useListOrganizationUsers(organization?.name);

  return execute;
});
