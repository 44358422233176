import React, { FC, useCallback, useContext, useMemo } from 'react';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { UrlQueryDataGrid } from '../components/UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../valueFormatters/DateValueFormatter';
import { DateFormat } from '../helpers/dateFormat';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { getStringEqualsOperator } from '../filterOperators/stringEqualsOperator';
import { getStringDiffersOperator } from '../filterOperators/stringDiffersOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../filterOperators/dateFilterOperators';
import { OrganizationFetcher } from '../components/Customer/CustomerFetchers';
import { OrganizationsFetcher } from '../fetchers/OrganizationsFetcher';
import { EasyFilter } from '../components/EasyFilter/EasyFilter';
import { IQuickFiltersProps } from '../components/EasyFilter/QuickFilters';
import { CellContentTooltip } from '../components/CellContentTooltip/CellContentTooltip';
import { createStatusChipComponent } from '../components/StatusChip/StatusChip';
import { useDefaultSorting } from '../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { getStringContainsOperator } from '../filterOperators/stringContainsOperator';
import { useDialog } from '../hooks/useDialog';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { useLazyUsers } from '../hooks/useLazyUsers';
import { Link } from 'react-router-dom';
import { useIsGuardedRouteAllowed } from '../resources/GuardedRouteLink';
import { AddOrganizationDialog } from '../components/EditOrganizationDialog/AddOrganizationDialog';

const Status = createStatusChipComponent({
  unverified: ['common.accountStatus.unverified', 'orange'],
  archived: ['common.accountStatus.archived', 'default'],
  mfaEnabled: ['common.accountStatus.mfaEnabled', 'lime'],
});

const OrganizationsContent: FC = () => {
  const { data } = useContext(OrganizationsFetcher.Context);

  const userIds = useMemo(() => {
    const rows = data?.payload.data ?? [];
    return rows.reduce((out, organization) => {
      if (organization.pixotope_representative && !out.includes(organization.pixotope_representative)) {
        out.push(organization.pixotope_representative);
      }
      if (organization.owner_id && !out.includes(organization.owner_id)) {
        out.push(organization.owner_id);
      }
      return out;
    }, [] as string[]);
  }, [data]);

  const { getUserData } = useLazyUsers(userIds);

  const { onDataUpdate } = useContext(OrganizationFetcher.Context);

  const { t } = useTranslation();

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.unverified'),
          {
            filterField: 'verified',
            filterOp: 'equals',
            filterVal: 'false',
          },
        ],
        [
          t('common.quickFilter.archived'),
          {
            filterField: 'archived',
            filterOp: 'equals',
            filterVal: 'true',
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: t('common.tableHeader.organization'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'owner',
        headerName: t('common.tableHeader.owner'),
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const { archived, verified, MFAEnabled } = params.row;

          if (archived || !verified || MFAEnabled) {
            const parts = [];
            archived && parts.push(t('common.accountStatus.archived'));
            !verified && parts.push(t('common.accountStatus.unverified'));
            MFAEnabled && parts.push(t('common.accountStatus.mfaEnabled'));
            parts.push(params.value);
            return (
              <Tooltip title={parts.join(' | ')}>
                <span>
                  {params.value}{' '}
                  {verified === false ? (
                    <Status {...params} value={'unverified'} />
                  ) : MFAEnabled ? (
                    <Status {...params} value={'mfaEnabled'} />
                  ) : null}
                </span>
              </Tooltip>
            );
          } else {
            return <CellContentTooltip>{params.value}</CellContentTooltip>;
          }
        },
      },
      {
        field: 'pixotope_representative',
        headerName: t('organizations.table.representativeHeader'),
        width: 180,
        filterable: false,
        sortable: false,
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'created_at',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'archived',
        headerName: t('common.tableHeader.archived'),
        hide: true,
        hideable: false,
        sortable: false,
      },
      {
        field: 'verified',
        headerName: t('common.tableHeader.verified'),
        hide: true,
        hideable: false,
        sortable: false,
      },
      {
        field: 'MFAEnabled',
        headerName: t('common.tableHeader.mfaEnabled'),
        hide: true,
        hideable: false,
        sortable: false,
      },
    ],
    []
  );

  const onRowClick = useCallback((params: GridRowParams) => {
    if (typeof params.id === 'number') {
      onDataUpdate(params.row.original);
    }
  }, []);

  const getRowLink = useCallback((params: GridRowParams) => {
    return AuthenticatedRoutes.OrganizationDetails(params.row.name);
  }, []);

  const isGetRowLinkAllowed = useIsGuardedRouteAllowed('OrganizationDetails', ['any']);

  const rows = useMemo(() => {
    if (!data) return null;
    return data.payload.data.map((row) => {
      const owner = getUserData(row.owner_id);
      const representative = getUserData(row.pixotope_representative);

      return {
        id: row.id,
        name: row.name,
        owner: owner ? `${owner.first_name} ${owner.last_name}` : null,
        email: owner?.email ?? null,
        pixotope_representative: representative ? `${representative.first_name} ${representative.last_name}` : null,
        created_at: row.created_at,
        verified: true, // @todo check this field and filtering
        archived: row.archived, // @todo check this field and filtering
        original: row,
        MFAEnabled: false, // @todo check with Jakob if needed and propagate from owner if so
      };
    });
  }, [data, getUserData]);

  if (!rows || !data) return null;

  return (
    <div>
      <EasyFilter quickFilters={quickFilters} />

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        filterMode={'server'}
        sortingMode={'server'}
        onRowClick={onRowClick}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
        rowCount={data.payload.total_items}
        getRowClassName={(params) => (params.row.archived ? 'Mui-disabled' : '')}
      />
    </div>
  );
};

export const OrganizationsPage: FC = () => {
  const { t } = useTranslation();
  const preventRender = useDefaultSorting('created_at', 'desc');

  const { isOpen: isNewOpen, openUrl: newUrl, close: abortNew } = useDialog('new');

  if (preventRender) return null;

  return (
    <OrganizationsFetcher.WAF>
      <PageHeader title={t('organizations.title')}>
        <Button component={Link} to={newUrl} variant="contained" color="primary">
          {t('organizations.addButton')}
        </Button>
      </PageHeader>
      <OrganizationsContent />
      <AddOrganizationDialog open={isNewOpen} closeDialog={abortNew} />
    </OrganizationsFetcher.WAF>
  );
};
