import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const productToolResources = {
  getTools: createResourceBuilder('/admin/products/plans/packages/tools', EResourceMethod.Get),
  createTool: createResourceBuilder('/admin/products/plans/packages/tools', EResourceMethod.Post),
  updateTool: createResourceBuilder(
    '/admin/products/plans/packages/tools/:tool_id',
    EResourceMethod.Put,
    (tool_id: number) => ({ tool_id })
  ),
  deleteTool: createResourceBuilder(
    '/admin/products/plans/packages/tools/:tool_id',
    EResourceMethod.Delete,
    (tool_id: number) => ({ tool_id })
  ),
};
