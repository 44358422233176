import React, { FC, useCallback, useContext, useMemo } from 'react';
import { UserFetcher } from '../fetchers/UserFetcher';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { GuardedRouteLink, useIsGuardedRouteAllowed } from '../resources/GuardedRouteLink';
import Grid from '@mui/material/Grid';
import { AccountSingleData } from '../components/AccountData/AccountSingleData';
import Link from '@mui/material/Link';
import { TypographyBody1 } from '../components/Typography/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { apiUser } from '../resources/api/apiUser';
import Button from '@mui/material/Button';
import { useDialog } from '../hooks/useDialog';
import { Link as RouterLink } from 'react-router-dom';
import { EditUserDialog } from '../components/EditUserDialog/EditUserDialog';
import { useApiErrorHandler } from '../hooks/useApiErrorHandler';

const { useUpdateUser, useUpdateDefaultOrganization } = apiUser;

const OrganizationCard: FC<{
  organization: { id: string; name: string };
  is_default: boolean;
}> = ({ organization, is_default }) => {
  const { data: user, onDataUpdate } = useContext(UserFetcher.Context);
  const { execute: setAsDefault, Guard: GuardSetDefault } = useUpdateDefaultOrganization(
    user?.payload.id,
    organization.id
  );
  const handleError = useApiErrorHandler();

  const onSetAsDefault = useCallback(async () => {
    if (!user) return;
    try {
      await setAsDefault();
      onDataUpdate({
        ...user,
        payload: {
          ...user.payload,
          default_organization: organization.id,
        },
      });
    } catch (e) {
      handleError(e);
    }
  }, [user, setAsDefault]);

  if (!user) return null;

  return (
    <Card>
      <Box p={2}>
        <Grid container>
          <Grid item xs={8}>
            <TypographyBody1>
              Organization:{' '}
              <Link
                component={GuardedRouteLink}
                route={'OrganizationDetails'}
                params={[organization.name]}
                showFallback
              >
                {organization.name}
              </Link>
            </TypographyBody1>

            <TypographyBody1>
              <Link
                component={GuardedRouteLink}
                route={'UserOrganization'}
                params={[user.payload.id, organization.name]}
              >
                User data in {organization.name} organization
              </Link>
            </TypographyBody1>
          </Grid>
          <Grid item xs={4} textAlign={'right'}>
            {is_default ? (
              'default'
            ) : (
              <GuardSetDefault>
                <Button variant={'outlined'} onClick={onSetAsDefault}>
                  Set as default
                </Button>
              </GuardSetDefault>
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

const UserContent: FC = () => {
  const { t } = useTranslation();
  const { data } = useContext(UserFetcher.Context);

  const isAllUsersAllowed = useIsGuardedRouteAllowed('AllUsers');

  const { Guard: GuardUpdateUser } = useUpdateUser(data?.payload.id);

  const crumbs = useMemo(() => {
    const out = [];
    if (isAllUsersAllowed) {
      out.push({
        title: t('layout.adminNavigation.users'),
        url: AuthenticatedRoutes.AllUsers(),
      });
    }
    return out;
  }, [isAllUsersAllowed]);

  const { isOpen: isEdit, openUrl: editUrl, close: abortEdit } = useDialog('edit');

  // -- no more hooks beyond this point
  if (!data) return null;

  const defaultOrganization = data.payload.organizations.find((org) => org.id === data.payload.default_organization);

  return (
    <div>
      <PageHeader title={`${data.payload.first_name} ${data.payload.last_name}`} crumbs={crumbs}>
        <GuardUpdateUser>
          <Button variant="contained" component={RouterLink} to={editUrl}>
            Edit User
          </Button>

          <EditUserDialog open={isEdit} closeDialog={abortEdit} />
        </GuardUpdateUser>
      </PageHeader>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.name')}>
            {data.payload.first_name} {data.payload.last_name}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.email')}>{data.payload.email}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.phone')}>{data.payload.phone_number}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('user.defaultOrganization')}>
            {defaultOrganization ? (
              <Link
                component={GuardedRouteLink}
                route={'OrganizationDetails'}
                params={[defaultOrganization?.name]}
                showFallback
              >
                {defaultOrganization.name}
              </Link>
            ) : (
              '-'
            )}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.id')}>{data.payload.id}</AccountSingleData>
        </Grid>
      </Grid>

      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TypographyBody1>
            {t('user.organizationsMembership', {
              name: `${data.payload.first_name} ${data.payload.last_name}`,
              count: data.payload.organizations?.length ?? 0,
            })}
          </TypographyBody1>
        </Grid>

        {data.payload.organizations.map((organization) => (
          <Grid item xs={12} lg={6} key={organization.id}>
            <OrganizationCard organization={organization} is_default={organization === defaultOrganization} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const UserPage: FC = () => {
  return (
    <UserFetcher.WAF>
      <UserContent />
    </UserFetcher.WAF>
  );
};
