import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ProductToolForm } from './ProductToolForm';
import { IProductToolPayload, apiProductTool } from '../../resources/api/apiProductTool';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { IProductPlanPackageTool } from '../../models/ProductPlanPackageTool';
import { DangerousButton } from '../CustomButtons/DangerousButton';

import { Link } from 'react-router-dom';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { useTranslation } from 'react-i18next';

import { useDialog } from '../../hooks/useDialog';

interface IProps {
  tool?: IProductPlanPackageTool;
  closeDialog: CloseDialogFunc;
}

const { useUpdateTool, useDeleteTool } = apiProductTool;

export const EditProductToolDialog: FC<IProps> = ({ tool, closeDialog }) => {
  const form = useForm();
  const { isOpen: isDeleting, openUrl: deleteUrl, close: abortDelete } = useDialog('delete', 'subdialog');

  const handleError = useApiErrorHandler();
  const { execute: updateTool } = useUpdateTool(tool?.id);
  const { execute: deleteTool, Guard: GuardDeleteTool } = useDeleteTool(tool?.id);
  const { onRefresh } = useContext(ProductToolsFetcher.Context);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      key: tool?.key ?? '',
      filter: tool?.filter ?? false,
    });
  }, [tool]);

  const onSubmit = useCallback(
    async (payload: IProductToolPayload) => {
      try {
        const updatedTool = await updateTool(payload);
        await onRefresh();
        closeDialog(updatedTool.id);
      } catch (e) {
        handleError(e);
      }
    },
    [updateTool]
  );

  const onDelete = useCallback(async () => {
    if (!tool) return;
    try {
      await deleteTool();
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [deleteTool]);

  return (
    <Dialog open={!!tool} onClose={closeDialog}>
      <DialogTitle>{t('adminProducts.tools.editDialog.title', { id: tool?.id })}</DialogTitle>
      <DialogContent>{tool ? <ProductToolForm form={form} onSubmit={onSubmit} /> : null}</DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        {tool ? (
          <GuardDeleteTool>
            <DangerousButton component={Link} to={deleteUrl}>
              {t('common.button.delete')}
            </DangerousButton>
          </GuardDeleteTool>
        ) : null}

        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.tools.editDialog.confirm')}
        </Button>
      </DialogActions>

      {tool ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('adminProducts.tools.deleteDialog.title')}
          confirm={onDelete}
          abort={abortDelete}
          message={t('adminProducts.tools.deleteDialog.message', { name: tool.key })}
          dangerous
        />
      ) : null}
    </Dialog>
  );
};
