import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { TextInputController } from '../InputControllers/TextInputController';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { useTranslation } from 'react-i18next';
import { PasswordInputController } from '../InputControllers/PasswordInputController';
import Box from '@mui/material/Box';
import { EAuthResponse, useAuth } from '../../hooks/useAuth';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useRouteWithQuery } from '../../hooks/useRouteWithQuery';
import { GuestRoutes } from '../../routers/GuestRouter';
import { useRedirectAfterAuth } from '../../hooks/useRedirectAfterAuth';
import { useQueryParam } from '../../hooks/useQueryParam';
import Alert from '@mui/material/Alert';
import { AuthContext } from '../../contexts/AuthContext';

export const AuthEmail: FC = () => {
  const { authenticate } = useAuth();

  const { t } = useTranslation();

  const { identity, setAuth2faCache } = useContext(AuthContext);

  const forceEmail = useQueryParam('email');

  const flow = useQueryParam('flow');

  const { addNotification } = useContext(NotificationContext);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus,
  } = useForm();

  const secondFactorRedirectUrl = useRouteWithQuery(GuestRoutes.SecondFactor, [], {}, true);

  useRedirectAfterAuth();

  const onSubmit = useCallback(async (data) => {
    setAuth2faCache({
      email: data.email,
      access_token: '', // not used now
    });
    const result = await authenticate(data.email, data.password);
    if (result === EAuthResponse.SecondFactorNeeded) {
      history.replace(secondFactorRedirectUrl);
    } else if (result === EAuthResponse.Unauthorized) {
      addNotification({
        message: t('auth.signIn.errors.unauthorized'),
        severity: 'error',
      });
    } else if (result === EAuthResponse.Unexpected) {
      addNotification({
        message: t('auth.signIn.errors.unexpected'),
        severity: 'error',
      });
    }
  }, []);

  useEffect(() => {
    reset({
      email: forceEmail,
      password: '',
    });
  }, [forceEmail]);

  return (
    <AuthDialog
      title={t('auth.email.encouragement')}
      onSubmit={handleSubmit(onSubmit)}
      actions={[
        <Button key="signIn" variant="contained" color="primary" type="submit">
          {t('auth.email.submitButton')}
        </Button>,
      ]}
    >
      {flow === 'invitation' ? (
        <Alert severity={'info'}>
          {identity
            ? `You are currently logged in with ${identity.email}. To access the new organization, you need to sign in using the invited email address.`
            : `You are currently logged out. To access the new organization, you need to sign in using the invited email address.`}
        </Alert>
      ) : null}

      <TextInputController
        control={control}
        errors={errors}
        label={t('auth.inputs.email.label')}
        name="email"
        inputType="email"
        InputProps={{
          autoComplete: 'email',
          disabled: !!forceEmail,
          ref: (el: HTMLInputElement | null) => {
            if (el && !forceEmail) {
              setFocus('email');
            }
          },
        }}
        rules={{ required: true }}
      />

      <PasswordInputController
        control={control}
        errors={errors}
        label={t('auth.inputs.password.label')}
        name="password"
        rules={{ required: true }}
        InputProps={{
          autoComplete: 'current-password',
          ref: (el: HTMLInputElement | null) => {
            if (el && forceEmail) {
              setFocus('password');
            }
          },
        }}
      />

      {/* @todo this does nothing */}
      <Box textAlign="right">
        <Button component={Link} to={`#`} variant="text" color="secondary" size="small">
          {t('auth.signIn.forgotPassword')}
        </Button>
      </Box>
    </AuthDialog>
  );
};
