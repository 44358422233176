import { createContext } from 'react';
import { IBasicOrganization } from '../resources/models/IOrganization';

export interface IOrganizationContext {
  organization: IBasicOrganization | null;
  organizationId: string | null;
  setOrganization: (organization: IBasicOrganization | null) => void;
}

export const OrganizationContext = createContext<IOrganizationContext>({
  organization: null,
  organizationId: null,
  setOrganization: () => void 0,
});
