import React, { FC, useCallback, useContext, useMemo } from 'react';
import { OrganizationUsersFetcher } from '../fetchers/OrganizationUsersFetcher';
import { UrlQueryDataGrid } from '../components/UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getStringEqualsOperator } from '../filterOperators/stringEqualsOperator';
import { getStringContainsOperator } from '../filterOperators/stringContainsOperator';
import { getStringDiffersOperator } from '../filterOperators/stringDiffersOperator';
import { CellContentTooltip } from '../components/CellContentTooltip/CellContentTooltip';
import { getMappedEnumIsFilterOperator } from '../filterOperators/mappedEnumFilterOperator';
import { useTranslation } from 'react-i18next';
import { createStatusChipComponent } from '../components/StatusChip/StatusChip';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { apiOrganization } from '../resources/api/apiOrganization';
import { OrganizationContext } from '../contexts/OrganizationContext';
import Button from '@mui/material/Button';
import { ResourceDenied } from '../resources/resource';
import { useQueryParam } from '../hooks/useQueryParam';
import { useQueryUrl } from '../hooks/useQueryUrl';
import { useCloseDialog } from '../hooks/useCloseDialog';
import { Link } from 'react-router-dom';
import { InviteUserModal } from '../components/InviteUserModal/InviteUserModal';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';

const StatusRenderer = createStatusChipComponent({
  verified: ['common.accountStatus.verified', 'lime'],
  unverified: ['common.accountStatus.unverified', 'orange'],
});

const OrganizationUsers = () => {
  const { organization } = useContext(OrganizationContext);

  const { data } = useContext(OrganizationUsersFetcher.Context);

  const rows = data ? data.payload.data : null;

  const getRowLink = useCallback((params: GridRowParams) => {
    return AuthenticatedRoutes.UserOrganization(params.row.id, organization?.name);
  }, []);

  const isGetRowLinkAllowed = true; //useIsGuardedRouteAllowed('UserOrganization', ['any', organization?.name]);

  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'first_name',
        headerName: t('common.tableHeader.firstName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'last_name',
        headerName: t('common.tableHeader.lastName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'phone_number',
        headerName: t('common.tableHeader.phone'),
        width: 120,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 120,
        renderCell: (params) => {
          return <StatusRenderer {...params} value={!params.row.verified ? 'unverified' : 'verified'} />;
        },
        filterOperators: [
          getMappedEnumIsFilterOperator({
            [t('common.accountStatus.verified')]: ['verified', true],
            [t('common.accountStatus.unverified')]: ['verified', false],
          }),
        ],
        sortable: false,
      },
      {
        field: 'verified',
        headerName: t('common.tableHeader.updated'),
        hide: true,
        hideable: false,
        sortable: false,
      },
    ];
  }, []);

  if (!rows || !data) return null;
  return (
    <UrlQueryDataGrid
      columns={columns}
      rows={rows}
      disableExtendRowFullWidth={false}
      autoHeight={true}
      disableSelectionOnClick={true}
      paginationMode={'server'}
      filterMode={'server'}
      sortingMode={'server'}
      rowCount={data.payload.total_items}
      getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
    />
  );
};

const { useInviteUsers } = apiOrganization;

// @todo add trans
export const OrganizationUsersPage: FC = () => {
  const { organization } = useContext(OrganizationContext);

  const { Guard: GuardInviteUsers } = useInviteUsers(organization?.name);

  const isInviteOpen = useQueryParam('dialog') === 'invite';

  const inviteUrl = useQueryUrl('dialog', 'invite');

  const onCloseDialog = useCloseDialog(void 0, ['dialog']);

  if (!organization) {
    return null;
  }

  return (
    <div>
      <PageHeader title={'Users'}>
        <GuardInviteUsers>
          <Button component={Link} variant="contained" to={inviteUrl}>
            Invite user
          </Button>
          {/* @todo remove below message */}
          <ResourceDenied>&quot;invite user&quot; button would be here, but the permission is missing</ResourceDenied>
        </GuardInviteUsers>
      </PageHeader>

      <OrganizationUsers />

      <InviteUserModal organization={organization} open={isInviteOpen} closeDialog={onCloseDialog} />
    </div>
  );
};
