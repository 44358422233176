import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const authResources = {
  signUp: createResourceBuilder('/identities/auth/signup', EResourceMethod.Post),
  login: createResourceBuilder('/identities/auth/login', EResourceMethod.Post),
  register2fa: createResourceBuilder('/identities/auth/2fa/register', EResourceMethod.Post),
  verify2faSetup: createResourceBuilder('/identities/auth/2fa/verify-setup', EResourceMethod.Post),
  verify2fa: createResourceBuilder('/identities/auth/2fa/verify', EResourceMethod.Post),
  send2fa: createResourceBuilder('/identities/auth/2fa/send', EResourceMethod.Post),
  useBackup2faCode: createResourceBuilder('/identities/auth/2fa/use-backup-code', EResourceMethod.Post),
  disable2fa: createResourceBuilder('/identities/auth/2fa/disable', EResourceMethod.Patch),
  logout: createResourceBuilder('/identities/auth/logout', EResourceMethod.Post),
  logoutFromAllSessions: createResourceBuilder('/identities/auth/logout-from-all-sessions', EResourceMethod.Post),
  refreshToken: createResourceBuilder('/identities/auth/refresh-token', EResourceMethod.Post),
  sendEmailVerificationCode: createResourceBuilder(
    '/identities/auth/send-email-verification-code',
    EResourceMethod.Post
  ),
  /** @todo change that to POST */
  verifyEmailByToken: createResourceBuilder('/identities/auth/verify-email', EResourceMethod.Get),
  changePassword: createResourceBuilder('/identities/auth/change-password', EResourceMethod.Post),
  requestPasswordReset: createResourceBuilder('/identities/auth/forget-password', EResourceMethod.Post),
  confirmPasswordReset: createResourceBuilder('/identities/auth/reset-password', EResourceMethod.Post),
} as const;
