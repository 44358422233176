import { useCallback, useContext, useMemo } from 'react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { LoadingContext } from '../contexts/LoadingContext';
import { config } from '../config';
import { logger } from '../helpers/logger';
import { AccountType } from '../types/AccountType';
import { GlobalContext } from '../contexts/GlobalContext';
import { AuthContext } from '../contexts/AuthContext';
import { parseAccessToken } from '../helpers/parseAccessToken';

enum HttpResponseCode {
  Unauthorized = 401,
}

const tokenRefreshEndpoint = '/identities/auth/refresh-token';

// @deprecated
export const accountTypePrefix: Record<AccountType, string> = {
  [AccountType.User]: '/customer/user',
  [AccountType.Customer]: '/customer/admin',
  [AccountType.Admin]: '/admin',
};

export interface IApiConfig {
  addAuthHeader?: boolean;
  logRequests?: boolean;
  indicateLoading?: boolean;
  signOutUnauthorized?: boolean;
  addAccountTypePrefix?: boolean;
  throwIfErrorFieldIsPresent?: boolean;
  refreshTokenBeforeExpiration?: boolean;
  refreshTokenIfExpired?: boolean;
  baseURL?: string;
}

export interface IApiExecutor {
  get: <ResponseType>(path: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<ResponseType>>;
  post: <ResponseType>(path: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<ResponseType>>;
  put: <ResponseType>(path: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<ResponseType>>;
  patch: <ResponseType>(path: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<ResponseType>>;
  del: <ResponseType>(path: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<ResponseType>>;
}

// 401 Unauthorized
// {error: true, reason: "exp claim verification failed: expired"}

export const useApi = ({
  addAuthHeader = true,
  logRequests = true,
  indicateLoading = true,
  // @todo this is temporarily disabled
  signOutUnauthorized = false,
  throwIfErrorFieldIsPresent = true,
  refreshTokenBeforeExpiration = true,
  // refreshTokenIfExpired = true,
  baseURL,
}: IApiConfig = {}): IApiExecutor => {
  const { setSignInFastTrack } = useContext(GlobalContext);

  const { identity, setIdentity } = useContext(AuthContext);

  const { startLoading, endLoading } = useContext(LoadingContext);

  const accessToken = identity?.tokenData?.access_token;

  const authHeader = useMemo<AxiosRequestHeaders>(() => {
    if (accessToken) {
      return { Authorization: `Bearer ${accessToken}` };
    } else {
      return {} as AxiosRequestHeaders;
    }
  }, [accessToken]);

  const createAxiosInstance = useCallback(() => {
    const axiosInstance = axios.create({
      baseURL: baseURL || localStorage.getItem('apiUrl') || config.apiUrl,
      // headers: {
      //   ...authHeader,
      // },
    });

    return axiosInstance;
  }, [/*authHeader*/ baseURL]);

  const addAuthHeaderInterceptor = useCallback(
    (instance: AxiosInstance) => {
      const reqInterceptor = instance.interceptors.request.use((config) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            ...authHeader,
          },
        };
      });

      return () => {
        instance.interceptors.request.eject(reqInterceptor);
      };
    },
    [authHeader]
  );

  const addLoggingInterceptors = useCallback((instance: AxiosInstance) => {
    const reqInterceptor = instance.interceptors.request.use((config) => {
      logger.http(`[${config.method?.toUpperCase()}] REQUEST`, config.url, config);
      return config;
    });
    const resInterceptor = instance.interceptors.response.use(
      (response) => {
        logger.http(`[${response.config.method?.toUpperCase()}] RESPONSE`, response.config.url, response);
        return response;
      },
      (error: AxiosError) => {
        logger.http(
          `[${error.response?.config.method?.toUpperCase()}] RESPONSE ERROR`,
          error.response?.config.url ?? error.config.url,
          error.response
        );
        throw error;
      }
    );
    return () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  const addLoadingInterceptors = useCallback((instance: AxiosInstance) => {
    const reqInterceptor = instance.interceptors.request.use((config) => {
      startLoading();
      return config;
    });
    const resInterceptor = instance.interceptors.response.use(
      (response) => {
        endLoading();
        return response;
      },
      (error) => {
        endLoading();
        throw error;
      }
    );
    return () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  // const addAccountTypePrefixInterceptor = useCallback(
  //   (instance: AxiosInstance) => {
  //     const reqInterceptor = instance.interceptors.request.use((config) => {
  //       return { ...config, url: `${accountTypePrefix[accountType]}${config.url}` };
  //     });
  //     return () => {
  //       instance.interceptors.request.eject(reqInterceptor);
  //     };
  //   },
  //   [accountType]
  // );

  const addSignOutUnauthorizedInterceptor = useCallback(
    (instance: AxiosInstance) => {
      const resInterceptor = instance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error?.response?.status === HttpResponseCode.Unauthorized) {
            if (identity?.email) {
              setSignInFastTrack({
                // @todo
                accountType: AccountType.Admin,
                email: identity.email,
              });
            }
            setIdentity(null);
          }
          throw error;
        }
      );
      return () => {
        instance.interceptors.response.eject(resInterceptor);
      };
    },
    [identity]
  );

  const addThrowIfErrorPropertyIsPresentInterceptor = useCallback((instance: AxiosInstance) => {
    const resInterceptor = instance.interceptors.response.use((response) => {
      if (response.data && typeof response.data === 'object' && 'error' in response.data && !!response.data.error) {
        throw response.data.error;
      }
      return response;
    });
    return () => {
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  const addRefreshTokenBeforeExpirationInterceptor = useCallback(
    (instance: AxiosInstance) => {
      const reqInterceptor = instance.interceptors.request.use(async (reqConfig) => {
        if (reqConfig.url?.includes(tokenRefreshEndpoint)) {
          console.log('  -- returning');
          return reqConfig;
        }

        if (identity) {
          const { access_token } = identity.tokenData;
          const tokenPayload = parseAccessToken(access_token);

          console.log('token payload', tokenPayload);

          // If token expiration is earlier than 5 minutes ahead
          // if (forceRefresh || tokenPayload.exp * 1e3 < Date.now() + 3e5) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (window.forceRefreshToken || tokenPayload.exp * 1e3 < Date.now() + 3e5) {
            console.log('YAAAY, refreshment attempt!');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.forceRefreshToken = false;
            const refreshInstance = axios.create({
              baseURL: baseURL || localStorage.getItem('apiUrl') || config.apiUrl,
              headers: {},
            });

            const response = await refreshInstance.post(tokenRefreshEndpoint, identity.tokenData);

            /*
             {
                 "message": "Tokens are generated successfully!",
                 "error": false,
                 "status_code": 200,
                 "payload": {
                     "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiMTA0NTJkNC0xMmMxLTRkMGMtOWZlZS01NzdjNzY1MjIxOGIiLCJ1c2VyX2lkIjoiZmIxYzk1OWMtNWE5Ni00MTIzLWEwZGYtZjMwYTI5YWU5ZjgxIiwiZW1haWwiOiJsdWthc3ouZnVkcm8rdXNlckBwaXhvdG9wZS5jb20iLCJkYXRhIjp7fSwiZXhwIjoxNzMzNDg4NjgyfQ.86eUDY3RFCPN-1B7C5XXDpoUhA6-kr09U9n9jkfFtD4",
                     "refresh_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlZmRhYzNjMi1iYTIxLTRlM2ItYWEzNy03MmVkMDk1Y2ZhZjQiLCJ1c2VyX2lkIjoiZmIxYzk1OWMtNWE5Ni00MTIzLWEwZGYtZjMwYTI5YWU5ZjgxIiwiZW1haWwiOiJsdWthc3ouZnVkcm8rdXNlckBwaXhvdG9wZS5jb20iLCJkYXRhIjp7fSwiZXhwIjoxNzM1OTk0MjgyfQ.JP4oPbT7CPVCcxtRNbnqntqyfo1Th39KFSzxuhG3WdA"
                 }
             }
            */

            const newTokenData = response.data.payload;

            setIdentity({
              ...identity,
              tokenData: newTokenData,
            });

            return {
              ...reqConfig,
              headers: {
                ...reqConfig.headers,
                Authorization: `Bearer ${newTokenData.access_token}`,
              },
            };
          }
        }
        return reqConfig;
      });

      return () => {
        instance.interceptors.request.eject(reqInterceptor);
      };
    },
    [identity]
  );

  const axiosInstance = useMemo(() => {
    const instance = createAxiosInstance();
    addAuthHeader && addAuthHeaderInterceptor(instance);
    logRequests && addLoggingInterceptors(instance);
    indicateLoading && addLoadingInterceptors(instance);
    signOutUnauthorized && addSignOutUnauthorizedInterceptor(instance);
    throwIfErrorFieldIsPresent && addThrowIfErrorPropertyIsPresentInterceptor(instance);
    refreshTokenBeforeExpiration && addRefreshTokenBeforeExpirationInterceptor(instance);
    return instance;
  }, [
    createAxiosInstance,
    addAuthHeaderInterceptor,
    addLoggingInterceptors,
    addLoadingInterceptors,
    addSignOutUnauthorizedInterceptor,
    addThrowIfErrorPropertyIsPresentInterceptor,
    addRefreshTokenBeforeExpirationInterceptor,
    addAuthHeader,
    logRequests,
    indicateLoading,
    signOutUnauthorized,
  ]);

  const get = useCallback(
    <T>(path: string, config?: AxiosRequestConfig) => {
      return axiosInstance.get<T, AxiosResponse<T>>(path, config);
    },
    [axiosInstance]
  );

  const post = useCallback(
    <T>(path: string, data?: any, config?: AxiosRequestConfig) => {
      return axiosInstance.post<T, AxiosResponse<T>>(path, data, config);
    },
    [axiosInstance]
  );

  const put = useCallback(
    <T>(path: string, data?: any, config?: AxiosRequestConfig) => {
      return axiosInstance.put<T, AxiosResponse<T>>(path, data, config);
    },
    [axiosInstance]
  );

  const patch = useCallback(
    <T>(path: string, data?: any, config?: AxiosRequestConfig) => {
      return axiosInstance.patch<T, AxiosResponse<T>>(path, data, config);
    },
    [axiosInstance]
  );

  const del = useCallback(
    <T>(path: string, config?: AxiosRequestConfig) => {
      return axiosInstance.delete<T, AxiosResponse<T>>(path, config);
    },
    [axiosInstance]
  );

  return useMemo(
    () => ({
      get,
      post,
      put,
      patch,
      del,
    }),
    [get, post, put, patch, del]
  );
};
