import { useCallback, useEffect, useState } from 'react';
import { IBasicUserInfo } from '../resources/models/IUserInfo';
import { apiUser } from '../resources/api/apiUser';

const { useGetUsersByIds } = apiUser;

export const useLazyUsers = (userIds: string[]) => {
  const { execute: getUsersByIds } = useGetUsersByIds();
  const [lazyUsers, setLazyUsers] = useState<Array<Pick<IBasicUserInfo, 'id' | 'first_name' | 'last_name' | 'email'>>>(
    []
  );

  // @todo handle aborting query
  useEffect(() => {
    if (userIds.length === 0) {
      return;
    }
    (async () => {
      const usersData = await getUsersByIds(userIds, {
        page: 1,
        limit: userIds.length,
      });
      setLazyUsers(usersData.payload.data);
    })();
  }, [getUsersByIds, userIds]);

  const getUserData = useCallback(
    (userId: string) => {
      return lazyUsers.find((user) => user.id === userId);
    },
    [lazyUsers]
  );

  return { getUserData };
};
