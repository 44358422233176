import React, { FC, useCallback, useContext } from 'react';
import { ReleasesFetcher } from '../../fetchers/ReleasesFetcher';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DateFormat } from '../../helpers/dateFormat';
import { displayFilesize } from '../../helpers/displayFilesize';
import { Link, useHistory } from 'react-router-dom';
import { useIntParams } from '../../hooks/useIntParams';
import { EditReleaseDialog } from './EditReleaseDialog';
import { NewReleaseDialog } from './NewReleaseDialog';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { useDialog } from '../../hooks/useDialog';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { apiProductRelease } from '../../resources/api/apiProductRelease';
import { GuardedRouteLink } from '../../resources/GuardedRouteLink';

interface IUrlParams {
  productId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    cellLink: {
      textDecoration: 'none',
      color: 'inherit',
      width: '100%',
      display: 'inline-block',
    },
  })
);

const { useCreateProductRelease } = apiProductRelease;

export const ProductVersions: FC = () => {
  const { data } = useContext(ReleasesFetcher.Context);
  const { productId } = useIntParams<IUrlParams>();
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { Guard: GuardCreateRelease } = useCreateProductRelease(productId, 'any');

  const closeDialog = useCallback(() => {
    history.push(AuthenticatedRoutes.ReleasesProduct(productId));
  }, [productId]);
  const { openUrl: createUrl, close: abortCreate } = useDialog('new');

  if (!data) return null;
  return (
    <>
      {data.releases.length === 0 || data.releases[0].versions.length === 0 ? (
        <Box marginTop={3}>
          <Paper>
            <Box padding={3}>
              <Typography variant="h5">{t('releases.noReleases.message')}</Typography>
              <Box marginTop={1}>
                <GuardCreateRelease>
                  <Button variant="outlined" color="primary" size="large" component={Link} to={createUrl}>
                    {t('releases.noReleases.addButton')}
                  </Button>
                </GuardCreateRelease>
              </Box>
            </Box>
          </Paper>
        </Box>
      ) : null}
      {data.releases[0]?.versions.map((version) => {
        return (
          <Box marginTop={3} key={version.version}>
            <Card>
              <CardContent>
                <Typography variant="h5">{t('releases.versionHeader', { version: version.version })}</Typography>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('releases.table.releasedHeader')}</TableCell>
                      <TableCell>{t('releases.table.versionHeader')}</TableCell>
                      <TableCell>{t('releases.table.buildHeader')}</TableCell>
                      <TableCell>{t('releases.table.productHeader')}</TableCell>
                      <TableCell>{t('releases.table.sizeHeader')}</TableCell>
                    </TableRow>
                  </TableHead>

                  {version.installer.map((installer) => {
                    const Cell: FC = ({ children }) => (
                      <TableCell>
                        <Typography
                          component={GuardedRouteLink}
                          variant="body2"
                          route="ReleasesProductVersion"
                          params={[productId, installer.version]}
                          showFallback
                          className={styles.cellLink}
                        >
                          {children}
                        </Typography>
                      </TableCell>
                    );

                    return (
                      <TableRow key={installer.version}>
                        <Cell>
                          <Date date={installer.released} format={DateFormat.DateMedium} />
                        </Cell>
                        <Cell>{installer.version}</Cell>
                        <Cell>{installer.build}</Cell>
                        <Cell>{installer.filePath}</Cell>
                        <Cell>{installer.file ? displayFilesize(installer.file.Size) : '-'}</Cell>
                      </TableRow>
                    );
                  })}
                </Table>
              </CardContent>
            </Card>
          </Box>
        );
      })}
      <NewReleaseDialog closeDialog={abortCreate} />
      <EditReleaseDialog closeDialog={closeDialog} />
    </>
  );
};
