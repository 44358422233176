import { IResponse } from '../models/IResponse';
import { permissionResources } from '../builders/permissionResources';
import { EResourceMethod, IFullPermission } from '../models/IPermission';
import { IPage } from '../models/IPage';
import { getUseResource } from '../resource';
import { IEndpointPermission } from '../models/IEndpointPermission';

export const apiPermission = {
  usePermissions: getUseResource(permissionResources.getPermissions, (body): IResponse<IPage<IFullPermission>> => body),
  useUserPermissions: getUseResource(
    permissionResources.getUserPermissions,
    (body): IResponse<Record<EResourceMethod, IEndpointPermission[]>> => body
  ),
};
