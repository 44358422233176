import React, { FC, useCallback, useContext, useMemo } from 'react';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowData, GridRowParams } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { useAutoFetch } from '../../contexts/Fetcher';
import { IPlan } from '../../models/Plan';
import { AddProductDialog } from './AddProductDialog';
import { useIntParams } from '../../hooks/useIntParams';
import { EditProductDialog } from './EditProductDialog';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { CollapsedContentsWithTooltip } from '../CollapsedContentsWithTooltip/CollapsedContentsWithTooltip';
import { useDialog } from '../../hooks/useDialog';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { useCloseDialog } from '../../hooks/useCloseDialog';

interface IUrlParams {
  id?: string;
}

export const ProductsList: FC = () => {
  const { data } = useContext(ProductsFetcher.Context);
  useAutoFetch(ProductsFetcher);
  const history = useHistory();

  const { isOpen: isAdding, close: closeDialog } = useDialog('new');

  const abortEdit = useCloseDialog(AuthenticatedRoutes.AdminProductsTab('products'));

  const { id } = useIntParams<IUrlParams>();
  useDefaultSorting('id', 'asc');
  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
      },
      {
        field: 'name',
        headerName: t('common.tableHeader.name'),
        flex: 1,
      },
      {
        field: 'plans',
        headerName: t('adminProducts.products.table.plansHeader'),
        flex: 1,
        valueGetter: (params) => {
          const count = (params.row.plans as IPlan[]).length;
          return t('adminProducts.products.table.totalPlans', { count });
        },
        renderCell: (params) => {
          const items = (params.row.plans as IPlan[]).map((plan) => ({
            name: plan?.name,
          }));
          return <CollapsedContentsWithTooltip items={items}>{params.value}</CollapsedContentsWithTooltip>;
        },
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    if (!data) return [];
    return data.products;
  }, [data]);

  const getRowLink = useCallback((params: GridRowParams) => {
    return `${AuthenticatedRoutes.AdminProductsTabEditItem('products', params.row.id)}${history.location.search}`;
  }, []);
  /** @todo add proper isAllowed check */
  const isGetRowLinkAllowed = true; //useIsAdminRouteAllowed('ProductsTabEditItem', 'products');

  if (!data) return null;
  const product = data.products.find((p) => p.id === id);

  return (
    <div>
      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />

      <AddProductDialog open={isAdding} closeDialog={closeDialog} />
      <EditProductDialog product={product} closeDialog={abortEdit} />
    </div>
  );
};
