import { getUseResource, resolveResourceUrl } from '../resource';
import { authResources } from '../builders/authResources';
import { IResponse } from '../models/IResponse';
import { IBasicUserInfo, IUserInfo } from '../models/IUserInfo';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

type ISignUpRequest = Pick<IUserInfo, 'first_name' | 'last_name' | 'email' | 'phone_number'> & { password: string };

interface ITokenResponse {
  access_token: string;
  refresh_token: string;
}

interface IRegister2FAResponse {
  qr: string;
  backup_code: string;
}

export enum EMfaMethod {
  Email = 'email',
  AuthApp = 'auth_app',
}

export const apiAuth = {
  useSignUp: getUseResource(
    authResources.signUp,
    (body): IResponse<IBasicUserInfo> => body,
    (params, payload: ISignUpRequest) => payload,
    void 0,
    void 0,
    {
      addAuthHeader: false,
    }
  ),
  useLogin: getUseResource(
    authResources.login,
    (body): IResponse<ITokenResponse> => body,
    (params, email: string, password: string) => ({ email, password }),
    void 0,
    void 0,
    {
      addAuthHeader: false,
    }
  ),
  useRegister2fa: getUseResource(
    authResources.register2fa,
    (body): IResponse<IRegister2FAResponse> => body,
    (params, mfa_method: EMfaMethod) => ({ mfa_method })
  ),
  useVerify2faSetup: getUseResource(
    authResources.verify2faSetup,
    (body): IResponse<void> => body,
    (params, mfa_method: EMfaMethod, token: string) => ({ mfa_method, token })
  ),
  useVerify2fa: getUseResource(
    authResources.verify2fa,
    (body): IResponse<ITokenResponse> => body,
    (params, mfa_method: EMfaMethod, token: string) => ({ mfa_method, token }),
    (resource, apiExecutor) => {
      const resourceUrl = resource ? resolveResourceUrl(resource) : null;
      const { auth2faCache } = useContext(AuthContext);
      return async (payload): Promise<IResponse<ITokenResponse>> => {
        if (!resourceUrl) {
          throw new Error('resource is missing');
        }
        const response = await apiExecutor.post<IResponse<ITokenResponse>>(resourceUrl, payload, {
          headers: {
            Authorization: `Bearer ${auth2faCache?.access_token}`,
          },
        });
        return response.data;
      };
    }
  ),
  useSend2fa: getUseResource(
    authResources.send2fa,
    (body): IResponse<void> => body,
    (params, mfa_method: EMfaMethod) => ({ mfa_method }),
    (resource, apiExecutor) => {
      const resourceUrl = resource ? resolveResourceUrl(resource) : null;
      const { auth2faCache } = useContext(AuthContext);
      return async (payload): Promise<IResponse<void>> => {
        if (!resourceUrl) {
          throw new Error('resource is missing');
        }
        const response = await apiExecutor.post<IResponse<void>>(resourceUrl, payload, {
          headers: {
            Authorization: `Bearer ${auth2faCache?.access_token}`,
          },
        });
        return response.data;
      };
    }
  ),
  useUseBackup2faCode: getUseResource(
    authResources.useBackup2faCode,
    (body): IResponse<void> => body,
    (params, mfa_method: EMfaMethod, code: string) => ({ mfa_method, code })
  ),
  useDisable2fa: getUseResource(authResources.disable2fa, (body): IResponse<void> => body),
  useLogout: getUseResource(authResources.logout, (body): IResponse<void> => body),
  useLogoutFromAllSessions: getUseResource(authResources.logoutFromAllSessions, (body): IResponse<void> => body),
  useRefreshToken: getUseResource(authResources.refreshToken, (body): IResponse<ITokenResponse> => body),
  useSendEmailVerificationCode: getUseResource(
    authResources.sendEmailVerificationCode,
    (body): IResponse<void> => body
  ),
  useVerifyEmailByToken: getUseResource(
    authResources.verifyEmailByToken,
    (body): IResponse<void> => body,
    (params, code: string) => ({ code })
  ),
  useChangePassword: getUseResource(
    authResources.changePassword,
    (body): IResponse<any> => body,
    (params, payload: { old_password: string; new_password: string }) => payload
  ),
  useRequestPasswordReset: getUseResource(
    authResources.requestPasswordReset,
    (body): IResponse<void> => body,
    (params, email: string) => ({ email })
  ),
  useConfirmPasswordReset: getUseResource(
    authResources.confirmPasswordReset,
    (body): IResponse<void> => body,
    (params, token: string, password: string) => ({ token, password })
  ),
};
