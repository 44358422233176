import { IEndpointPermission } from './IEndpointPermission';

export interface IPermission {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export enum EResourceMethod {
  Get = 'GET',
  Post = 'POST',
  Delete = 'DELETE',
  Put = 'PUT',
  Patch = 'PATCH',
}

export interface IFullPermission extends IPermission {
  endpoints: Record<EResourceMethod, IEndpointPermission[]>;
}
