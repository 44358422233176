import React, { FC, useContext } from 'react';

import { GuestRouter } from './routers/GuestRouter';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthenticatedRouter } from './routers/AuthenticatedRouter';
import { AuthContext } from './contexts/AuthContext';
import { RedirectToAuth } from './routers/RedirectToAuth';
import { authenticatedRoot } from './routers/AuthenticatedRoutes';

const App: FC = () => {
  const { identity, auth2faCache } = useContext(AuthContext);
  // console.log({ account });
  return (
    <BrowserRouter>
      {identity && !auth2faCache ? <AuthenticatedRouter /> : null}

      <GuestRouter />

      <Switch>
        {!identity ? <RedirectToAuth from={authenticatedRoot} /> : null}

        {/*  <Route path={guestRoot} />*/}
        {/*  {account ? <Route path={authenticatedRoot} /> : null}*/}
        {/*  <RouteWrapper path={'/'} component={NotFoundComponent} layout={AuthLayout} />*/}
      </Switch>
    </BrowserRouter>
  );
};
export default App;
