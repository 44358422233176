import React, { FC, ForwardedRef, forwardRef, ReactElement, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthenticatedRouteResources, AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { useResourceChecker } from './resource';

type NonNullableArray<T extends any[]> = {
  [El in keyof T]: NonNullable<T[El]>;
};
type AuthenticatedRouteKey = keyof typeof AuthenticatedRoutes;

interface IProps<T extends AuthenticatedRouteKey> {
  children: React.ReactNode;
  showFallback?: boolean;
  route: T;
  params: NonNullableArray<Parameters<typeof AuthenticatedRoutes[T]>>;
  query?: Record<string, string | number>;
  keepQuery?: boolean;
}

// @todo fix `any[]`
export const useIsGuardedRouteAllowed = (route: AuthenticatedRouteKey, params?: any[]) => {
  const resourceChecker = useResourceChecker();
  let isAllowed = true;

  const resourceBuilders = AuthenticatedRouteResources[route];
  for (const builder of resourceBuilders) {
    const resource = builder(...(params ?? []));
    if (!resourceChecker(resource)) {
      isAllowed = false;
      break;
    }
  }
  return isAllowed;
};

export const GuardedRouteCondition: FC<{
  route: AuthenticatedRouteKey;
  params?: any[];
  fallback?: React.ReactNode;
}> = ({ route, params, children, fallback }) => {
  const isAllowed = useIsGuardedRouteAllowed(route, params);

  if (isAllowed) return <>{children}</>;
  return fallback ? <>{fallback}</> : null;
};

const GuardedRouteLinkComponent: <Route extends AuthenticatedRouteKey>(
  props: IProps<Route>,
  ref: ForwardedRef<HTMLAnchorElement>
) => ReactElement | null = ({ route, params, query = {}, keepQuery = false, showFallback = false, ...rest }, ref) => {
  const { search } = useLocation();

  const url = (AuthenticatedRoutes[route] as (...p: typeof params) => string)(...params);

  const linkSearch = useMemo(() => {
    const searchValue = keepQuery ? new URLSearchParams(search) : new URLSearchParams();
    for (const [key, value] of Object.entries(query)) {
      searchValue.set(key, typeof value === 'number' ? value.toString() : value);
    }
    return searchValue.toString();
  }, [JSON.stringify(query), keepQuery, search]);

  return (
    <GuardedRouteCondition route={route} params={params} fallback={showFallback ? rest.children : void 0}>
      <Link ref={ref} to={`${url}?${linkSearch}`} {...rest} />
    </GuardedRouteCondition>
  );
};
export const GuardedRouteLink = forwardRef(GuardedRouteLinkComponent);
