import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { StandardTextFieldProps } from '@mui/material/TextField';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';

interface IProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  disabled?: boolean;
  errors: FieldErrors;
  InputProps?: StandardTextFieldProps['InputProps'];
  inputType?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: StandardTextFieldProps['size'];
  label: string;
  className?: string;
}

export const TextInputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled = false,
  errors,
  InputProps,
  inputType = 'text',
  label,
  name,
  variant,
  size,
  className,
  ...restProps
}: IProps<TFieldValues, TName>) => {
  const renderFn = useCallback<ControllerProps<TFieldValues, TName>['render']>(
    ({ field: { ref, ...field } }) => {
      return (
        <TextField
          fullWidth
          label={label}
          inputRef={ref}
          {...field}
          type={inputType}
          InputProps={InputProps}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          disabled={disabled}
          variant={variant}
          size={size}
          className={className}
        />
      );
    },
    [label, inputType, InputProps, errors[name], disabled]
  );

  return <Controller name={name} render={renderFn} {...restProps} />;
};
