import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWrapper } from '../components/RouteWrapper';
import { DashboardLayout } from '../layouts/DashboardLayout';
import { NotFoundComponent } from '../components/NotFound';
import { authenticatedRoot, AuthenticatedRoutes } from './AuthenticatedRoutes';
import { Welcome } from '../pages/Welcome';
import { ChooseOrganizationPage } from '../pages/ChooseOrganization';
import { AuthLayout } from '../layouts/AuthLayout';
import { SecurityPage } from '../pages/Security';
import { OrganizationUsersPage } from '../pages/OrganizationUsers';
import { OrganizationUsersFetcher } from '../fetchers/OrganizationUsersFetcher';
import { AdminProducts } from '../components/AdminProducts/AdminProducts';
import { Releases } from '../components/Releases/Releases';
import { ProductsFetcher } from '../fetchers/ProductsFetcher';
import { OrganizationsPage } from '../pages/Organizations';
import { OrganizationPage } from '../pages/Organization';
import { AllUsers } from '../components/AllUsers/AllUsers';
import { UserPage } from '../pages/User';
import { UserOrganizationPage } from '../pages/UserOrganization';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */

// OrganizationUsersFetcher.WAF

const ProvidersProductsFetcher = [ProductsFetcher.WMF];

export const AuthenticatedRouter: FC = () => {
  return (
    <Switch>
      <Redirect exact from={authenticatedRoot} to={AuthenticatedRoutes.ChooseOrganization()} />

      <RouteWrapper exact path={AuthenticatedRoutes.ChooseOrganization()} component={ChooseOrganizationPage} layout={AuthLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.Security()} component={SecurityPage} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationUsers()} component={OrganizationUsersPage} layout={DashboardLayout} providers={[OrganizationUsersFetcher.WAF]} />

      <RouteWrapper exact path={AuthenticatedRoutes.Welcome()} component={Welcome} layout={DashboardLayout} />

      <Redirect exact from={'/'} to={authenticatedRoot} />
      {/*<Redirect from={guestRoot} to={authenticatedRoot} />*/}

      <RouteWrapper exact path={AuthenticatedRoutes.AdminProducts()} component={AdminProducts} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.AdminProductsTab()} component={AdminProducts} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.AdminProductsTabEditItem()} component={AdminProducts} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.Releases()} component={Releases} providers={ProvidersProductsFetcher} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.ReleasesProduct()} component={Releases} providers={ProvidersProductsFetcher} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.ReleasesProductVersion()} component={Releases} providers={ProvidersProductsFetcher} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.Organizations()} component={OrganizationsPage} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationDetails()} component={OrganizationPage} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationDetailsProducts()} component={OrganizationPage} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationDetailsUsers()} component={OrganizationPage} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationDetailsInvites()} component={OrganizationPage} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationDetailsTickets()} component={OrganizationPage} layout={DashboardLayout} />
      <RouteWrapper exact path={AuthenticatedRoutes.OrganizationDetailsLive()} component={OrganizationPage} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.AllUsers()} component={AllUsers} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.User()} component={UserPage} layout={DashboardLayout} />

      <RouteWrapper exact path={AuthenticatedRoutes.UserOrganization()} component={UserOrganizationPage} layout={DashboardLayout} />

      <RouteWrapper path={authenticatedRoot} component={NotFoundComponent} layout={DashboardLayout} />
    </Switch>
  );
};
