import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const productReleaseResources = {
  getProductReleases: createResourceBuilder(
    '/admin/releases/product/:product_id',
    EResourceMethod.Get,
    (product_id: number) => ({ product_id })
  ),
  getProductInstallers: createResourceBuilder(
    '/releases/product/:product_id/new',
    EResourceMethod.Get,
    (product_id: number) => ({ product_id })
  ),
  createProductReleaseVersion: createResourceBuilder(
    '/admin/releases/product/:product_id/version/:version',
    EResourceMethod.Post,
    (product_id: number, version: string) => ({ product_id, version })
  ),
  updateProductReleaseVersion: createResourceBuilder(
    '/admin/releases/product/:product_id/version/:version',
    EResourceMethod.Put,
    (product_id: number, version: string) => ({ product_id, version })
  ),
  deleteProductReleaseVersion: createResourceBuilder(
    '/admin/releases/product/:product_id/version/:version',
    EResourceMethod.Delete,
    (product_id: number, version: string) => ({ product_id, version })
  ),
};
