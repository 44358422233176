import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { OrganizationContext } from './OrganizationContext';
import { IBasicOrganization } from '../resources/models/IOrganization';
import { UserContext } from './UserContext';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { AuthContext } from './AuthContext';
import { OrganizationFetcher } from '../components/Customer/CustomerFetchers';

const ORGANIZATION_LOCAL_STORAGE_KEY = 'currentOrganization';

export const OrganizationContextProvider: FC = ({ children }) => {
  const { identity } = useContext(AuthContext);
  const { userInfo } = useContext(UserContext);

  const [organizationId, setOrganizationId] = useLocalStorage<string | null>(ORGANIZATION_LOCAL_STORAGE_KEY, null);

  const organization = useMemo(() => {
    return userInfo?.organizations?.find((organization) => organization.id === organizationId) ?? null;
  }, [organizationId, userInfo?.organizations]);

  const setOrganization = useCallback((organization: IBasicOrganization | null) => {
    setOrganizationId(organization?.id ?? null);
  }, []);

  useEffect(() => {
    if (!identity) {
      setOrganization(null);
    }
    // switching identities
    if (identity) {
      return () => setOrganization(null);
    }
  }, [identity]);

  const contextValue = useMemo(
    () => ({
      organization,
      organizationId,
      setOrganization,
    }),
    [organization]
  );

  return <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>;
};

export const OrganizationContextProviderFromFetcher: FC = ({ children }) => {
  const { data } = useContext(OrganizationFetcher.Context);

  const ctxValue = useMemo(() => {
    return {
      organization: data?.payload ?? null,
      organizationId: data?.payload.id ?? null,
      setOrganization: () => {
        throw new Error('cannot be called in this context');
      },
    };
  }, [data?.payload]);

  return <OrganizationContext.Provider value={ctxValue}>{children}</OrganizationContext.Provider>;
};
