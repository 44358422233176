import React, { FC } from 'react';

interface IProps {
  stackName: string;
}

export const LiveStackHeader: FC<IProps> = ({ stackName }) => {
  // const accountType = useContext(AccountContext)!;

  // const stackData = new StackName(stackName);

  // if (!stackData.valid)
  return <span>{stackName}</span>;

  // const { prefix, customerId, separator1, userId, separator2, stackId } = stackData;
  //
  // return (
  //   <span>
  //     {prefix}
  //     {accountType === AccountType.Admin ? (
  //       <Link
  //         component={AdminRouteLink}
  //         route={'Customer'}
  //         params={[parseInt(customerId)]}
  //         color="primary"
  //         underline="hover"
  //         showFallback
  //       >
  //         {customerId}
  //       </Link>
  //     ) : (
  //       customerId
  //     )}
  //     {separator1}
  //     {accountType === AccountType.Admin ? (
  //       <Link
  //         component={AdminRouteLink}
  //         route={'CustomerUser'}
  //         params={[parseInt(customerId), parseInt(userId)]}
  //         color="primary"
  //         underline="hover"
  //         showFallback
  //       >
  //         {userId}
  //       </Link>
  //     ) : accountType === AccountType.Customer ? (
  //       <Link component={RouterLink} to={CustomerRoutes.User(parseInt(userId))} color="primary" underline="hover">
  //         {userId}
  //       </Link>
  //     ) : (
  //       userId
  //     )}
  //     {separator2}
  //     {stackId}
  //   </span>
  // );
};
