import React, { useContext, useEffect, useState } from 'react';
import { CSSProperties, FC } from 'react';
import { styled, keyframes } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { PermissionContext } from '../contexts/PermissionContext';
import { UserContext } from '../contexts/UserContext';
import Button from '@mui/material/Button';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { Link } from 'react-router-dom';
import { apiPermission } from '../resources/api/apiPermission';
import { TypographyH6 } from '../components/Typography/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/system/Box';
import { IResource, useResourceChecker } from '../resources/resource';
import { apiUser } from '../resources/api/apiUser';
import { apiOrganization } from '../resources/api/apiOrganization';

const anim = keyframes`
  0% {
      color: var(--col1);
  }
  33% {
      color: var(--col2);
  }
  67% {
      color: var(--col3);
  }
  100% {
      color: var(--col1);
  }
`;

const randomColor = () => {
  return (
    '#' +
    Array(3)
      .fill(0)
      .map(() =>
        Math.round(Math.random() * 255)
          .toString(16)
          .padStart(2, '0')
      )
      .join('')
  );
};

const LetterComponent = styled('span')({
  fontSize: '45px',
  fontVariant: 'small-caps',
  animationName: anim,
  animationDuration: '3s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
});

const Letter: FC = ({ children }) => {
  return (
    <LetterComponent
      style={
        {
          '--col1': randomColor(),
          '--col2': randomColor(),
          '--col3': randomColor(),
        } as CSSProperties
      }
    >
      {children}
    </LetterComponent>
  );
};

const { useUserPermissions } = apiPermission;
const { useGetUsers } = apiUser;
const { useInviteUsers, useListOrganizationUsers, useGetOrganizations } = apiOrganization;

const Green = styled('span')({
  color: 'green',
});

const Red = styled('span')({
  color: 'red',
});

const SimplePermChecker: FC<{ resource: IResource<any, any> | null }> = ({ resource }) => {
  const resourceChecker = useResourceChecker();

  return (
    <pre>
      I am {resourceChecker(resource) ? <Green>allowed</Green> : <Red>NOT allowed</Red>} to call{' '}
      {JSON.stringify(resource)}
    </pre>
  );
};

export const Welcome: FC = () => {
  // const { getCurrent } = useUser();
  // const { getPermissions, getUserPermissions } = usePermission();
  //
  // const myId = 'fb1c959c-5a96-4123-a0df-f30a29ae9f81';

  // const [currentUserInfo, setCurrentUserInfo] = useState<null | IUserInfo>(null);
  // const [permissions, setPermissions] = useState<IFullPermission[]>([]);
  // const [userPermissions, setUserPermissions] = useState<any>(null);

  const { userInfo, refetchUserInfo } = useContext(UserContext);
  const { permissions } = useContext(PermissionContext);
  const { organization, setOrganization } = useContext(OrganizationContext);

  const { resource: resourceGetUsers } = useGetUsers();
  const { resource: resourceUserPermissionMy } = useUserPermissions(userInfo?.id);
  const { resource: resourceUserPermissionOther } = useUserPermissions('some-random-id');

  const { resource: resourceInviteUsersMyOrg } = useInviteUsers(organization?.name);
  const { resource: resourceInviteUsersOther } = useInviteUsers('some-random-name');
  const { resource: resourceListMyOrgUsers } = useListOrganizationUsers(organization?.name);
  const { resource: resourceListOtherOrgUsers } = useListOrganizationUsers('some-random-name');
  const { resource: resourceGetOrganizations } = useGetOrganizations();

  // const currentUserResource = getCurrent();
  //                                                  ^  maybe turn that into a hook with memoization

  // const permissionsResource = getPermissions();
  // const myPermissionsResource = getUserPermissions(myId);

  // useEffect(() => {
  //   currentUserResource.execute().then((data) => {
  //     setCurrentUserInfo(data.payload);
  //   });

  // permissionsResource.execute().then((data) => {
  //   setPermissions(data.payload.data);
  // });
  //
  // myPermissionsResource.execute().then((data) => {
  //   setUserPermissions(data.payload);
  // });
  // }, []);

  // const currentUserInfo = currentUserResource.execute();

  // console.log('Current user info', currentUserInfo);
  // console.log('Permissions', permissions);
  // console.log('User Permissions', userPermissions);

  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  useEffect(() => {
    (window as any).forceRefreshToken = forceRefresh;
  }, [forceRefresh]);

  useEffect(() => {
    setForceRefresh((window as any).forceRefreshToken);
  });

  return (
    <div>
      <Letter>W</Letter>
      <Letter>e</Letter>
      <Letter>l</Letter>
      <Letter>c</Letter>
      <Letter>o</Letter>
      <Letter>m</Letter>
      <Letter>e</Letter>
      <Letter>&nbsp;</Letter>
      <Letter>e</Letter>
      <Letter>a</Letter>
      <Letter>r</Letter>
      <Letter>t</Letter>
      <Letter>h</Letter>
      <Letter>.</Letter>

      <Button
        variant="outlined"
        onClick={() => {
          setForceRefresh(!forceRefresh);
        }}
      >
        {forceRefresh ? 'Disable' : 'Enable'} force token refresh in next request
      </Button>

      <Button variant="outlined" onClick={refetchUserInfo}>
        Refetch user data
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          localStorage.removeItem('identitiesData');
          window.location.reload();
        }}
      >
        Erase localStorage
      </Button>

      <Accordion>
        <AccordionSummary>current user info</AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(userInfo, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>current user permissions</AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(permissions, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>current organization</AccordionSummary>
        <AccordionDetails>
          {organization ? (
            <Button variant="outlined" onClick={() => setOrganization(null)}>
              clear current organization
            </Button>
          ) : (
            <Link to={AuthenticatedRoutes.ChooseOrganization()} component={Button}>
              Choose organization
            </Link>
          )}

          <pre>{JSON.stringify(organization, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>

      <Card>
        <Box p={2} mt={2}>
          <TypographyH6>Permission check</TypographyH6>

          <SimplePermChecker resource={resourceUserPermissionMy} />
          <SimplePermChecker resource={resourceUserPermissionOther} />
          <SimplePermChecker resource={resourceGetUsers} />
          <SimplePermChecker resource={resourceInviteUsersMyOrg} />
          <SimplePermChecker resource={resourceInviteUsersOther} />
          <SimplePermChecker resource={resourceListMyOrgUsers} />
          <SimplePermChecker resource={resourceListOtherOrgUsers} />
          <SimplePermChecker resource={resourceGetOrganizations} />
        </Box>
      </Card>
    </div>
  );
};
