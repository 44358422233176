import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Retry } from '../components/Retry';
import { logger } from '../helpers/logger';
import { useQueryParam } from '../hooks/useQueryParam';
import { usePageSize } from '../hooks/usePageSize';
import { parseMappedEnumIs } from '../filterOperators/mappedEnumFilterOperator';
import { useMemoWithEqualityCheck } from '../hooks/useMemoWithEqualityCheck';
import { FetcherInvalidatorContext } from './FetcherInvalidator';
import { captureException } from '../sentry';
import { isAxiosError } from '../helpers/isAxiosError';
import { IResponse } from '../resources/models/IResponse';

export interface IFetcherContext<T, FetcherProps> {
  data: T | null;
  isLoading: boolean;
  isInvalidated: boolean;
  onRefresh: (fetchCallParams?: Partial<FetcherProps>) => Promise<T | null>;
  onDataUpdate: (data: T) => void;
  invalidateData: () => void;
}

export interface IFetcher<DataType, FetcherProps = unknown> {
  Context: React.Context<IFetcherContext<DataType, FetcherProps>>;
  WrapperAutoFetch: FC<PropsWithChildren<FetcherProps>>;
  WrapperManualFetch: FC<PropsWithChildren<FetcherProps>>;
  WAF: FC<PropsWithChildren<FetcherProps>>;
  WMF: FC<PropsWithChildren<FetcherProps>>;
}

enum FetcherState {
  INITIAL,
  LOADING,
  ERROR,
  INVALIDATED,
  READY,
}

interface IFetcherReducerState<DataType extends unknown> {
  data: DataType | null;
  state: FetcherState;
  errorDetails: null | { status: number; message: string };
}

type FetcherReducerAction<DataType extends unknown> =
  | { type: 'setLoading' }
  | { type: 'setError'; payload: IFetcherReducerState<DataType>['errorDetails'] }
  | { type: 'setInvalidated' }
  | { type: 'setReady'; payload: IFetcherReducerState<DataType>['data'] };

type FetcherReducerType<DataType extends unknown> = (
  state: IFetcherReducerState<DataType>,
  action: FetcherReducerAction<DataType>
) => IFetcherReducerState<DataType>;

const fetcherReducer = <DataType extends unknown>(
  state: IFetcherReducerState<DataType>,
  action: FetcherReducerAction<DataType>
): IFetcherReducerState<DataType> => {
  switch (action.type) {
    case 'setLoading':
      return {
        ...state,
        state: FetcherState.LOADING,
      };
      break;
    case 'setError':
      return {
        ...state,
        state: FetcherState.ERROR,
        errorDetails: action.payload,
      };
      break;
    case 'setInvalidated':
      return {
        ...state,
        state: FetcherState.INVALIDATED,
      };
      break;
    case 'setReady':
      return {
        ...state,
        state: FetcherState.READY,
        data: action.payload,
      };
      break;
    default:
      return state;
  }
};

export const createFetcherWrapper = <DataType extends unknown, FetcherProps extends Record<string, any> | void>(
  Context: React.Context<IFetcherContext<DataType, FetcherProps>>,
  useFetchFn: () => (props: FetcherProps) => Promise<DataType>
): FC<FetcherProps & { autoFetch?: boolean }> => {
  return (props) => {
    const { children, autoFetch, ...fetcherProps } = props;
    const fetch = useFetchFn();
    const { subscribe: invalidatorSubscribe } = useContext(FetcherInvalidatorContext);

    const [{ data, state, errorDetails }, dispatch] = useReducer<FetcherReducerType<DataType>>(fetcherReducer, {
      data: null,
      state: FetcherState.INITIAL,
      errorDetails: null,
    });

    useEffect(() => {
      const listener = () => {
        dispatch({ type: 'setInvalidated' });
      };
      return invalidatorSubscribe(listener);
    }, []);

    const fetcherPropsMemoized = useMemoWithEqualityCheck(fetcherProps, (oldValue, newValue) => {
      return JSON.stringify(oldValue) === JSON.stringify(newValue);
    });

    const onFetch = useCallback(
      async (fetchCallParams?: Partial<FetcherProps>) => {
        let newData: DataType | null = null;
        try {
          dispatch({ type: 'setLoading' });
          newData = await fetch({ ...fetcherProps, ...fetchCallParams } as FetcherProps);
          dispatch({ type: 'setReady', payload: newData });
        } catch (e) {
          logger.error(e);
          captureException(e);

          if (isAxiosError(e)) {
            dispatch({
              type: 'setError',
              payload: {
                status: e.response?.status ?? 0,
                message: e.response?.data?.reason,
              },
            });
          } else {
            dispatch({ type: 'setError', payload: null });
          }
        }
        return newData;
      },
      [fetch, fetcherPropsMemoized]
    );

    const onDataUpdate = useCallback((data: DataType) => {
      dispatch({ type: 'setReady', payload: data });
    }, []);

    const invalidateData = useCallback(() => {
      dispatch({ type: 'setInvalidated' });
    }, []);

    useEffect(() => {
      dispatch({ type: 'setInvalidated' });
    }, [onFetch]);

    useEffect(() => {
      if (autoFetch && [FetcherState.INVALIDATED, FetcherState.INITIAL].includes(state)) onFetch().then();
    }, [onFetch, autoFetch, state]);

    const contextValue = useMemo<IFetcherContext<DataType, FetcherProps>>(
      () => ({
        data,
        isLoading: state === FetcherState.LOADING,
        isInvalidated: state === FetcherState.INVALIDATED,
        onRefresh: onFetch,
        onDataUpdate,
        invalidateData,
      }),
      [data, onFetch, onDataUpdate, state, invalidateData]
    );

    if (state === FetcherState.ERROR) {
      return <Retry status={errorDetails?.status ?? null} message={errorDetails?.message} onRetry={onFetch} />;
    } else {
      return <Context.Provider value={contextValue}>{children}</Context.Provider>;
    }
  };
};
export const composeFetcher = <DataType extends unknown, FetcherProps extends unknown | void>(
  Context: React.Context<IFetcherContext<DataType, FetcherProps>>,
  Wrapper: FC<FetcherProps & { autoFetch?: boolean }>
): IFetcher<DataType, FetcherProps> => {
  const WrapperAutoFetch: FC<PropsWithChildren<FetcherProps>> = (props) => <Wrapper {...props} autoFetch={true} />;
  const WrapperManualFetch: FC<PropsWithChildren<FetcherProps>> = (props) => <Wrapper {...props} autoFetch={false} />;

  return {
    Context,
    WrapperAutoFetch,
    WrapperManualFetch,
    WAF: WrapperAutoFetch,
    WMF: WrapperManualFetch,
  };
};

export const createFetcher = <
  DataType extends unknown,
  FetcherProps extends Record<string, unknown> = Record<string, unknown>
>(
  useFetchFn: () => (props: FetcherProps) => Promise<DataType>
): IFetcher<DataType, FetcherProps> => {
  const defaultContextValue = {
    data: null,
    isLoading: false,
    isInvalidated: false,
    onRefresh: async () => null,
    onDataUpdate: () => void 0,
    invalidateData: () => void 0,
  };
  const Context = createContext<IFetcherContext<DataType, FetcherProps>>(defaultContextValue);
  const Wrapper = createFetcherWrapper(Context, useFetchFn);

  return composeFetcher(Context, Wrapper);
};

export const createNoParamsFetcher = <DataType extends unknown>(
  useFetchFn: () => (params: []) => Promise<DataType>
) => {
  return createFetcher(() => {
    const fetchFn = useFetchFn();
    return useCallback(() => fetchFn([]), [fetchFn]);
  });
};

interface IServerFilter {
  filterField: string;
  filterOp: 'eq' | 'gt' | 'lt' | 'gte' | 'lte' | 'neq' | 'nis' | 'is' | 'ilike';
  filterValue: string;
}

interface IServerSorting {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

export interface IServerSidePaginatedFetcherParams {
  page: number;
  limit?: number;
  /**  @deprecated */
  per?: number;
  filter?: IServerFilter;
  sort?: IServerSorting;
}

const mapFilterOperator = (clientFilterOp: null | string): IServerFilter['filterOp'] | void => {
  if (clientFilterOp === 'equals' || clientFilterOp === 'equals-m') return 'eq';
  if (clientFilterOp === 'gt') return 'gt';
  if (clientFilterOp === 'lt') return 'lt';
  if (clientFilterOp === 'differs') return 'neq';
  if (clientFilterOp === 'contains') return 'ilike';
};

const mapSortOrder = (sortOrder: null | string): IServerSorting['sortOrder'] | void => {
  if (sortOrder === 'asc' || sortOrder === 'desc') return sortOrder;
};

export const createServerSidePaginatedFetcher = <T extends unknown>(
  useFetchFn: () => (params: IServerSidePaginatedFetcherParams) => Promise<T>
) => {
  return createFetcher(() => {
    const page = useQueryParam('page');
    const per = usePageSize();
    const filterOpParam = useQueryParam('filterOp');

    let filterOp = mapFilterOperator(filterOpParam);
    let filterField = useQueryParam('filterField');
    let filterValue = useQueryParam('filterVal');
    const filterFreeText = useQueryParam('text');

    if (filterFreeText) {
      filterField = 'text';
      filterValue = filterFreeText;
      filterOp = 'eq';
    } else if (filterOpParam === 'equals-m' && filterValue) {
      const parsedFilter = parseMappedEnumIs(filterValue);
      if (parsedFilter) {
        filterField = parsedFilter[0];
        filterValue = parsedFilter[1].toString();
      }
    }

    const sortBy = useQueryParam('sortBy');
    const sortOrder = mapSortOrder(useQueryParam('sortOrder'));

    const filter = useMemo<IServerSidePaginatedFetcherParams['filter']>(() => {
      if (filterField && filterOp && filterValue) {
        return { filterField, filterOp, filterValue };
      }
    }, [filterField, filterOp, filterValue]);

    const sort = useMemo<IServerSidePaginatedFetcherParams['sort']>(() => {
      if (sortBy && sortOrder) return { sortBy, sortOrder };
    }, [sortBy, sortOrder]);

    const fetchFn = useFetchFn();

    logger.debug('render fetcher', { page, per, filter, sort });

    return useCallback(() => {
      logger.debug('execute fetcher fn', { page, per, filter, sort });
      return fetchFn({
        page: page ? parseInt(page, 10) : 1,
        limit: per,
        filter,
        sort,
      });
    }, [fetchFn, page, per, JSON.stringify(sort), JSON.stringify(filter)]);
  });
};

export const createNoParamsServerSidePaginatedFetcher = <DataType extends unknown>(
  useFetchFn: () => (params: [], extraParams: IServerSidePaginatedFetcherParams) => Promise<DataType>
) => {
  return createServerSidePaginatedFetcher(() => {
    const fetchFn = useFetchFn();
    return useCallback((extraParams: IServerSidePaginatedFetcherParams) => fetchFn([], extraParams), [fetchFn]);
  });
};

export const useAutoFetch = <DataType extends unknown, FetcherProps>(
  fetcher: IFetcher<DataType, FetcherProps>,
  andCondition = true,
  orCondition = false
) => {
  const { data, isLoading, isInvalidated, onRefresh } = useContext(fetcher.Context);

  useEffect(() => {
    if (isLoading) return;
    if (((!data || isInvalidated) && andCondition) || orCondition) onRefresh().then();
  }, [data, isLoading, isInvalidated, andCondition, orCondition, onRefresh]);
};

export const createIdentitiesFetcher = <T extends unknown>(useFetchFn: () => () => Promise<IResponse<T>>) => {
  return createFetcher(() => {
    const fetchFn = useFetchFn();
    return useCallback(async () => {
      const result = await fetchFn();
      return result.payload;
    }, [fetchFn]);
  });
};
