import { createContext } from 'react';
import { IEndpointPermission } from '../resources/models/IEndpointPermission';
import { IRole } from '../resources/models/IRole';
import { EResourceMethod } from '../resources/models/IPermission';

export interface IPermissionContext {
  roles: IRole[];
  permissions: Record<EResourceMethod, IEndpointPermission[]>;
}

export const defaultPermissionContext: IPermissionContext = {
  roles: [],
  permissions: {
    GET: [],
    POST: [],
    DELETE: [],
    PUT: [],
    PATCH: [],
  },
};

export const PermissionContext = createContext<IPermissionContext>(defaultPermissionContext);
