import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const productResources = {
  getProducts: createResourceBuilder('/admin/products', EResourceMethod.Get),
  createProduct: createResourceBuilder('/admin/products', EResourceMethod.Post),
  updateProduct: createResourceBuilder('/admin/product/:product_id', EResourceMethod.Put, (product_id: number) => ({
    product_id,
  })),
  deleteProduct: createResourceBuilder('/admin/product/:product_id', EResourceMethod.Delete, (product_id: number) => ({
    product_id,
  })),
};
