import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from './UserContext';
import { apiUser } from '../resources/api/apiUser';
import { IUserInfo } from '../resources/models/IUserInfo';
import { AuthContext } from './AuthContext';

export const UserContextProvider: FC = ({ children }) => {
  const { identity } = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);

  const { useGetCurrent } = apiUser;
  const currentUserResource = useGetCurrent();

  const refetchUserInfo = useCallback(async () => {
    try {
      const response = await currentUserResource.execute();
      setUserInfo(response.payload);
    } catch (e) {
      setUserInfo(null);
    }
  }, [currentUserResource]);

  useEffect(() => {
    refetchUserInfo().then();
  }, [refetchUserInfo, identity]);

  const contextVal = useMemo(() => ({ userInfo, refetchUserInfo }), [userInfo, refetchUserInfo]);

  return <UserContext.Provider value={contextVal}>{children}</UserContext.Provider>;
};
