import { IProductVersions } from '../../models/ProductVersions';
import { IProductDetails } from '../../models/ProductDetails';
import { IAwsFileMetadata } from '../../hooks/useDownloadApi';
import { productReleaseResources } from '../builders/productReleaseResources';
import { getUseResource } from '../resource';

interface IReleasesResponse {
  releases: [] | [IProductVersions];
}

export interface IProductReleaseApiData {
  changelogNotes: string;
  forceUpdate: boolean;
  pinCode: number;
}

export interface ICreateVersionPayload extends IProductReleaseApiData {
  filePath?: string;
  marketingNotes: string;
  emailOption: 'none' | 'allAdminsUsers' | 'notArchived' | 'activeLicense' | 'custom';
  customEmails: string;
}

export const apiProductRelease = {
  useGetProductReleases: getUseResource(productReleaseResources.getProductReleases, (body): IReleasesResponse => body),
  useUpdateProductRelease: getUseResource(
    productReleaseResources.updateProductReleaseVersion,
    (body): IReleasesResponse => body,
    (params, payload: IProductReleaseApiData) => payload,
    void 0,
    void 0,
    { signOutUnauthorized: false }
  ),
  useGetProductInstallers: getUseResource(
    productReleaseResources.getProductInstallers,
    (body): { product: IProductDetails; files: IAwsFileMetadata[] } => body
  ),
  useCreateProductRelease: getUseResource(
    productReleaseResources.createProductReleaseVersion,
    (body): IReleasesResponse => body,
    (params, payload: ICreateVersionPayload) => payload,
    void 0,
    void 0,
    { signOutUnauthorized: false }
  ),
  useDeleteProductRelease: getUseResource(
    productReleaseResources.deleteProductReleaseVersion,
    () => null,
    (params, pinCode: number) => ({ pinCode }),
    void 0,
    void 0,
    { signOutUnauthorized: false }
  ),
};
