import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useAutoFetch } from '../../contexts/Fetcher';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { ReleasesFetcher } from '../../fetchers/ReleasesFetcher';
import { PageHeader } from '../PageHeader/PageHeader';
import { useIntParams } from '../../hooks/useIntParams';
import { Link, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ProductVersions } from './ProductVersions';
import { useTranslation } from 'react-i18next';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { useDialog } from '../../hooks/useDialog';
import { apiProductRelease } from '../../resources/api/apiProductRelease';

interface IUrlParams {
  productId?: string;
}

const { useCreateProductRelease } = apiProductRelease;

export const Releases: FC = () => {
  const { data: productsData } = useContext(ProductsFetcher.Context);
  useAutoFetch(ProductsFetcher);
  const { productId } = useIntParams<IUrlParams>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (productsData && !productId) {
      history.replace(AuthenticatedRoutes.ReleasesProduct(productsData.products[0].id));
    }
  }, [productsData, productId]);

  const { openUrl: createUrl } = useDialog('new');
  const { Guard: GuardNewRelease } = useCreateProductRelease(productId, 'any');

  const onProductChange = useCallback((event, value: number) => {
    history.push(AuthenticatedRoutes.ReleasesProduct(value));
  }, []);

  if (!productsData) return null;
  return (
    <div>
      <PageHeader title={t('releases.header')}>
        {productId ? (
          <GuardNewRelease>
            <Button component={Link} to={createUrl} variant="contained" color="primary">
              {t('releases.addButton')}
            </Button>
          </GuardNewRelease>
        ) : null}
      </PageHeader>

      <Tabs
        value={productId}
        indicatorColor="primary"
        textColor="primary"
        onChange={onProductChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {productsData.products.map((product) => {
          return <Tab key={product.id} value={product.id} label={product.name} />;
        })}
      </Tabs>

      <ReleasesFetcher.WAF>
        <ProductVersions />
      </ReleasesFetcher.WAF>
    </div>
  );
};
