import React, { FC, useCallback, useContext, useEffect } from 'react';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductPlanFormPayload, ProductPlanForm } from './ProductPlanForm';
import { useForm } from 'react-hook-form';
import { apiProductPlan } from '../../resources/api/apiProductPlan';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { ProductPlanFetcher } from '../../fetchers/ProductPlanFetcher';
import { useAutoFetch } from '../../contexts/Fetcher';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { Link } from 'react-router-dom';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../hooks/useDialog';

interface IProps {
  planId?: number;
  closeDialog: CloseDialogFunc;
}

const { useUpdatePlan, useDeletePlan } = apiProductPlan;

export const EditProductPlanDialog: FC<IProps> = ({ planId, closeDialog }) => {
  const form = useForm();
  const { execute: updatePlan } = useUpdatePlan(planId);
  const { execute: deletePlan, Guard: GuardDeletePlan } = useDeletePlan(planId);
  const handleError = useApiErrorHandler();
  const { onRefresh } = useContext(ProductPlansFetcher.Context);
  const { data: planData } = useContext(ProductPlanFetcher.Context);
  useAutoFetch(ProductPlanFetcher, !!planId, planData?.plan.id !== planId);
  const { t } = useTranslation();

  const { isOpen: isDeleting, openUrl: deleteUrl, close: abortDelete } = useDialog('delete', 'subdialog');

  useEffect(() => {
    if (!planData) return;
    form.reset({
      name: planData.plan.name,
      type: planData.plan.type,
      price: (planData.plan.price / 100).toFixed(2),
      duration: planData.plan.duration.toString(10),
      productCode: planData.plan?.productCode ?? '',
      productId: planData.products[0].id,
      packages: planData.plan.packages.data,
      watermark: planData.plan.watermark,
      customerPackageDefaultProperties: planData.plan.customerPackageDefaultProperties,
    });
  }, [planData]);

  const toggleArchive = useCallback(
    async (archived: boolean) => {
      if (!planData) return;
      try {
        const result = await updatePlan({ archived });
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [updatePlan]
  );

  const onArchive = useCallback(() => toggleArchive(true), [toggleArchive]);

  const onUnArchive = useCallback(() => toggleArchive(false), [toggleArchive]);

  const onSubmit = useCallback(
    async (data: IProductPlanFormPayload) => {
      try {
        const result = await updatePlan({
          name: data.name,
          duration: parseFloat(data.duration),
          price: parseInt(data.price, 10) * 100,
          type: data.type,
          productCode: data.productCode,
          watermark: data.watermark,
          packages: { data: data.packages },
          customerPackageDefaultProperties: data.customerPackageDefaultProperties,
        });
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [updatePlan]
  );

  const onDelete = useCallback(async () => {
    if (!planData) return;
    try {
      await deletePlan();
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [deletePlan]);

  return (
    <Dialog open={!!planId} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('adminProducts.plans.editDialog.title', { id: planData?.plan.id })}</DialogTitle>
      <DialogContent>
        {planData ? <ProductPlanForm form={form} onSubmit={onSubmit} editPlanId={planData.plan.id} /> : null}
      </DialogContent>

      <DialogActions>
        <span>
          <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        </span>
        {planData?.plan.archived ? (
          <span>
            <Button variant="outlined" onClick={onUnArchive}>
              {t('adminProducts.plans.editDialog.unArchive')}
            </Button>
          </span>
        ) : (
          <span>
            <Button variant="outlined" onClick={onArchive}>
              {t('adminProducts.plans.editDialog.archive')}
            </Button>
          </span>
        )}
        {planData ? (
          <GuardDeletePlan>
            <DangerousButton component={Link} to={deleteUrl}>
              {t('common.button.delete')}
            </DangerousButton>
          </GuardDeletePlan>
        ) : null}

        <span>
          <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('adminProducts.plans.editDialog.confirm')}
          </Button>
        </span>
      </DialogActions>

      {planData ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('adminProducts.plans.deleteDialog.title')}
          confirm={onDelete}
          abort={abortDelete}
          message={t('adminProducts.plans.deleteDialog.message', { name: planData.plan.name })}
          dangerous
        />
      ) : null}
    </Dialog>
  );
};
