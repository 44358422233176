import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CustomerProductsFetcher } from './CustomerFetchers';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowData, GridRowParams } from '@mui/x-data-grid';
import { ScheduledJobs } from '../ScheduledJobs/ScheduledJobs';
import { DateFormat } from '../../helpers/dateFormat';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerNewProduct } from './CustomerNewProduct';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import { useDialog } from '../../hooks/useDialog';
import { apiOrganizationProduct } from '../../resources/api/apiOrganizationProduct';
import { Link } from 'react-router-dom';
import { useIsGuardedRouteAllowed } from '../../resources/GuardedRouteLink';

const useStyles = makeStyles((theme) =>
  createStyles({
    scheduledPaper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    scheduledHeader: {
      padding: theme.spacing(2),
    },
    buttonWrapper: {
      padding: `${theme.spacing(1)} 0`,
    },
  })
);

interface IUrlParams {
  organization_name: string;
}

const StatusChip = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  expired: ['common.licenseStatus.expired', 'default'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
  suspended: ['common.licenseStatus.suspended', 'red'],
});

const { useCreateOrganizationProduct } = apiOrganizationProduct;

export const OrganizationProducts: FC = () => {
  const { data } = useContext(CustomerProductsFetcher.Context);
  const styles = useStyles();
  const { organization_name } = useDecodedParams<IUrlParams>();

  const { Guard: GuardNewProduct } = useCreateOrganizationProduct(organization_name);

  const { isOpen: isNewProductDialogOpen, openUrl: newUrl } = useDialog('new');

  useAutoFetch(CustomerProductsFetcher);
  const { t } = useTranslation();

  const closeDialog = useCloseDialog(AuthenticatedRoutes.OrganizationDetailsProducts(organization_name));

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        width: 80,
        filterOperators: [getStringEqualsOperator(), getGreaterThanOperator(), getLessThanOperator()],
      },
      {
        field: 'amount',
        headerName: t('organization.products.table.amountHeader'),
        width: 100,
        filterOperators: [
          getStringEqualsOperator(),
          getStringDiffersOperator(),
          getGreaterThanOperator(),
          getLessThanOperator(),
        ],
      },
      {
        field: 'product',
        headerName: t('organization.products.table.productHeader'),
        flex: 2,
      },
      {
        field: 'plan',
        headerName: t('organization.products.table.planHeader'),
        flex: 3,
      },
      {
        field: 'type',
        headerName: t('common.tableHeader.type'),
        width: 120,
        filterOperators: [
          getEnumIsFilterOperator(['subscription', 'perpetual']),
          getEnumIsNotFilterOperator(['subscription', 'perpetual']),
        ],
      },
      {
        field: 'expires',
        headerName: t('common.tableHeader.expires'),
        renderCell: (params) => {
          return <LicenseExpiry type={params.row.type} expiry={params.row.expires} />;
        },
        width: 190,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 110,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
        ],
        renderCell: StatusChip,
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    if (!data) return [];
    return data.customerProducts.map((customerProduct) => ({
      id: customerProduct.customerProduct.id,
      amount: customerProduct.customerProduct.amount,
      product: customerProduct.product.name,
      plan: customerProduct.plan.name,
      type: customerProduct.plan.type,
      expires: customerProduct.expires,
      status: getLicenseStatus(customerProduct.suspended, customerProduct.expires, customerProduct.plan.type),
      created: customerProduct.customerProduct.created,
    }));
  }, [data]);

  const getRowLink = useCallback(
    (params: GridRowParams) => AuthenticatedRoutes.OrganizationProduct(organization_name, params.row.id),
    [
      /*customerId*/
    ]
  );

  const isGetRowLinkAllowed = useIsGuardedRouteAllowed('OrganizationProduct', [organization_name, 0]);

  if (!data) return null;
  return (
    <div>
      <div className={styles.buttonWrapper}>
        <GuardNewProduct>
          <Button component={Link} to={newUrl} variant="contained" color="primary">
            {t('organization.products.addButton')}
          </Button>
        </GuardNewProduct>
      </div>

      {data.pendingJobs.length > 0 ? (
        <Paper className={styles.scheduledPaper}>
          <Typography variant="h4" className={styles.scheduledHeader}>
            {t('organization.products.scheduled')}
          </Typography>
          <Divider />
          <ScheduledJobs jobs={data.pendingJobs} />
        </Paper>
      ) : null}

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />

      <CustomerNewProduct open={isNewProductDialogOpen} closeDialog={closeDialog} />
    </div>
  );
};
