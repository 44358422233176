import { createFetcher } from '../contexts/Fetcher';
import { apiUser } from '../resources/api/apiUser';
import { useParams } from 'react-router-dom';

const { useGetUser } = apiUser;

export const UserFetcher = createFetcher(() => {
  const { user_id } = useParams<{ user_id: string }>();
  return useGetUser(user_id).execute;
});
