import { useCallback, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { apiAuth, EMfaMethod } from '../resources/api/apiAuth';
import { useApiErrorHandler } from './useApiErrorHandler';
import { parseAccessToken } from '../helpers/parseAccessToken';

export enum EAuthResponse {
  Success,
  Unauthorized,
  Unexpected,
  NotImplemented,
  SecondFactorNeeded,
  CodeExpired,
}

export interface IBackendAccountData {
  email: string;
  name: string;
  phone: string;
}

export interface ITokenData {
  access_token: string;
  refresh_token: string;
}

export interface IGenericResponse<PayloadType> {
  message: string;
  error: boolean;
  status_code: number;
  payload: PayloadType;
}

export type IIdentityLoginResponse = IGenericResponse<ITokenData>;

const { useLogout, useVerify2fa, useLogin, useSend2fa } = apiAuth;

export const useAuth = () => {
  const handleError = useApiErrorHandler();

  const { setSignInFastTrack } = useContext(GlobalContext);
  const { setIdentity, auth2faCache, setAuth2faCache, forgetCurrentIdentity } = useContext(AuthContext);

  const { execute: signOut } = useLogout();
  const { execute: verify2fa } = useVerify2fa();
  const { execute: login } = useLogin();
  const { execute: send2fa } = useSend2fa();

  const handleLoginSuccess = useCallback((response: ITokenData) => {
    const parsedToken = parseAccessToken(response.access_token);

    setAuth2faCache(null);
    setSignInFastTrack(null);

    setIdentity({
      email: parsedToken.email,
      tokenData: response,
      userId: parseAccessToken(response.access_token).user_id,
    });
  }, []);

  const twoFactorAuth = useCallback(
    async (code: string): Promise<EAuthResponse> => {
      if (!auth2faCache) {
        return EAuthResponse.Unexpected;
      }
      try {
        const response = await verify2fa(EMfaMethod.Email, code);

        if (response.status_code === 200) {
          handleLoginSuccess(response.payload);
          return EAuthResponse.Success;
        }
      } catch (e) {
        if ((e as any)?.response?.data?.message === 'Forbidden') {
          return EAuthResponse.Unauthorized;
        }
        handleError(e);
      }
      /** @todo examine other possibilities */
      return EAuthResponse.Unexpected;
    },
    [verify2fa, handleLoginSuccess, auth2faCache]
  );

  const authenticate = useCallback(
    async (email: string, password: string): Promise<EAuthResponse> => {
      const result = await login(email, password);

      if (result.status_code !== 200) {
        /** @todo check other statuses as well */
        return EAuthResponse.Unauthorized;
      }
      const token = result.payload.access_token;
      const parsedToken = parseAccessToken(token);

      if (parsedToken.data['2fa_required']) {
        setAuth2faCache({
          email: parsedToken.email,
          access_token: token,
        });
        return EAuthResponse.SecondFactorNeeded;
      }

      if (!result.payload.refresh_token) {
        return EAuthResponse.Unexpected;
      }

      handleLoginSuccess(result.payload);
      return EAuthResponse.Success;
    },
    [login, handleLoginSuccess]
  );

  const logOut: () => Promise<void> = useCallback(async () => {
    try {
      await signOut();
      forgetCurrentIdentity();
    } catch (e) {
      handleError(e);
    }

    setIdentity(null);
  }, []);

  const renewMfa = useCallback(async () => {
    if (!auth2faCache) {
      return EAuthResponse.Unexpected;
    }
    try {
      await send2fa(EMfaMethod.Email);
      return EAuthResponse.Success;
    } catch (e) {
      handleError(e);
      return EAuthResponse.Unexpected;
    }
    // return await renewMfaApi(auth2faCache.refreshToken);
  }, [send2fa]);

  return {
    authenticate,
    logOut,
    renewMfa,
    twoFactorAuth,
  };
};
