import React, { FC, ForwardedRef, forwardRef, ReactElement, useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes, AdminRoutesEndpoints } from '../../routers/AdminRoutes';
import { RolesContext } from '../../contexts/RolesContext';
import { Single } from '../../types/single';
import { IRolesResponse } from '../../models/RolesResponse';
import { accountTypePrefix } from '../../hooks/useApi';
import { AccountContext } from '../../contexts/AccountContext';

type NonNullableArray<T extends any[]> = {
  [El in keyof T]: NonNullable<T[El]>;
};
type AdminRouteKey = keyof typeof AdminRoutes;

interface IAdminProps<T extends AdminRouteKey> {
  children: React.ReactNode;
  showFallback?: boolean;
  route: T;
  params: NonNullableArray<Parameters<typeof AdminRoutes[T]>>;
  query?: Record<string, string | number>;
  keepQuery?: boolean;
}

const stripTrailingSlash = (path: string) => path.replace(/\/$/, '');

/** @deprecated Because AdminRouter is no longer used */
export const useIsEndpointAllowed = () => {
  const { endpoints } = useContext(RolesContext);
  const accountType = useContext(AccountContext);
  const prefix = accountType ? accountTypePrefix[accountType] : '';

  return (method: 'DELETE' | 'GET' | 'POST' | 'PUT', path: string) => {
    if (!endpoints) return true;
    return endpoints.some((endpoint) => {
      return endpoint.method.includes(method) && stripTrailingSlash(`/${endpoint.path}`) === `${prefix}${path}`;
    });
  };
};

/** @deprecated Because AdminRouter is no longer used */
export const useIsAdminRouteAllowed = (route: AdminRouteKey, ...params: Array<any>) => {
  const { endpoints } = useContext(RolesContext);
  const isEndpointAllowed = useIsEndpointAllowed();

  const routeEndpoints = AdminRoutesEndpoints[route];

  const isAllowed = useMemo(() => {
    return (
      endpoints === null ||
      (routeEndpoints as Array<Single<IRolesResponse['endpoints']> & { routeParams: any[] }>)
        .filter((routeEndpoint) => {
          if (typeof routeEndpoint.routeParams === 'undefined') return true;
          for (let i = 0; i < routeEndpoint.routeParams.length; ++i) {
            if (typeof routeEndpoint.routeParams[i] !== 'undefined' && params[i] === routeEndpoint.routeParams[i]) {
              return true;
            }
          }
          return false;
        })
        .reduce((isAllowed, routeEndpoint) => {
          if (!isAllowed) return false;

          routeEndpoint.method.forEach((method) => {
            if (!isEndpointAllowed(method, routeEndpoint.path)) isAllowed = false;
          });

          return isAllowed;
        }, true as boolean)
    );
  }, [endpoints, route, params]);
  return isAllowed;
};

/** @deprecated Because AdminRouter is no longer used */
export const AdminRouteCondition: FC<{
  route: AdminRouteKey;
  params?: any[];
  fallback?: React.ReactNode;
}> = ({ route, params, children, fallback }) => {
  const isAllowed = useIsAdminRouteAllowed(route, ...(params ?? []));
  if (isAllowed) return <>{children}</>;
  return fallback ? <>{fallback}</> : null;
};

const AdminRouteLinkTmp: <Route extends AdminRouteKey>(
  props: IAdminProps<Route>,
  ref: ForwardedRef<HTMLAnchorElement>
) => ReactElement | null = ({ route, params, query = {}, keepQuery = false, showFallback = false, ...rest }, ref) => {
  const { search } = useLocation();

  const url = (AdminRoutes[route] as (...p: typeof params) => string)(...params);

  const linkSearch = useMemo(() => {
    const searchValue = keepQuery ? new URLSearchParams(search) : new URLSearchParams();
    for (const [key, value] of Object.entries(query)) {
      searchValue.set(key, typeof value === 'number' ? value.toString() : value);
    }
    return searchValue.toString();
  }, [JSON.stringify(query), keepQuery, search]);

  return (
    <AdminRouteCondition route={route} params={params} fallback={showFallback ? rest.children : void 0}>
      <Link ref={ref} to={`${url}?${linkSearch}`} {...rest} />
    </AdminRouteCondition>
  );
};

/** @deprecated Because AdminRouter is no longer used */
export const AdminRouteLink = forwardRef(AdminRouteLinkTmp);
