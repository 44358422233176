import React, { FC, useCallback, useContext, useState } from 'react';
import { TypographyBody1, TypographyH5 } from '../components/Typography/Typography';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PasswordInputController } from '../components/InputControllers/PasswordInputController';
import Divider from '@mui/material/Divider';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import { apiAuth, EMfaMethod } from '../resources/api/apiAuth';
import { NotificationContext } from '../contexts/NotificationContext';
import { PageHeader } from '../components/PageHeader/PageHeader';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useApiErrorHandler } from '../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { useEnumState } from '../hooks/useEnumState';
import { CodeInput } from '../components/CodeInput/CodeInput';
import { HiddenSubmit } from '../components/HiddenSubmit/HiddenSubmit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { ConfirmationDialog } from '../components/ConfirmationDialog/ConfirmationDialog';
import { MFAStatusFetcher } from '../fetchers/MfaStatusFetcher';
import Alert from '@mui/material/Alert';
import { useDialog } from '../hooks/useDialog';
import { Link } from 'react-router-dom';

interface IChangePasswordFields {
  old_password: string;
  new_password: string;
}

const { useRegister2fa, useVerify2faSetup, useDisable2fa, useChangePassword } = apiAuth;

const SecurityComponent: FC = () => {
  const { t } = useTranslation();
  const handleError = useApiErrorHandler();

  const passwordForm = useForm<IChangePasswordFields>();

  const [isSettingUpMfa, setSettingUpMfa, stopSettingUpMfa] = useEnumState(false, true, false);
  const [mfaCode, setMfaCode] = useState('');

  const { isOpen: isDisablingMfa, openUrl: disableMfaUrl, close: abortDisablingMfa } = useDialog('disable-mfa');

  const { data: mfaData, onRefresh: onMfaRefresh } = useContext(MFAStatusFetcher.Context);

  const { execute: disable2fa, Guard: GuardDisableMfa } = useDisable2fa();
  const { execute: verify2fa } = useVerify2faSetup();
  const { execute: register2fa } = useRegister2fa();
  const { execute: changePassword, Guard: GuardChangePassword } = useChangePassword();

  const { addNotification } = useContext(NotificationContext);

  const onSubmit: SubmitHandler<IChangePasswordFields> = useCallback(
    async (payload) => {
      try {
        await changePassword(payload);

        addNotification({
          message: t('security.password.successMessage'),
          severity: 'success',
        });
        passwordForm.reset();
      } catch (e) {
        handleError(e);
      }
    },
    [t, changePassword]
  );

  const onSetupMfa = useCallback(async () => {
    try {
      setSettingUpMfa();
      await register2fa(EMfaMethod.Email);
    } catch (e) {
      handleError(e);
    }
  }, [register2fa]);

  const onDisableMfa = useCallback(async () => {
    try {
      await disable2fa();
      await onMfaRefresh();
      abortDisablingMfa();
    } catch (e) {
      handleError(e);
    }
  }, [disable2fa]);

  const onMfaSubmit = useCallback(async () => {
    try {
      await verify2fa(EMfaMethod.Email, mfaCode);
      await onMfaRefresh();

      addNotification({
        severity: 'success',
        message: t('security.mfa.successMessage'),
      });
      setMfaCode('');
      stopSettingUpMfa();
    } catch (e) {
      handleError(e);
    }
  }, [mfaCode]);

  return (
    <div>
      <PageHeader title={t('security.title')} />

      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      <Grid container spacing={2}>
        <GuardChangePassword>
          <Grid item xs={12} lg={6}>
            <Card>
              <Box p={2}>
                <form onSubmit={passwordForm.handleSubmit(onSubmit)}>
                  <TypographyH5>{t('security.password.title')}</TypographyH5>

                  <PasswordInputController
                    control={passwordForm.control}
                    errors={passwordForm.formState.errors}
                    label={t('security.password.oldPassword')}
                    name="old_password"
                    rules={{ required: true }}
                    InputProps={{
                      autoComplete: 'current-password',
                    }}
                  />

                  <PasswordInputController
                    control={passwordForm.control}
                    errors={passwordForm.formState.errors}
                    label={t('security.password.newPassword')}
                    name="new_password"
                    rules={{ required: true }}
                  />

                  <Box mt={2}>
                    <Button variant="contained" type="submit">
                      {t('security.password.submit')}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Card>
          </Grid>
        </GuardChangePassword>

        {mfaData ? (
          <Grid item xs={12} lg={6}>
            <Card>
              <Box p={2}>
                <TypographyH5>{t('security.mfa.title')}</TypographyH5>

                {mfaData.mfa_enabled ? (
                  <Box mt={2}>
                    {mfaData.methods.some(({ method_name }) => method_name === EMfaMethod.Email) ? (
                      <Alert severity={'success'}>{t('security.mfa.email.enabledDescription')}</Alert>
                    ) : null}

                    <Box mt={2}>
                      <GuardDisableMfa>
                        <Button variant={'outlined'} component={Link} to={disableMfaUrl}>
                          {t('security.mfa.disable.title')}
                        </Button>
                        <ConfirmationDialog
                          open={isDisablingMfa}
                          title={t('security.mfa.disable.title')}
                          message={t('security.mfa.disable.message')}
                          confirm={onDisableMfa}
                          abort={abortDisablingMfa}
                          dangerous={true}
                        />
                      </GuardDisableMfa>
                    </Box>
                  </Box>
                ) : (
                  <Box mt={2}>
                    <Alert severity={'warning'}>{t('security.mfa.disabled.description')}</Alert>
                    <Box mt={2}>
                      <Button variant={'outlined'} onClick={onSetupMfa}>
                        {t('security.mfa.disabled.setupEmail2fa')}
                      </Button>
                    </Box>
                    <Dialog open={isSettingUpMfa}>
                      <DialogTitle>{t('security.mfa.email.enterCode')}</DialogTitle>
                      <DialogContent>
                        <TypographyBody1>{t('security.mfa.email.description')}</TypographyBody1>
                        <form onSubmit={onMfaSubmit}>
                          <CodeInput value={mfaCode} onChange={setMfaCode} pattern={'ddd-ddd'} />
                          <HiddenSubmit />
                        </form>
                      </DialogContent>
                      <DialogActions>
                        <Button variant={'outlined'} onClick={stopSettingUpMfa}>
                          {t('common.dialog.close')}
                        </Button>
                        <Button variant={'contained'} onClick={onMfaSubmit} disabled={mfaCode.length < 6}>
                          {t('security.mfa.email.submit')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        ) : null}
      </Grid>

      {/* @todo add mfa settings */}
    </div>
  );
};

export const SecurityPage: FC = () => {
  return (
    <MFAStatusFetcher.WAF>
      <SecurityComponent />
    </MFAStatusFetcher.WAF>
  );
};
