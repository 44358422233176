/**
 * This file contains quite rigid definition of hardcoded roles. This is far
 * from perfect, but is good enough for the first iteration of working with
 * identities.
 */

export enum EAdminRole {
  God = 'god',
  Admin = 'admin',
  Sales = 'sales',
}
export enum EOrganizationRole {
  Admin = 'organization_admin',
  Member = 'organization_member',
}
export enum EUserRole {
  User = 'user',
  Anonymous = 'anonymous',
}

type TRolesCollection<EGroup> = Array<{ role: EGroup; id: string }>;

export const adminRoles: TRolesCollection<EAdminRole> = [
  {
    role: EAdminRole.God,
    id: '48ba8a58-309a-44ce-b229-93c5ee16133e',
  },
  {
    role: EAdminRole.Admin,
    id: 'ef9029c4-ee83-49f9-bd74-c6361786482d',
  },
  {
    role: EAdminRole.Sales,
    id: '8e69f4a5-9d04-4d88-8461-ae95e5c6545e',
  },
];

export const organizationRoles: TRolesCollection<EOrganizationRole> = [
  {
    role: EOrganizationRole.Admin,
    id: '3cdfe90f-5baa-4c58-9969-64a71db8ae37',
  },
  {
    role: EOrganizationRole.Member,
    id: '77a43e74-c23e-4198-8a8b-e2d4d5dc2131',
  },
];

export const userRoles: TRolesCollection<EUserRole> = [
  {
    role: EUserRole.User,
    id: '23bc62e0-2bfa-411b-9e0c-3c943dfc2c5b',
  },
  {
    role: EUserRole.Anonymous,
    id: '6268e056-0b20-4f61-9f53-2f99a72099de',
  },
];
