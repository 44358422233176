import React, { FC, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { TextInputController } from '../InputControllers/TextInputController';
import { IProductReleaseApiData } from '../../resources/api/apiProductRelease';
import { SelectController } from '../InputControllers/SelectController';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { useTranslation } from 'react-i18next';

interface IProductReleaseVersionForm {
  version: string;
  emailOption: string;
  customEmails: string;
  marketingNotes: string;
}

interface IProps {
  alreadyReleased: string[];
  onSubmit: (fields: IProductReleaseVersionForm) => void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<IProductReleaseApiData>;
  formState: FormState<IProductReleaseVersionForm>;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
}

const sendEmailOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'allAdminsUsers',
    label: '[All Products] All customer admins and users',
  },
  {
    value: 'notArchived',
    label: '[All Products] Customer admins and users - not archived',
  },
  {
    value: 'activeLicense',
    label: '[All Products] Customer admins and users - active license',
  },
  {
    value: 'allAdminsUsersForProduct',
    label: '[This Product] All customer admins and users',
  },
  {
    value: 'notArchivedForProduct',
    label: '[This Product] Customer admins and users - not archived',
  },
  {
    value: 'activeLicenseForProduct',
    label: '[This Product] Customer admins and users - active license',
  },
  {
    value: 'custom',
    label: 'Custom list of emails',
  },
];

export const ProductReleaseVersionForm: FC<IProps> = ({
  alreadyReleased,
  onSubmit,
  control,
  handleSubmit,
  formState,
  watch,
  trigger,
}) => {
  const { t } = useTranslation();
  const emailOption = watch('emailOption');

  useEffect(() => {
    trigger('marketingNotes').then();
    trigger('customEmails').then();
  }, [emailOption]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInputController
            errors={formState.errors}
            label={t('releases.addDialog.version.versionLabel')}
            name="version"
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                if (!value.match(/^(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)(-[a-z0-9]+)?$/i)) {
                  const msg = t('releases.addDialog.version.versionInvalid');
                  return msg;
                }
                if (alreadyReleased.includes(value)) {
                  const msg = t('releases.addDialog.version.alreadyReleased', { version: value });
                  return msg;
                }
                return true;
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectController
            control={control}
            errors={formState.errors}
            items={sendEmailOptions}
            label={t('releases.addDialog.version.sendMarketingEmailLabel')}
            name="emailOption"
          />
        </Grid>
        <Grid item xs={12} style={{ display: emailOption !== 'custom' ? 'none' : void 0 }}>
          <TextInputController
            control={control}
            errors={formState.errors}
            label={t('releases.addDialog.version.customEmailsLabel')}
            rules={{
              required: emailOption === 'custom',
            }}
            name="customEmails"
          />
        </Grid>

        <Grid item xs={12} style={{ display: emailOption === 'none' ? 'none' : void 0 }}>
          <FormLabel>{t('releases.addDialog.version.marketingNotesLabel')}</FormLabel>
          <Controller
            name="marketingNotes"
            control={control}
            rules={{
              validate: (val: string) => {
                if (emailOption === 'none') return true;
                const dummy = document.createElement('div');
                dummy.innerHTML = val;
                if (dummy.innerText.trim() === '') return 'Text is required in marketing notes';
                return true;
              },
            }}
            render={({ field }) => (
              <RichTextEditor
                onChange={field.onChange}
                value={field.value}
                error={formState.errors['marketingNotes']?.message}
              />
            )}
          />
        </Grid>

        <input type="submit" style={{ display: 'none' }} />
      </Grid>
    </form>
  );
};
