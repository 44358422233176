// import { EResourceMethod, createResourceBuilder } from './resource';
//
import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const organizationResources = {
  listOrganizationUsers: createResourceBuilder(
    '/identities/organization/:organization_name/users',
    EResourceMethod.Get,
    (organization_name: string) => ({ organization_name })
  ),
  inviteUsers: createResourceBuilder(
    '/identities/organization/invite/:organization_name',
    EResourceMethod.Post,
    (organization_name: string) => ({ organization_name })
  ),
  acceptInvitation: createResourceBuilder(
    '/identities/organization/:organization_name/invitation/accept/:token',
    EResourceMethod.Post,
    (organization_name: string, token: string) => ({ organization_name, token })
  ),
  getInvitationDetails: createResourceBuilder(
    '/identities/organization/public/invitation/:token',
    EResourceMethod.Get,
    (token: string) => ({ token })
  ),
  acceptInvitationAndSignUp: createResourceBuilder(
    '/identities/organization/:organization_name/invitation/:token/signup',
    EResourceMethod.Post,
    (organization_name: string, token: string) => ({ organization_name, token })
  ),
  rejectInvitationAuthenticated: createResourceBuilder(
    '/identities/organization/:organization_name/invitation/:token',
    EResourceMethod.Delete,
    (organization_name: string, token: string) => ({ organization_name, token })
  ),
  getOrganizations: createResourceBuilder('/identities/organization', EResourceMethod.Get),
  createOrganization: createResourceBuilder('/identities/organization', EResourceMethod.Post),
  getOrganizationByName: createResourceBuilder(
    '/identities/organization/:organization_name',
    EResourceMethod.Get,
    (organization_name: string) => ({ organization_name })
  ),
  deleteOrganizationByName: createResourceBuilder(
    '/identities/organization/:organization_name',
    EResourceMethod.Delete,
    (organization_name: string) => ({ organization_name })
  ),
  updateOrganizationByName: createResourceBuilder(
    '/identities/organization/:organization_name',
    EResourceMethod.Patch,
    (organization_name: string) => ({ organization_name })
  ),
  assignUserToOrganization: createResourceBuilder(
    '/identities/organization/:organization_name/user/:user_id',
    EResourceMethod.Put,
    (organization_name: string, user_id: string) => ({ organization_name, user_id })
  ),
  getInvitations: createResourceBuilder(
    '/identities/organization/:organization_name/invitations',
    EResourceMethod.Get,
    (organization_name: string) => ({ organization_name })
  ),
  removeUser: createResourceBuilder(
    '/identities/organization/:organization_name/user/:user_id',
    EResourceMethod.Delete,
    (organization_name: string, user_id: string) => ({ organization_name, user_id })
  ),
  getUser: createResourceBuilder(
    '/identities/organization/:organization_name/members/:user_id',
    EResourceMethod.Get,
    (organization_name: string, user_id: string) => ({ organization_name, user_id })
  ),
} as const;
