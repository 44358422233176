import { createResourceBuilder } from '../resource';
import { EResourceMethod } from '../models/IPermission';

export const productPackageResources = {
  getPackages: createResourceBuilder('/admin/products/plans/packages', EResourceMethod.Get),
  createPackage: createResourceBuilder('/admin/products/plans/packages', EResourceMethod.Post),
  updatePackage: createResourceBuilder(
    '/admin/products/plans/packages/:package_id',
    EResourceMethod.Put,
    (package_id: number) => ({ package_id })
  ),
  deletePackage: createResourceBuilder(
    '/admin/products/plans/packages/:package_id',
    EResourceMethod.Delete,
    (package_id: number) => ({ package_id })
  ),
};
