import { logger } from './logger';

export interface IAccessTokenPayload {
  data: {
    '2fa_required'?: boolean;
  };
  email: string;
  exp: number;
  sub: string;
  user_id: string;
}

export const parseAccessToken = (token: string): IAccessTokenPayload => {
  try {
    const [, payload] = token.split('.');
    return JSON.parse(atob(payload));
  } catch (e) {
    logger.error(e);
    throw new Error("Couldn't parse access token");
  }
};
