import { createIdentitiesFetcher } from '../contexts/Fetcher';
import { apiUser } from '../resources/api/apiUser';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const { useGetMfaStatus } = apiUser;

export const MFAStatusFetcher = createIdentitiesFetcher(() => {
  const { identity } = useContext(AuthContext);
  return useGetMfaStatus(identity?.userId).execute;
});
