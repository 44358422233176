import { getUseResource } from '../resource';
import { organizationProductResources } from '../builders/organizationProduct';
import { IAdminCustomerProductsResponse } from '../../models/AdminCustomerProductsResponse';
import { ICustomerProductResponse } from '../../models/CustomerProductResponse';
import { ICustomerData } from '../../models/CustomerData';
import { ICustomerProduct } from '../../models/CustomerProduct';
import { ICustomerPackageDefaultProperties, IPlan } from '../../models/Plan';

export const apiOrganizationProduct = {
  useGetOrganizationProducts: getUseResource(
    organizationProductResources.GetOrganizationProducts,
    (body): IAdminCustomerProductsResponse => body
  ),
  useCreateOrganizationProduct: getUseResource(
    organizationProductResources.CreateOrganizationProduct,
    (body): ICustomerProduct[] => body,
    (
      params,
      productId: number,
      planId: number,
      amount: number,
      sendNotifications: boolean,
      properties: ICustomerPackageDefaultProperties
    ) => ({
      productId,
      planId,
      amount,
      sendNotifications,
      properties,
    })
  ),
  useGetOrganizationProduct: getUseResource(
    organizationProductResources.GetOrganizationProduct,
    (body): ICustomerProductResponse => body
  ),
  useRenewOrganizationProduct: getUseResource(
    organizationProductResources.RenewOrganizationProduct,
    (body): { customer: ICustomerData; customerProduct: ICustomerProduct; plan: IPlan } => body,
    (params, retroActivate: number, sendNotifications: boolean) => ({ retroActivate, sendNotifications })
  ),
  useUpdateOrganizationProduct: getUseResource(
    organizationProductResources.UpdateOrganizationProduct,
    (body): { customer: ICustomerData; customerProduct: ICustomerProduct; plan: IPlan } => body,
    (
      params,
      notification_reneval_270_days: boolean,
      notification_reneval_180_days: boolean,
      notification_reneval_90_days: boolean,
      notification_reneval_60_days: boolean,
      notification_reneval_30_days: boolean,
      notification_expiring_30_days: boolean,
      ignore_notification_until_reneval: boolean
    ) => ({
      notification_reneval_270_days,
      notification_reneval_180_days,
      notification_reneval_90_days,
      notification_reneval_60_days,
      notification_reneval_30_days,
      notification_expiring_30_days,
      ignore_notification_until_reneval,
    })
  ),
  useSuspendOrganizationProduct: getUseResource(
    organizationProductResources.SuspendOrganizationProduct,
    (body): void => body
  ),
  useReinstateOrganizationProduct: getUseResource(
    organizationProductResources.ReinstateOrganizationProduct,
    (body): void => body
  ),
  useReleaseOrganizationProduct: getUseResource(
    organizationProductResources.ReleaseOrganizationProduct,
    (body): void => body
  ),
  useRevokeOrganizationProduct: getUseResource(
    organizationProductResources.RevokeOrganizationProduct,
    (body): void => body
  ),
};
