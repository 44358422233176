import React, { FC, useCallback, useContext, useEffect } from 'react';
import { ChooseOrganizationModal } from '../components/ChooseOrganizationModal/ChooseOrganizationModal';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { IBasicOrganization } from '../resources/models/IOrganization';
import { useHistory } from 'react-router-dom';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';

export const ChooseOrganizationPage: FC = () => {
  const history = useHistory();
  const mainPage = AuthenticatedRoutes.Welcome();

  const { organization, setOrganization } = useContext(OrganizationContext);

  const logOut = useCallback(() => {
    // @todo
    console.log('should log out now');
  }, []);

  const onSubmit = useCallback(
    (organization: IBasicOrganization) => {
      console.log('set organization', organization);
      setOrganization(organization);
    },
    [setOrganization]
  );

  const noOrgAction = useCallback(() => {
    setOrganization(null);
    history.push(AuthenticatedRoutes.Welcome());
  }, [setOrganization]);

  useEffect(() => {
    if (organization) {
      history.push(mainPage);
    }
  }, [organization]);

  return (
    <ChooseOrganizationModal
      cancelAction={logOut}
      submitAction={onSubmit}
      cancelText={'Log out'}
      submitText={'Sign in'}
      noOrgText={'No organization'}
      noOrgAction={noOrgAction}
    />
  );
};
