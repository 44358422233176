import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { SelectTable } from '../SelectTable/SelectTable';
import { UserContext } from '../../contexts/UserContext';
import Button from '@mui/material/Button';
import { IBasicOrganization } from '../../resources/models/IOrganization';
import { EOrganizationRole, organizationRoles } from '../../const/roles';

interface IProps {
  cancelText: string;
  cancelAction: () => void;
  submitText: string;
  submitAction: (organization: IBasicOrganization) => void;
  noOrgText: string;
  noOrgAction: () => void;
  open?: boolean;
  noBackdrop?: boolean;
}

export const ChooseOrganizationModal: FC<IProps> = ({
  cancelText,
  cancelAction,
  submitText,
  submitAction,
  noOrgText,
  noOrgAction,
  open = true,
  noBackdrop = true,
}) => {
  const { userInfo } = useContext(UserContext);

  const [selectedRow, setSelectedRow] = useState<string | undefined>();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        format: (val: string) => val,
      },
      {
        id: 'role',
        name: 'Role',
        format: (val: string) => val,
      },
      {
        id: 'lastSignIn',
        name: 'Last Sign In',
        format: (val: string) => val,
      },
    ],
    []
  );

  const rows = useMemo(() => {
    const rolesPerOrg = (userInfo?.roles ?? []).reduce((out, role) => {
      if (role.organization_name) {
        if (!(role.organization_name in out)) {
          out[role.organization_name] = [];
        }

        const roleDef = organizationRoles.find(({ id }) => id === role.id);
        if (roleDef) {
          out[role.organization_name].push(roleDef.role);
        }
      }
      return out;
    }, {} as Record<string, string[]>);

    console.log(rolesPerOrg);
    return (userInfo?.organizations ?? []).map((organization) => ({
      id: organization.id,
      // @todo fill these placeholders
      cells: [
        organization.name,
        rolesPerOrg[organization.name]?.includes(EOrganizationRole.Admin)
          ? 'Admin'
          : rolesPerOrg[organization.name]?.includes(EOrganizationRole.Member)
          ? 'Member'
          : '-',
        '::placeholder::',
      ],
    }));
  }, [userInfo]);

  const onSubmit = useCallback(() => {
    console.log('on submit', userInfo);

    if (!userInfo) return null;
    console.log('selected row', selectedRow);
    const organization = userInfo?.organizations?.find((organization) => organization.id === selectedRow);
    if (!organization) return null;

    submitAction(organization);
  }, [selectedRow, submitAction, userInfo?.organizations]);

  console.log({ columns, rows });

  const buttons = useMemo(
    () => [
      <Button key="cancel" onClick={cancelAction}>
        {cancelText}
      </Button>,
      <Button key="noorg" variant="outlined" onClick={noOrgAction}>
        {noOrgText}
      </Button>,
      <Button key="submit" variant="contained" disabled={!selectedRow} onClick={onSubmit}>
        {submitText}
      </Button>,
    ],
    [selectedRow]
  );

  return (
    <AuthDialog
      title={'Choose organization'}
      actions={buttons}
      open={open}
      noBackdrop={noBackdrop}
      onClose={noBackdrop ? void 0 : cancelAction}
    >
      {rows.length > 0 ? (
        <SelectTable
          hasHeader={false}
          rows={rows}
          columns={columns}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          clearSelectedRow={() => setSelectedRow(void 0)}
        />
      ) : (
        <div>
          {/* @todo show nicer message*/}
          You have no organizations
        </div>
      )}
    </AuthDialog>
  );
};
