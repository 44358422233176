import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EditOrganizationForm, TEditOrganizationFormType } from './EditOrganizationForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { apiOrganization } from '../../resources/api/apiOrganization';
import { useHistory } from 'react-router-dom';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

const { useCreateOrganization } = apiOrganization;

export const AddOrganizationDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { execute: createOrganization } = useCreateOrganization();
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();
  const history = useHistory();

  const addOrganizationForm = useForm<TEditOrganizationFormType>({
    defaultValues: { same_billing_address: true },
  });

  useEffect(() => {
    if (!open) {
      addOrganizationForm.reset();
      addOrganizationForm.setValue('same_billing_address', true);
    }
  }, [open]);

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ same_billing_address, ...fields }: TEditOrganizationFormType) => {
      try {
        const newOrganization = await createOrganization({
          ...fields,
          pixotope_representative: fields.pixotope_representative || void 0,
          country: fields.country || void 0,
          notes: fields.notes || void 0,
        });
        history.push(AuthenticatedRoutes.OrganizationDetails(newOrganization.payload.name));
        closeDialog();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [createOrganization, closeDialog]
  );

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editOrganizationDialog.add.title')}</DialogTitle>
      <DialogContent>
        <EditOrganizationForm onSubmit={onSubmit} form={addOrganizationForm} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={addOrganizationForm.handleSubmit(onSubmit)}>
          {t('editOrganizationDialog.add.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
