import React, { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { GuestRoutes } from '../../routers/GuestRouter';
import { NotificationContext } from '../../contexts/NotificationContext';
import { FieldValues, useForm, UseFormWatch } from 'react-hook-form';
import { useAuthApi } from '../../hooks/useAuthApi';
import { useEnumState } from '../../hooks/useEnumState';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { PasswordInputController } from '../InputControllers/PasswordInputController';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const useWatchToRef = (watch: UseFormWatch<FieldValues>, field: string) => {
  const ref = useRef('');
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === field) ref.current = values[name];
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return ref;
};

export const AccountRecoveryUpdatePassword: FC = () => {
  const { addNotification } = useContext(NotificationContext);
  const apiErrorHandler = useApiErrorHandler();
  const form = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      password: '',
      password2: '',
    });
  }, []);

  const { accountRecoveryUpdatePassword } = useAuthApi();

  const [done, setDone] = useEnumState(false, true);
  const email = useQueryParam('email');
  const token = useQueryParam('token');
  const passwordRef = useWatchToRef(form.watch, 'password');

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (!email || !token) {
          throw new Error('missing params');
        }
        await accountRecoveryUpdatePassword({
          email,
          token,
          password: data.password,
          verifyPassword: data.password2,
        });
        addNotification({
          message: t('auth.recoveryUpdatePassword.success'),
          severity: 'success',
        });
        setDone();
      } catch (e) {
        apiErrorHandler(e, t('auth.recoveryUpdatePassword.failure'));
      }
    },
    [email, token]
  );

  return (
    <AuthDialog
      title={done ? t('auth.recoveryUpdatePassword.done') : t('auth.recoveryUpdatePassword.encouragement', { email })}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={
        done ? (
          <AuthGoBack to={routeWithQuery(GuestRoutes.SignIn, [], { email: email! })} />
        ) : (
          <Button variant="contained" color="primary" type="submit">
            {t('auth.recoveryUpdatePassword.submitButton')}
          </Button>
        )
      }
    >
      {done ? null : (
        <>
          <PasswordInputController
            key={'pw1'}
            control={form.control}
            errors={form.formState.errors}
            label={t('auth.inputs.newPassword.label')}
            name="password"
            rules={{ required: true }}
            InputProps={{
              inputProps: {
                tabIndex: 1,
                autoComplete: 'new-password',
              },
            }}
          />
          <PasswordInputController
            key={'pw2'}
            control={form.control}
            errors={form.formState.errors}
            label={t('auth.inputs.confirmPassword.label')}
            name="password2"
            rules={{
              required: true,
              validate: (value) => {
                const msg = t('auth.inputs.confirmPassword.validation.notMatch');
                return value === passwordRef.current || msg;
              },
            }}
            InputProps={{
              inputProps: {
                tabIndex: 2,
                autoComplete: 'new-password',
              },
            }}
          />
        </>
      )}
    </AuthDialog>
  );
};
