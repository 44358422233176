import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { defaultPermissionContext, PermissionContext } from './PermissionContext';
import { UserContext } from './UserContext';
import { apiPermission } from '../resources/api/apiPermission';
import { AuthContext } from './AuthContext';

const { useUserPermissions } = apiPermission;

const emptyArr: never[] = [];

export const PermissionContextProvider: FC = ({ children }) => {
  const { identity } = useContext(AuthContext);
  const { userInfo } = useContext(UserContext);

  const roles = userInfo?.roles ?? emptyArr;
  const [permissions, setPermissions] = useState(defaultPermissionContext.permissions);

  const userPermissionsResource = useUserPermissions(identity?.userId);

  useEffect(() => {
    if (userPermissionsResource.resource) {
      userPermissionsResource.execute().then((result) => {
        setPermissions(result.payload);
      });
    } else {
      setPermissions(defaultPermissionContext.permissions);
    }
  }, [userPermissionsResource, roles]);

  const contextValue = useMemo(
    () => ({
      roles,
      permissions,
    }),
    [roles, permissions]
  );

  return <PermissionContext.Provider value={contextValue}>{children}</PermissionContext.Provider>;
};
