import { LogLevel } from './types/logLevel';

type PixotopeHostname =
  | '127.0.0.1'
  | 'dev.pixotope.cloud'
  | 'dev.tfg.cloud'
  | 'development.pixotope.cloud'
  | 'localhost'
  | 'pixotope.cloud'
  | 'pixotope.tfg.cloud'
  | 'prod.pixotope.cloud'
  | 'prod.tfg.cloud'
  | 'production.pixotope.cloud'
  | 'staging.pixotope.cloud'
  | 'staging.tfg.cloud'
  | 'test';
const isPixotopeHostname = (hostname: string): hostname is PixotopeHostname =>
  [
    '127.0.0.1',
    'dev.pixotope.cloud',
    'dev.tfg.cloud',
    'development.pixotope.cloud',
    'localhost',
    'pixotope.cloud',
    'pixotope.tfg.cloud',
    'prod.pixotope.cloud',
    'prod.tfg.cloud',
    'production.pixotope.cloud',
    'staging.pixotope.cloud',
    'staging.tfg.cloud',
  ].includes(hostname);

interface IEnvironmentDependentConfig {
  apiUrl: string;
  verticalLogo: string;
  pixotopeLogo: string;
  cloudLogo: string;
  logLevel: LogLevel | -1;
  enableDeveloperTools: boolean;
  enableLive: boolean;
  enableInvoices: boolean;
}

const devConfig = {
  verticalLogo: '/images/brand/dev/vertical.svg',
  pixotopeLogo: '/images/brand/dev/pixotope.svg',
  cloudLogo: '/images/brand/dev/cloud.svg',
  logLevel: LogLevel.Debug,
  enableDeveloperTools: true,
  enableLive: true,
  enableInvoices: true,
};
const prodConfig = {
  verticalLogo: '/images/brand/prod/vertical.svg',
  pixotopeLogo: '/images/brand/prod/pixotope.svg',
  cloudLogo: '/images/brand/prod/cloud.svg',
  logLevel: LogLevel.Error,
  enableDeveloperTools: false,
  enableLive: false,
  enableInvoices: false,
};

const environmentDependentConfig: Record<PixotopeHostname, IEnvironmentDependentConfig> = {
  '127.0.0.1': {
    apiUrl: 'http://127.0.0.1:8090',
    ...devConfig,
  },
  'dev.pixotope.cloud': {
    apiUrl: 'https://dev-api.pixotope.cloud',
    ...devConfig,
  },
  'dev.tfg.cloud': {
    apiUrl: 'https://dev-api.pixotope.cloud',
    ...devConfig,
  },
  'development.pixotope.cloud': {
    apiUrl: 'https://development-api.pixotope.cloud',
    ...devConfig,
  },
  localhost: {
    apiUrl: 'https://staging-api.pixotope.cloud',
    ...devConfig,
  },
  'pixotope.cloud': {
    apiUrl: 'https://api.pixotope.cloud',
    ...prodConfig,
  },
  'pixotope.tfg.cloud': {
    apiUrl: 'https://prod-api.pixotope.cloud',
    ...prodConfig,
  },
  'prod.pixotope.cloud': {
    apiUrl: 'https://prod-api.pixotope.cloud',
    ...prodConfig,
  },
  'prod.tfg.cloud': {
    apiUrl: 'https://prod-api.pixotope.cloud',
    ...prodConfig,
  },
  'production.pixotope.cloud': {
    apiUrl: 'https://production-api.pixotope.cloud',
    ...prodConfig,
  },
  'staging.pixotope.cloud': {
    apiUrl: 'https://staging-api.pixotope.cloud',
    ...devConfig,
  },
  'staging.tfg.cloud': {
    apiUrl: 'https://staging-api.pixotope.cloud',
    ...devConfig,
  },
  test: {
    verticalLogo: '/images/brand/dev/vertical.svg',
    pixotopeLogo: '/images/brand/dev/pixotope.svg',
    cloudLogo: '/images/brand/dev/cloud.svg',
    apiUrl: 'http://api.localhost',
    logLevel: -1,
    enableDeveloperTools: true,
    enableLive: true,
    enableInvoices: true,
  },
};

const initializeConfig = (hostname: PixotopeHostname) => {
  return {
    defaultRowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    helpPage: 'https://help.pixotope.com/phc/',
    adminSupportTicketLink: (id: number) => `https://pixotope.zendesk.com/agent/tickets/${id}`,
    customerSupportTicketLink: (id: number) => `https://pixotope.zendesk.com/tickets/${id}`,
    supportNewTicketLink: 'https://support.pixotope.com/hc/en-us/requests/new',
    zenDeskAdminLink: 'https://support.pixotope.com',
    salesEmail: 'sales@pixotope.com',
    supportEmail: 'support@pixotope.com',
    expiresSoonDays: 7,
    sentryDsn: 'https://4b6f5f1e490c4ec3b66095aeb9232ebe@o1137587.ingest.sentry.io/6192658',
    ...environmentDependentConfig[hostname],
  };
};

const hostname = location.hostname.replace(/^www\./, '');
if (!isPixotopeHostname(hostname)) {
  throw new Error('unknown hostname');
}

const env = (typeof process === 'undefined' ? {} : process.env) as Record<string, string>;

export const config = initializeConfig(env?.JEST_WORKER_ID ? 'test' : hostname);
