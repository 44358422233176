import { createFetcher, createNoParamsFetcher } from '../contexts/Fetcher';
import { useCustomerProductsApi } from '../hooks/useCustomerProductsApi';
import { apiProduct } from '../resources/api/apiProduct';

const { useGetProducts } = apiProduct;

export const ProductsFetcher = createNoParamsFetcher(() => {
  const { execute } = useGetProducts();

  return execute;
});

export const CustomerProductsFetcher = createFetcher(() => useCustomerProductsApi().getProducts);
