import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { AccountType } from '../types/AccountType';

/**
 * @deprecated
 */
export const useAccount = (accountType?: AccountType | null) => {
  const authContext = useContext(AuthContext);

  if (typeof accountType !== 'undefined') {
    console.error('Deperacted. Please stop using useAccount with argument');
  }

  return {
    account: authContext.identity,
    setAccount: authContext.setIdentity,
  };
};
