import React, { FC, useCallback, useContext, useMemo } from 'react';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { createServerSidePaginatedFetcher } from '../../contexts/Fetcher';
import { GridColumns } from '@mui/x-data-grid';
import { PageHeader } from '../PageHeader/PageHeader';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { getStringContainsOperator } from '../../filterOperators/stringContainsOperator';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { GuardedRouteLink, useIsGuardedRouteAllowed } from '../../resources/GuardedRouteLink';
import { apiUser } from '../../resources/api/apiUser';
import { styled } from '@mui/material';
import { AuthenticatedRoutes } from '../../routers/AuthenticatedRoutes';

const { useGetUsers } = apiUser;

const AllUsersFetcher = createServerSidePaginatedFetcher(() => useGetUsers().execute);

const Status = createStatusChipComponent({
  unverified: ['common.accountStatus.unverified', 'orange'],
});

const MoreOrgs = styled('span')({
  marginLeft: '0.25rem',
});

const AllUsersContent: FC = () => {
  const { data } = useContext(AllUsersFetcher.Context);
  const { t } = useTranslation();

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.unverified'),
          {
            filterField: 'verified',
            filterOp: 'equals',
            filterVal: 'false',
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'first_name',
        headerName: t('common.tableHeader.firstName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
      },
      {
        field: 'last_name',
        headerName: t('common.tableHeader.lastName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator()],
        renderCell: (params) => {
          const { archived, verified } = params.row;

          if (!verified || archived) {
            const parts: string[] = [];
            if (!verified) parts.push(t('common.accountStatus.unverified'));
            if (archived) parts.push(t('common.accountStatus.archived'));
            parts.push(params.value);
            return (
              <Tooltip title={parts.join(' | ')}>
                <span>
                  {params.value} {verified === false ? <Status {...params} value={'unverified'} /> : null}
                </span>
              </Tooltip>
            );
          } else {
            return <CellContentTooltip>{params.value}</CellContentTooltip>;
          }
        },
      },
      {
        field: 'organizations',
        headerName: t('allUsers.table.organizationsHeader'),
        flex: 1,
        filterOperators: [getStringContainsOperator()],
        renderCell: (params) => {
          const defOrg = params.row.default_organization;
          const organizations = [...params.row.organizations];
          organizations.sort((a, b) => (a.id === defOrg ? 1 : 0) - (b.id === defOrg ? 1 : 0));

          if (organizations.length === 0) {
            return '-';
          }
          return (
            <>
              <Link
                component={GuardedRouteLink}
                route="OrganizationDetails"
                params={[organizations[0].name]}
                underline="hover"
                showFallback
              >
                {organizations[0].name}
              </Link>
              {organizations.length > 1 ? (
                <Tooltip title={organizations.map(({ name }) => name).join(', ')}>
                  <MoreOrgs>{t('allUsers.table.organizationsMore', { amount: organizations.length - 1 })}</MoreOrgs>
                </Tooltip>
              ) : null}
            </>
          );
        },
      },
      {
        field: 'created_at',
        headerName: t('common.tableHeader.created'),
        width: 130,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'updated_at',
        headerName: t('common.tableHeader.updated'),
        width: 130,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
    ],
    []
  );

  const getRowLink = useCallback((params) => {
    return AuthenticatedRoutes.User(params.row.id);
  }, []);

  const isGetRowLinkAllowed = useIsGuardedRouteAllowed('User', ['any']);

  if (!data) return null;
  return (
    <>
      <EasyFilter quickFilters={quickFilters} />
      <UrlQueryDataGrid
        columns={columns}
        rows={data.payload.data}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
        rowCount={data.payload.total_items}
        getRowClassName={(params) => (params.row.archived ? 'Mui-disabled' : '')}
      />
    </>
  );
};

export const AllUsers: FC = () => {
  const { t } = useTranslation();
  const preventRender = useDefaultSorting('created_at', 'desc');
  if (preventRender) return null;

  return (
    <AllUsersFetcher.WAF>
      <PageHeader title={t('allUsers.header')} />
      <AllUsersContent />
    </AllUsersFetcher.WAF>
  );
};
