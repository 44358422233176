import { getUseResource } from '../resource';
import { productResources } from '../builders/productResources';
import { IProductsResponse } from '../../models/ProductsResponse';
import { Single } from '../../types/single';

interface IProductPayload {
  name: string;
  plans: number[];
  installerPath: string;
  productCode?: string;
  docsPath: string;
}

export const apiProduct = {
  useGetProducts: getUseResource(productResources.getProducts, (body: IProductsResponse) => body),
  useCreateProduct: getUseResource(
    productResources.createProduct,
    (body): Single<IProductsResponse['products']> => body,
    (params, payload: IProductPayload) => payload
  ),
  useUpdateProduct: getUseResource(
    productResources.updateProduct,
    (body): Single<IProductsResponse['products']> => body,
    (params, payload: IProductPayload) => payload
  ),
  useDeleteProduct: getUseResource(productResources.deleteProduct, () => null),
};
