import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, Link as RouterLink, useLocation } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader/PageHeader';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { OrganizationProducts } from '../components/Customer/OrganizationProducts';
import { combineComponents } from '../contexts/combineComponents';
import { DateFormat } from '../helpers/dateFormat';
import { useApiErrorHandler } from '../hooks/useApiErrorHandler';
import { ConfirmationDialog } from '../components/ConfirmationDialog/ConfirmationDialog';
import { DangerousButton } from '../components/CustomButtons/DangerousButton';
import { OrganizationUsers } from '../components/Customer/OrganizationUsers';
import { CustomerTickets } from '../components/Customer/CustomerTickets';
import { useQueryUrl } from '../hooks/useQueryUrl';
import {
  CustomerAvailableProductsFetcher,
  OrganizationFetcher,
  CustomerTicketsFetcher,
  CustomerUsersFetcher,
  CustomerProductsFetcher,
} from '../components/Customer/CustomerFetchers';
import { useAutoFetch } from '../contexts/Fetcher';
import { countries } from '../helpers/countries';
import { CustomerLive } from '../components/Customer/CustomerLive';
import { Trans, useTranslation } from 'react-i18next';
import { Date } from '../components/Date/Date';
import { ConditionalTooltip } from '../components/ConditionalTooltip/ConditionalTooltip';
import { useIsEndpointAllowed } from '../components/RouteLink/RouteLink';
import { CustomersEndpoints } from '../hooks/api/endpoints/customers';
import { AccountSingleData } from '../components/AccountData/AccountSingleData';
import Box from '@mui/system/Box';
import { useDialog } from '../hooks/useDialog';
import { EditOrganizationDialog } from '../components/EditOrganizationDialog/EditOrganizationDialog';
import { AuthenticatedRoutes } from '../routers/AuthenticatedRoutes';
import { apiOrganization } from '../resources/api/apiOrganization';
import { useDecodedParams } from '../hooks/useDecodedParams';
import { useIsGuardedRouteAllowed } from '../resources/GuardedRouteLink';
import { useLazyUsers } from '../hooks/useLazyUsers';
import { matchPath } from 'react-router';
import { OrganizationInvites } from '../components/Customer/OrganizationInvites';
import { OrganizationInvitesFetcher } from '../fetchers/OrganizationInvitesFetcher';
import { IOrganizationContext, OrganizationContext } from '../contexts/OrganizationContext';

export type CustomerTab = 'products' | 'users' | 'invites' | 'tickets' | 'live';
export interface IOrganizationUrlParams {
  organization_name: string;
  tab?: CustomerTab;
}

const Providers = combineComponents(
  CustomerProductsFetcher.WMF,
  CustomerUsersFetcher.WMF,
  CustomerTicketsFetcher.WMF,
  CustomerAvailableProductsFetcher.WMF,
  OrganizationInvitesFetcher.WMF
);

const useStyles = makeStyles((theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  })
);

const { useDeleteOrganizationByName } = apiOrganization;

const tabs: Array<{ tab: CustomerTab; route: (organization_name?: string) => string }> = [
  {
    tab: 'products',
    route: AuthenticatedRoutes.OrganizationDetailsProducts,
  },
  {
    tab: 'users',
    route: AuthenticatedRoutes.OrganizationDetailsUsers,
  },
  {
    tab: 'tickets',
    route: AuthenticatedRoutes.OrganizationDetailsTickets,
  },
  {
    tab: 'live',
    route: AuthenticatedRoutes.OrganizationDetailsLive,
  },
  {
    tab: 'invites',
    route: AuthenticatedRoutes.OrganizationDetailsInvites,
  },
];

const OrganizationContent: FC = () => {
  const styles = useStyles();
  const { data /*, onDataUpdate*/ } = useContext(OrganizationFetcher.Context);

  /** @todo this might be needed to check if organization can be safely deleted */
  // const { data: productsData } = useContext(CustomerProductsFetcher.Context);

  const { pathname } = useLocation();

  const { organization_name } = useDecodedParams<IOrganizationUrlParams>();

  const tab = tabs.find((tab) => matchPath(pathname, tab.route()))?.tab;
  console.log('CURRENT TAB', tab);
  const history = useHistory();
  // const { reInvite, deleteCustomer, archiveCustomer, unArchiveCustomer, reassignZendeskId } = useCustomersApi();
  // const { addNotification } = useContext(NotificationContext);
  const handleApiError = useApiErrorHandler();

  const { execute: deleteOrganization, Guard: GuardDeleteOrganization } =
    useDeleteOrganizationByName(organization_name);

  const { isOpen: isEdit /*, openUrl: editUrl*/, close: closeDialog } = useDialog('edit');
  const { isOpen: isDelete /*, openUrl: deleteUrl*/ } = useDialog('delete');
  const { isOpen: isArchive /*, openUrl: archiveUrl*/ } = useDialog('archive');

  const { t } = useTranslation();
  const isEndpointAllowed = useIsEndpointAllowed();

  const editDialogUrl = useQueryUrl('dialog', 'edit');
  const deleteDialogUrl = useQueryUrl('dialog', 'delete');
  const archiveDialogUrl = useQueryUrl('dialog', 'archive');

  const isProductsAllowed = useIsGuardedRouteAllowed('OrganizationDetailsProducts', [organization_name]);
  const isUsersAllowed = useIsGuardedRouteAllowed('OrganizationDetailsUsers', [organization_name]);
  const isInvitesAllowed = useIsGuardedRouteAllowed('OrganizationDetailsInvites', [organization_name]);
  const isTicketsAllowed = useIsGuardedRouteAllowed('OrganizationDetailsTickets', [organization_name]);
  const isLiveAllowed = useIsGuardedRouteAllowed('OrganizationDetailsLive', [organization_name]);

  const setActiveTab = useCallback((tab: CustomerTab, replaceState = false) => {
    const route = tabs.find((tabDetails) => tabDetails.tab === tab)?.route;

    if (route) {
      const url = route(organization_name);
      history[replaceState ? 'replace' : 'push'](url);
    }
  }, []);

  const onTabChange = useCallback(
    (_, tab) => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );

  const lazyUsers = useMemo(
    () => [
      ...(data?.payload.owner_id ? [data.payload.owner_id] : []),
      ...(data?.payload.pixotope_representative ? [data.payload.pixotope_representative] : []),
    ],
    [data?.payload.owner_id, data?.payload.pixotope_representative]
  );
  const { getUserData } = useLazyUsers(lazyUsers);

  useAutoFetch(OrganizationFetcher, void 0, data?.payload.name !== organization_name);

  useEffect(() => {
    if (!tab) {
      /** @todo change to "products" once it works */
      setActiveTab('users', true);
    }
  }, [tab]);

  /** @todo verify if that's still needed */
  // const onReassignZendeskId = useCallback(async () => {
  //   if (!data) return;
  //   /** @todo make that work */
  //   // try {
  //   //   const updatedData = await reassignZendeskId([id]);
  //   //   onDataUpdate({ ...data, customer: updatedData });
  //   //   addNotification({
  //   //     severity: 'success',
  //   //     message: t('organization.reassignZendeskIdSucceeded'),
  //   //   });
  //   // } catch (e) {
  //   //   handleApiError(e);
  //   // }
  // }, []);

  const onDelete = useCallback(async () => {
    try {
      await deleteOrganization();
      history.push(AuthenticatedRoutes.Organizations());
    } catch (e) {
      handleApiError(e);
    }
  }, []);

  const onArchive = useCallback(async () => {
    if (!data) return;
    try {
      /** @todo make it work */
      // const newCustomerData = await archiveCustomer([id]);
      // onDataUpdate({ ...data, customer: newCustomerData });
      // closeDialog();
    } catch (e) {
      handleApiError(e);
    }
  }, []);

  const onUnArchive = useCallback(async () => {
    if (!data) return;
    try {
      /** @todo make it work */
      // const newCustomerData = await unArchiveCustomer([id]);
      // onDataUpdate({ ...data, customer: newCustomerData });
      // closeDialog();
    } catch (e) {
      handleApiError(e);
    }
  }, []);

  const orgCtxProv = useMemo<IOrganizationContext>(
    () => ({
      organization: data?.payload ?? null,
      organizationId: data?.payload.id ?? null,
      setOrganization: () => {
        throw new Error('cannot be performed in this context');
      },
    }),
    [data]
  );

  if (!data) return null;
  return (
    <div>
      <PageHeader
        title={data.payload.name}
        crumbs={[
          {
            url: AuthenticatedRoutes.Organizations(),
            title: t('layout.adminNavigation.organizations'),
          },
        ]}
      >
        <div className={styles.buttons}>
          {/** @todo consider bringing back this button */}
          {/*<Button*/}
          {/*  component={AdminRouteLink}*/}
          {/*  route="Events"*/}
          {/*  params={[]}*/}
          {/*  query={{ filterField: 'accountId', filterOp: 'equals', filterVal: id }}*/}
          {/*  variant="outlined"*/}
          {/*  color="secondary"*/}
          {/*>*/}
          {/*  {t('common.button.history')}*/}
          {/*</Button>*/}

          {isEndpointAllowed('PUT', CustomersEndpoints.CustomerArchive.template) ? (
            <Button component={RouterLink} to={archiveDialogUrl} variant="outlined">
              {data.payload.archived ? t('common.button.unArchive') : t('common.button.archive')}
            </Button>
          ) : null}

          {isEndpointAllowed('PUT', CustomersEndpoints.Customer.template) ? (
            <Button component={RouterLink} to={editDialogUrl} variant="contained" color="primary">
              {t('common.button.edit')}
            </Button>
          ) : null}

          <GuardDeleteOrganization>
            <ConditionalTooltip
              // @todo show when products are assigned
              enabled={false /*productsData.customerProducts.length > 0*/}
              title={t('organization.cannotDelete') as string}
            >
              <span>
                <DangerousButton
                  component={RouterLink}
                  to={deleteDialogUrl}
                  variant="contained"
                  // @todo disable when products are assigned
                  disabled={false /*productsData.customerProducts.length > 0*/}
                >
                  {t('common.button.delete')}
                </DangerousButton>
              </span>
            </ConditionalTooltip>
          </GuardDeleteOrganization>
          {/*) : null}*/}
        </div>
      </PageHeader>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.id')}>{data.payload.id}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.organization')}>{data.payload.name}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.domain')}>-- not implemented --</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.owner')}>
            {data.payload.owner_id ? (
              `${getUserData(data.payload.owner_id)?.first_name} ${getUserData(data.payload.owner_id)?.last_name}`
            ) : (
              <Button>Invite owner (not implemented)</Button>
            )}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.pixotopeRepresentative')}>
            {/** @todo add link */}
            {data.payload.pixotope_representative
              ? `${getUserData(data.payload.pixotope_representative)?.first_name} ${
                  getUserData(data.payload.pixotope_representative)?.last_name
                }`
              : 'none'}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.address')}>{data.payload.address}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.billingAddress')}>{data.payload.billing_address}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.country')}>
            {data.payload.country} {countries.find((country) => country.code === data.payload.country)?.name}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.lastUpdated')}>
            <Date date={data.payload.updated_at} format={DateFormat.DateWithFullTime} />
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.zenDesk')}>
            {data.payload.zendesk_id}
            {/*{isEndpointAllowed('POST', CustomersEndpoints.ReassignZendeskId.template) ? (*/}
            {/*  <Button onClick={onReassignZendeskId} variant="contained" color="secondary" size="small">*/}
            {/*    {t('organization.reassignZendeskId')}*/}
            {/*  </Button>*/}
            {/*) : null}*/}
          </AccountSingleData>
        </Grid>

        {/*<Grid item xs={6} md={4} lg={3}>*/}
        {/*  <AccountSingleData label={t('common.tableHeader.status')}>*/}
        {/*    {data.customer.verified ? (*/}
        {/*      <Chip label={t('common.accountStatus.verified')} color="primary" />*/}
        {/*    ) : isEndpointAllowed('POST', CustomersEndpoints.CustomerReInvite.template) ? (*/}
        {/*      <Button onClick={onReInvite} variant="contained" color="secondary">*/}
        {/*        {t('common.accountStatus.unverified')} / {t('organization.reInvite')}*/}
        {/*      </Button>*/}
        {/*    ) : (*/}
        {/*      <Chip label={t('common.accountStatus.unverified')} color="secondary" />*/}
        {/*    )}*/}
        {/*  </AccountSingleData>*/}
        {/*</Grid>*/}

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('organization.notes')}>{data.payload.notes}</AccountSingleData>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {isProductsAllowed ? <Tab label={t('organization.products.tabTitle')} value="products" /> : null}
          {isUsersAllowed ? <Tab label={t('organization.users.tabTitle')} value="users" /> : null}
          {isInvitesAllowed ? <Tab label={t('organization.invites.tabTitle')} value="invites" /> : null}
          {isTicketsAllowed ? <Tab label={t('organization.tickets.tabTitle')} value="tickets" /> : null}
          {isLiveAllowed ? <Tab label={t('organization.live.tabTitle')} value="live" /> : null}
        </Tabs>
      </Box>

      <OrganizationContext.Provider value={orgCtxProv}>
        <Providers>
          {tab === 'products' ? <OrganizationProducts /> : null}
          {tab === 'users' ? <OrganizationUsers /> : null}
          {tab === 'tickets' ? <CustomerTickets /> : null}
          {tab === 'live' ? <CustomerLive /> : null}
          {tab === 'invites' ? <OrganizationInvites /> : null}
        </Providers>
      </OrganizationContext.Provider>

      <ConfirmationDialog
        open={isDelete}
        title={t('organization.deleteDialog.title')}
        message={t('organization.deleteDialog.message', data.payload.name)}
        confirm={onDelete}
        abort={closeDialog}
        dangerous
        confirmLabel={t('common.button.delete')}
      />
      <EditOrganizationDialog open={isEdit} closeDialog={closeDialog} />
      <ConfirmationDialog
        open={isArchive}
        title={
          data.payload.archived
            ? t('organization.archiveDialog.titleUnArchive')
            : t('organization.archiveDialog.titleArchive')
        }
        confirm={data.payload.archived ? onUnArchive : onArchive}
        abort={closeDialog}
        confirmLabel={
          data.payload.archived
            ? t('organization.archiveDialog.confirmUnArchive')
            : t('organization.archiveDialog.confirmArchive')
        }
      >
        {data.payload.archived ? (
          <Trans i18nKey="customer.archiveDialog.contentUnArchive">
            <Typography paragraph />
            <List>
              <ListItem />
              <ListItem />
              <ListItem />
            </List>
            <Typography paragraph />
          </Trans>
        ) : (
          <Trans i18nKey="customer.archiveDialog.contentArchive">
            <Typography paragraph />
            <List>
              <ListItem />
              <ListItem />
            </List>
            <Typography paragraph />
          </Trans>
        )}
      </ConfirmationDialog>
    </div>
  );
};

export const OrganizationPage: FC = () => {
  return (
    <OrganizationFetcher.WAF>
      <OrganizationContent />
    </OrganizationFetcher.WAF>
    // <CustomerProductsFetcher.WAF>
    // </CustomerProductsFetcher.WAF>
  );
};
