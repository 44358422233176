import React, { FC, useCallback, useContext, useMemo } from 'react';
import { IApiConfig, IApiExecutor, useApi } from '../hooks/useApi';
import { PermissionContext } from '../contexts/PermissionContext';
import { EResourceMethod } from './models/IPermission';
import { useMemoWithEqualityCheck } from '../hooks/useMemoWithEqualityCheck';
import { EPolicyCondition, EPolicyOperator, IPolicy } from './models/IPolicy';
import { IRole } from './models/IRole';
import { IBasicOrganization } from './models/IOrganization';
import { IBasicUserInfo } from './models/IUserInfo';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { UserContext } from '../contexts/UserContext';

type Obj = Record<string, any>;

export type TPayloadMethod = EResourceMethod.Post | EResourceMethod.Put;

export interface IResource<ParamsType extends Obj, MethodType extends EResourceMethod> {
  template: string;
  method: MethodType;
  params: ParamsType;
}

export interface IResourceData<
  ResourceType extends IResource<any, any>,
  ResponseType,
  InputPayloadType extends any[] = never,
  PayloadType = never
> {
  preparePayload: ResourceType extends IResource<any, TPayloadMethod>
    ? (...input: InputPayloadType) => PayloadType
    : never;
  prepareResponse: (body: any) => ResponseType;
}

interface IResourceGuardProps {
  resource: IResource<any, EResourceMethod> | null;
}

export interface IResourceBuilder<
  InputParamsType extends any[],
  ParamsType extends Obj,
  MethodType extends EResourceMethod
> {
  (...params: InputParamsType): IResource<ParamsType, MethodType>;
}

export const createResourceBuilder = <
  MethodType extends EResourceMethod,
  ParamsType extends Obj = Record<string, never>,
  InputParamsType extends any[] = []
>(
  template: string,
  method: MethodType,
  paramsFn: (...input: InputParamsType) => ParamsType = () => ({} as ParamsType)
): IResourceBuilder<InputParamsType, ParamsType, MethodType> => {
  return (...params: InputParamsType) => ({
    template,
    method,
    params: paramsFn(...params),
  });
};

class ResourcePolicy {
  constructor(private json: IPolicy) {}

  public examine(
    resource: IResource<any, EResourceMethod>,
    roles: IRole[],
    currentOrganization: IBasicOrganization | null,
    self: IBasicUserInfo | null
  ) {
    switch (this.json.condition) {
      case EPolicyCondition.AlwaysAllow:
        return true;

      case EPolicyCondition.RoleCheck:
      case EPolicyCondition.Authenticated:
        return (this.json.member_of_roles || []).every((role_id) => roles.find(({ id }) => id === role_id));

      case EPolicyCondition.OrganizationOwner:
      case EPolicyCondition.OrganizationMember: {
        let organizationMatcher: null | ((organization: IBasicOrganization) => boolean) = null;

        if (this.json.function_parameters && 'organization_id' in this.json.function_parameters) {
          const resourceOrganizationId =
            resource.params[this.json.function_parameters.organization_id.key.replace(/^:/, '')];

          if (!resourceOrganizationId) {
            throw new Error('Missing organization identifier in resource params');
          }

          organizationMatcher = (organization: IBasicOrganization) => organization.id === resourceOrganizationId;
        } else if (this.json.function_parameters && 'organization_name' in this.json.function_parameters) {
          const resourceOrganizationName =
            resource.params[this.json.function_parameters.organization_name.key.replace(/^:/, '')];

          if (!resourceOrganizationName) {
            throw new Error('Missing organization name in resource params');
          }

          organizationMatcher = (organization: IBasicOrganization) => organization.name === resourceOrganizationName;
        }

        if (organizationMatcher === null) {
          throw new Error('Missing organization_id/organization_name in policy definition');
        }

        return (this.json.member_of_roles || []).every((role_id) =>
          roles.find((role) => {
            const roleOrganization = currentOrganization?.name === role.organization_name ? currentOrganization : null;

            return role.id === role_id && roleOrganization && organizationMatcher!(roleOrganization);
            // roleOrganization?.id === resourceOrganizationId;
          })
        );
      }

      case EPolicyCondition.IsSelf: {
        if (!this.json.function_parameters || !('user_id' in this.json.function_parameters)) {
          throw new Error('Missing user_id in policy definition');
        }

        const resourceUserId = resource.params[this.json.function_parameters.user_id.key.replace(/^:/, '')];

        if (!resourceUserId) {
          throw new Error('Missing user identifier in resource params');
        }

        const rolesChecked = (this.json.member_of_roles || []).every((role_id) =>
          roles.find((role) => role.id === role_id)
        );

        return resourceUserId === self?.id && rolesChecked;
      }
    }
  }

  public static examineCollection(
    policies: ResourcePolicy[],
    resource: IResource<any, EResourceMethod>,
    roles: IRole[],
    organization: IBasicOrganization | null,
    self: IBasicUserInfo | null
  ) {
    let currentOperator = policies[0].json.operator;
    let result = currentOperator === EPolicyOperator.And;

    for (const policy of policies) {
      const policyResult = policy.examine(resource, roles, organization, self);
      if (currentOperator === EPolicyOperator.Or) {
        result ||= policyResult;
      } else {
        result &&= policyResult;
      }
      currentOperator = policy.json.operator;
    }

    return result;
  }
}

export const useResourceChecker = () => {
  const { roles, permissions } = useContext(PermissionContext);
  const { organization } = useContext(OrganizationContext);
  const { userInfo } = useContext(UserContext);

  return useCallback(
    (resource: IResource<any, EResourceMethod> | null) => {
      if (!resource) {
        return false;
      }
      const permission = permissions[resource.method].find((permission) => {
        if (permission.path.includes('*')) {
          const regExp = new RegExp(permission.path.replaceAll('*', '.*'));
          return resource.template.match(regExp);
        }
        return permission.path === resource.template;
      });
      if (!permission) {
        return false;
      }

      const policies = permission.policies.map((policy) => new ResourcePolicy(policy));

      return ResourcePolicy.examineCollection(policies, resource, roles, organization, userInfo);
    },
    [roles, permissions, organization, userInfo]
  );
};

export const useIsResourceAllowed = (resource: IResource<any, EResourceMethod>) => {
  const checker = useResourceChecker();
  return checker(resource);
};

export const ResourceDenied: FC = ({ children }) => {
  return <>{children}</>;
};

export const ResourceGuard: FC<IResourceGuardProps> = ({ resource, children }) => {
  const childrenIfAllowed = useMemo(() => {
    if (Array.isArray(children)) {
      return children.filter((child) => child.type !== ResourceDenied);
    } else {
      return children;
    }
  }, [children]);

  const childrenIfDenied = useMemo(() => {
    if (Array.isArray(children)) {
      return children.filter((child) => child.type === ResourceDenied);
    } else {
      return null;
    }
  }, [children]);

  return <>{resource && useIsResourceAllowed(resource) ? childrenIfAllowed : childrenIfDenied}</>;
};

export const resolveResourceUrl = (resource: IResource<any, EResourceMethod>) => {
  return Object.entries(resource.params).reduce(
    (url, [key, value]: [string, any]) => url.replace(`:${key}`, encodeURIComponent(value)),
    resource.template
  );
};

// type TExecutorArgs<MethodType extends EResourceMethod, PayloadType> = MethodType extends TPayloadMethod
//   ? [PayloadType]
//   : never;

export interface IExecutor<PayloadType, ResponseType> {
  (payload: PayloadType): Promise<ResponseType>;
}

// interface IExecutor<MethodType extends EResourceMethod, PayloadType, ResponseType> {
//   (...payload: TExecutorArgs<MethodType, PayloadType>): Promise<ResponseType>;
// }

type UseExecutorType<ParamsType extends Obj, ResponseType, PayloadType, MethodType extends EResourceMethod> = (
  resource: IResource<ParamsType, MethodType> | null,
  apiExecutor: IApiExecutor
) => IExecutor<PayloadType, ResponseType>;
//
// type GetExecutorType = <ParamsType extends Obj, ResponseType, PayloadType, MethodType extends EResourceMethod>(
//   resource: IResource<ParamsType, MethodType>,
//   apiExecutor: IApiExecutor
// ) => IExecutor<PayloadType, ResponseType>;

// const defaultResourceExecutor = <ParamsType extends Obj, ResponseType, PayloadType, MethodType extends EResourceMethod>(
//   resource: IResource<ParamsType, MethodType>,
//   apiExecutor: IApiExecutor
// ): IExecutor<PayloadType, ResponseType> => {
const defaultUseResourceExecutor = <
  ParamsType extends Obj,
  ResponseType,
  PayloadType,
  MethodType extends EResourceMethod
>(
  resource: IResource<ParamsType, MethodType> | null,
  apiExecutor: IApiExecutor
): IExecutor<PayloadType, ResponseType> => {
  const resourceUrl = resource ? resolveResourceUrl(resource) : '';

  return useCallback(
    async (payload: PayloadType) => {
      if (!resource) {
        throw new Error('resource is missing');
      }
      if (resource.method === EResourceMethod.Get) {
        let search: null | URLSearchParams = null;
        if (payload) {
          search = new URLSearchParams();
          Object.entries(payload).forEach(([key, value]) => {
            if (typeof value !== 'undefined') {
              search!.set(key, value.toString());
            }
          });
        }
        const response = await apiExecutor.get<ResponseType>(resourceUrl, {
          params: search,
        });
        return response.data;
      } else if (resource.method === EResourceMethod.Post) {
        const response = await apiExecutor.post<ResponseType>(resourceUrl, payload);
        return response.data;
      } else if (resource.method === EResourceMethod.Delete) {
        const response = await apiExecutor.del<ResponseType>(resourceUrl);
        return response.data;
      } else if (resource.method === EResourceMethod.Put) {
        const response = await apiExecutor.put<ResponseType>(resourceUrl, payload);
        return response.data;
      } else if (resource.method === EResourceMethod.Patch) {
        const response = await apiExecutor.patch<ResponseType>(resourceUrl, payload);
        return response.data;
      } else {
        throw new Error('Unknown resource method');
      }
    },
    [resourceUrl, resource, apiExecutor]
  );
};

const defaultsForUseResource = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  preparePayload: (...params: any) => ({}),
  useExecutor: defaultUseResourceExecutor,
  useApi,
  apiParams: { addAccountTypePrefix: false },
};

// const defaultApiParams =

const isEqual = (oldParams: any[], newParams: any[]) => {
  return oldParams.length === newParams.length && oldParams.every((val, index) => val === newParams[index]);
};

type TupleOptional<Type extends any[]> = {
  [K in keyof Type]: Type[K] | undefined;
};

export const getUseResource = <
  InputParamsType extends any[],
  ParamsType extends Obj,
  ResponseType extends any,
  MethodType extends EResourceMethod,
  InputPayloadType extends any[] = [],
  PayloadType = never
>(
  resourceBuilder: IResourceBuilder<InputParamsType, ParamsType, MethodType>,
  prepareResponse: (body: any) => ResponseType,
  preparePayload = defaultsForUseResource.preparePayload as (
    params: ParamsType,
    ...input: InputPayloadType
  ) => PayloadType,
  useExecutor = defaultsForUseResource.useExecutor as UseExecutorType<
    ParamsType,
    ResponseType,
    PayloadType,
    MethodType
  >,
  useApi = defaultsForUseResource.useApi,
  apiParams: IApiConfig = defaultsForUseResource.apiParams
) => {
  return (...params: TupleOptional<InputParamsType>) => {
    const paramsMemo = useMemoWithEqualityCheck(params, isEqual);

    const resource = useMemo(() => {
      if (paramsMemo.some((param) => typeof param === 'undefined')) {
        return null;
      }
      return resourceBuilder(...(paramsMemo as InputParamsType));
    }, [resourceBuilder, paramsMemo]);
    const api = useApi(apiParams);

    const executor = useExecutor(resource, api);

    const execute = useCallback(
      (...payload: InputPayloadType): Promise<ResponseType> => {
        if (!resource) {
          throw new Error('resource params missing');
        }
        const preparedPayload = preparePayload(resource.params, ...payload);
        return executor(preparedPayload).then(prepareResponse);
      },
      [preparePayload, executor]
    );

    const Guard = useMemo<FC>(() => {
      return ({ children }) => <ResourceGuard resource={resource}>{children}</ResourceGuard>;
    }, [resource]);

    const out = useMemo(
      () => ({
        resource,
        execute,
        Guard,
      }),
      [resource, execute, Guard]
    );

    return out;
  };
};
//
// export const useResource = <
//   InputParamsType extends any[],
//   ParamsType extends Obj,
//   ResponseType extends any,
//   MethodType extends EResourceMethod,
//   InputPayloadType extends any[] = [],
//   PayloadType = never
// >(
//   resourceBuilder: IResourceBuilder<InputParamsType, ParamsType, MethodType>,
//   prepareResponse: (body: any) => ResponseType,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   // preparePayload = (...input: InputPayloadType) => ({} as PayloadType),
//   preparePayload = defaultsForUseResource.preparePayload as (...input: InputPayloadType) => PayloadType,
//   getExecutor = defaultsForUseResource.getExecutor,
//   useApi = defaultsForUseResource.useApi,
//   apiParams = defaultsForUseResource.apiParams
//   // data: IResourceData<IResource<ParamsType, MethodType>, ResponseType, InputPayloadType, PayloadType>,
//   // executor: IExecutor<MethodType, PayloadType, ResponseType>
// ) => {
//   const api = useApi(apiParams);
//
//   console.log('#1 useResource: mount', []);
//   console.log('#2 useResource: resourceBuilder changed', [resourceBuilder]);
//   console.log('#3 useResource: prepareResponse changed', [prepareResponse]);
//   console.log('#4 useResource: preparePayload changed', [preparePayload]);
//   console.log('#5 useResource: getExecutor changed', [getExecutor]);
//   console.log('#6 useResource: useApi changed', [useApi]);
//   console.log('#7 useResource: apiParams changed', [apiParams]);
//   console.log('#8 useResource: api changed', [api]);
//
//   const useBuiltResource = useCallback(
//     (...params: InputParamsType) => {
//       const resource = useMemo(() => resourceBuilder(...params), [resourceBuilder, params]);
//
//       const executor = useMemo(
//         () => getExecutor<ParamsType, ResponseType, PayloadType, typeof resource.method>(resource, api),
//         [getExecutor, resource, api]
//       );
//
//       const execute = useCallback(
//         (...payload: InputPayloadType): Promise<any> => {
//           const preparedPayload = preparePayload(...payload);
//           return executor(preparedPayload).then(prepareResponse);
//         },
//         [preparePayload, executor]
//       );
//
//       const Guard = useMemo<FC>(() => {
//         return ({ children }) => <ResourceGuard resource={resource}>{children}</ResourceGuard>;
//       }, [resource]);
//
//       return {
//         resource,
//         execute,
//         Guard,
//       };
//     },
//     [resourceBuilder, prepareResponse, preparePayload, getExecutor, useApi, apiParams, api]
//   );
//
//   return useBuiltResource;
// };

// export const useResourceExecutor = <ParamsType, ResponseType, PayloadType, MethodType extends EResourceMethod>(
//   resource: IResource<ParamsType, MethodType>,
//   getExecutor = defaultResourceExecutor,
//   getApi = useApi,
//   apiParams = { addAccountTypePrefix: false }
// ) => {
//   const api = getApi(apiParams);
//   return getExecutor<ParamsType, ResponseType, PayloadType, MethodType>(resource, api);
// };
//
// export const useResourceWithExecutor = <
//   InputParamsType extends any[],
//   ParamsType extends Obj,
//   MethodType extends EResourceMethod,
//   ResponseType,
//   PayloadType
// >(
//   resourceBuilder: IResourceBuilder<InputParamsType, ParamsType, MethodType>,
//   getExecutor = defaultResourceExecutor,
//   getApi = useApi,
//   apiParams = { addAccountTypePrefix: false }
// ) => {
//   const resourceFn = useResource(resourceBuilder);
//
//   return (...params: InputParamsType) => {
//     const { resource, Guard } = resourceFn(...params);
//     return {
//       resource,
//       Guard,
//       ...useResourceExecutor<ParamsType, ResponseType, PayloadType, MethodType>(
//         resource,
//         getExecutor,
//         getApi,
//         apiParams
//       ),
//     };
//   };
// };
