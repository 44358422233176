import { createFetcher } from '../contexts/Fetcher';
import { apiProductPlan } from '../resources/api/apiProductPlan';
import { useIntParams } from '../hooks/useIntParams';

const { useGetPlan } = apiProductPlan;

export const ProductPlanFetcher = createFetcher(() => {
  const { id } = useIntParams<{ id?: string }>();
  const { execute: getPlan } = useGetPlan(id);

  return getPlan;
});
