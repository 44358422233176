import React, { FC, useContext, useMemo } from 'react';
import { OrganizationInvitesFetcher } from '../../fetchers/OrganizationInvitesFetcher';
import { useAutoFetch } from '../../contexts/Fetcher';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns } from '@mui/x-data-grid';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { useTranslation } from 'react-i18next';
import { InviteUserModal } from '../InviteUserModal/InviteUserModal';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import { useDialog } from '../../hooks/useDialog';
import { OrganizationContext } from '../../contexts/OrganizationContext';

const ButtonWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
}));

export const OrganizationInvites: FC = () => {
  const { organization } = useContext(OrganizationContext);
  const { data, onRefresh } = useContext(OrganizationInvitesFetcher.Context);
  const { isOpen: isInvite, openUrl: inviteUrl, close: closeInvite } = useDialog('invite');

  useAutoFetch(OrganizationInvitesFetcher);

  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
        flex: 2,
      },
      {
        field: 'status',
        headerName: t('organization.invites.table.status'),
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
        flex: 1,
      },
      {
        field: 'created_at',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
        width: 130,
      },
      {
        field: 'updated_at',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
        width: 130,
      },
    ],
    []
  );

  if (!data || !organization) return null;

  return (
    <div>
      <ButtonWrapper>
        <Button component={Link} to={inviteUrl} variant="contained" color="primary">
          {t('organization.users.addButton')}
        </Button>
      </ButtonWrapper>

      <UrlQueryDataGrid
        columns={columns}
        rows={data.payload.data}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        filterMode={'server'}
        sortingMode={'server'}
        rowCount={data.payload.total_items}
      />
      <InviteUserModal open={isInvite} closeDialog={closeInvite} organization={organization} onDone={onRefresh} />
    </div>
  );
};
